import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, PropsWithChildren, useState } from 'react';
import { theme } from 'theme';

export const CollapsibleListWrapper: FC<
  PropsWithChildren<{
    title: string;
    hasCloseButton?: boolean;
    hasCollapse?: boolean;
    handleClose?: () => void;
  }>
> = ({
  title,
  hasCloseButton = false,
  hasCollapse = false,
  handleClose,
  children,
}) => {
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}
    >
      <ListItem
        disableGutters
        secondaryAction={
          hasCloseButton && (
            <IconButton sx={{ width: 24, height: 24 }} onClick={handleClose}>
              <Close sx={{ color: CommonColors.black }} />
            </IconButton>
          )
        }
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: open
              ? ''
              : `1px solid ${theme.palette.dividerBlack.medium}`,
          }}
        >
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              fontWeight: 700,
              color: CommonColors.black,
              fontSize: 14,
              letterSpacing: '0.16px',
            }}
          />
          {hasCollapse && (
            <Button
              onClick={handleToggle}
              sx={{
                display: 'flex',
                height: '28px',
                padding: '0px 8px',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: theme.palette.white.primary,
                },
              }}
            >
              <Typography variant="buttonText2">
                {open ? '閉じる' : '開く'}
              </Typography>
              {open ? (
                <ExpandLess
                  sx={{ color: CommonColors.appBlue, width: 16, height: 16 }}
                />
              ) : (
                <ExpandMore
                  sx={{ color: CommonColors.appBlue, width: 16, height: 16 }}
                />
              )}
            </Button>
          )}
        </Box>
      </ListItem>
      <Collapse in={open}>{children}</Collapse>
    </List>
  );
};
