import { RequiredText } from '@components/molecules/requiredText/requiredText';
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  Button,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { BayPlanCode } from 'types/bayPlan';
import {
  RealogramFormControl,
  RealogramStoreBayData,
  RealogramType,
} from 'types/realogram';
import { ImageUploadButton } from '../../../buttons/imageUploadButton';
import { UploadRealogramAlert } from '../alerts/uploadRealogramAlert';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { ImagePreview } from '../realogramImage/imagePreview';
import { useAppDispatch, useAppSelector } from '@store/index';
import { RealogramFormData } from '@components/pages/realogramCreate/types/realogram';
import { useModal } from '@hooks/useModal';
import { StoreBay } from 'types/storeBay';
import {
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
} from '@reducers/selectionStoreBayModal';
import { closeModal } from '@reducers/modal';
import { selectionStoreBayModal } from '@reducers/selectionStoreBayModal/selector';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { useRealogramDirectoriesSearch } from '@api/index';
import { RecentFlatRealogramImage } from './recentFlatRealogramImage';

type Props = {
  file?: File;
  failedDialogOpen: boolean;
  failedDialogOpenForbidden: boolean;
  onSubmit: (data: RealogramFormData) => Promise<void>;
  onFileSelect: (file: File) => void;
  handleCloseFailedDialog: () => void;
  handleCloseFailedDialogForbidden: () => void;
  selectedRealogramType: RealogramType;
  isFlat: boolean;
  isMd: boolean;
  memoRow: number;
  control: RealogramFormControl;
  storeBayData?: RealogramStoreBayData;
  bayPlanCodes?: BayPlanCode[];
  handleOpenModal: () => void;
  setFormValue: (type: 'storeId' | 'storeBayId', id: number) => void;
};

export const RealogramForm: FC<Props> = ({
  file,
  onFileSelect,
  control,
  storeBayData,
  handleOpenModal,
  setFormValue,
}) => {
  const dispatch = useAppDispatch();
  const { currentSelectedItem } = useAppSelector(selectionStoreModal);
  const { currentSelectedItem: currentSelectedStoreBay } = useAppSelector(
    selectionStoreBayModal
  );
  const { showModal: handleOpenStoreBayModal } = useModal(
    'selectionStoreBayModal',
    ''
  );

  const handleChangeSelectedStoreBayModal = (selectedItem?: StoreBay) => {
    dispatch(updateCurrentSelectedItemId(selectedItem?.id.toString()));
    dispatch(setCurrentSelectedItem(selectedItem));
    dispatch(closeModal());
  };

  const { data: recentRealograms } = useRealogramDirectoriesSearch({
    enabled: !!currentSelectedStoreBay,
    limit: 1,
    directory_type: 'file',
    directory_ancestor_id: currentSelectedStoreBay?.directory_id,
    first_order: 'created_at_desc',
  });

  const realogramCandidateId =
    recentRealograms && recentRealograms.length > 0
      ? recentRealograms[0].realogram_candidate_id ?? 0
      : 0;
  const { image, isLoading } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'medium',
      shotIndex: 1,
    },
    {
      skip: realogramCandidateId === 0,
      isNoCache: true,
    }
  );
  const { image: secondImage } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'medium',
      shotIndex: 2,
    },
    {
      skip: realogramCandidateId === 0,
      isNoCache: true,
    }
  );

  useEffect(() => {
    setFormValue('storeBayId', 0);
    dispatch(updateCurrentSelectedItemId(undefined));
    dispatch(setCurrentSelectedItem(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- unnecessary dependency
  }, []);

  return (
    <Box
      component="div"
      justifyContent="space-between"
      sx={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: { sm: 'column', md: 'row' },
        top: '176px',
        gap: { sm: 4, md: 0 },
        flexWrap: {
          xs: 'wrap',
          sm: 'nowrap',
        },
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={{ xs: '100%', sm: '100%', md: '40%' }}
        sx={{
          backgroundColor: theme.palette.backgroundBlack.black,
          borderRight: {
            md: `1px solid ${theme.palette.backgroundBlack.light}`,
          },
        }}
        gap={1}
        py={2}
      >
        <Box
          component="div"
          position="relative"
          gap={1}
          width="100%"
          height={{ xs: '496px', sm: '496px', md: '578px' }}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          flex={{ md: 1 }}
        >
          <ImagePreview file={file} selectedRealogramType="normal" />
        </Box>
        <ImageUploadButton onFileSelect={onFileSelect} />
      </Box>
      <Box
        component="div"
        display="flex"
        px={{ xs: 3, sm: 3, md: 5 }}
        py={{ md: 2 }}
        gap={4}
        flex={1}
        sx={{
          flexDirection: { xs: 'column', sm: 'row', md: 'column' },
          mb: 3,
          mt: { xs: 3, sm: 0 },
        }}
        justifyContent={{
          xs: 'space-between',
          sm: 'space-between',
          md: 'flex-start',
        }}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gap={2}
          flex={{ xs: 1, ms: 1, md: 0 }}
        >
          <RequiredText />
          <Controller
            name="storeId"
            control={control}
            render={() => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '20%', gap: '10px' }}>
                  <Typography variant="body2">店舗 *</Typography>
                </InputLabel>
                <FormControl size="small" sx={{ width: '90%' }}>
                  <Box component="div" display="flex" alignItems="center">
                    <Typography
                      sx={{ fontSize: '16px' }}
                      color={theme.palette.textBlack.primary}
                    >
                      {currentSelectedItem?.name || '選択なし'}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenModal()}
                      sx={{
                        p: '0 8px',
                        width: '102px',
                        minWidth: '102px',
                        height: '40px',
                        fontSize: '14px',
                        ml: '10px',
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      店舗を選択
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="storeBayId"
            render={() => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '20%', gap: '10px' }}>
                  <Typography variant="body2">什器 *</Typography>
                </InputLabel>
                <FormControl sx={{ width: '90%' }} size="small" required>
                  <Box component="div" display="flex" alignItems="center">
                    <Typography
                      sx={{ fontSize: '16px' }}
                      color={theme.palette.textBlack.primary}
                    >
                      {currentSelectedStoreBay?.name || '選択なし'}
                    </Typography>
                    <Button
                      variant="outlined"
                      disabled={!currentSelectedItem?.name}
                      onClick={() =>
                        handleOpenStoreBayModal({
                          data: storeBayData,
                          storeName: currentSelectedItem?.name,
                          handleChangeSelectedStoreBayModal: (
                            selectedItem?: StoreBay
                          ) => handleChangeSelectedStoreBayModal(selectedItem),
                        })
                      }
                      sx={{
                        p: '0 8px',
                        width: '102px',
                        minWidth: '102px',
                        height: '40px',
                        fontSize: '14px',
                        ml: '10px',
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      什器を選択
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          />
          {realogramCandidateId !== 0 && (
            <RecentFlatRealogramImage
              isLoading={!image || isLoading}
              image={image ?? ''}
              secondImage={secondImage ?? ''}
              createdAt={recentRealograms ? recentRealograms[0].created_at : ''}
            />
          )}
          {/* 仕様再検討のためコメントアウト */}
          {/* <Controller
            control={control}
            name="bayPlanId"
            render={({ field }) => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '115px', gap: '10px' }}>
                  <Typography variant="body2">ゴンドラ番号</Typography>
                </InputLabel>
                <FormControl sx={{ width: '90%' }} size="small">
                  <InputLabel sx={{ fontSize: '0.875rem' }}>
                    ゴンドラ番号
                  </InputLabel>
                  <Select label="ゴンドラ番号" {...field}>
                    {bayPlanCodes?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          /> */}
          {/* <Controller
            control={control}
            name="memo"
            render={({ field }) => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="top"
                gap={2}
              >
                <InputLabel sx={{ width: '115px', gap: '10px', py: '10px' }}>
                  <Typography variant="body2">メモ</Typography>
                </InputLabel>
                <TextField
                  sx={{
                    width: '90%',
                  }}
                  rows={isMd ? memoRow : 1}
                  label="メモ"
                  multiline
                  {...field}
                />
              </Box>
            )}
          /> */}
        </Box>
        <UploadRealogramAlert />
      </Box>
    </Box>
  );
};
