import { useState } from 'react';
import {
  defaultScale,
  maxZoomScale,
  minZoomScale,
  zoomScaleStep,
} from '@utils/const';

export const useZoomController = () => {
  const [zoomScale, setZoomScale] = useState(defaultScale);
  const isDisableZoomOut = zoomScale.toFixed(1) >= maxZoomScale.toFixed(1);
  const isDisableZoomIn = zoomScale.toFixed(1) <= minZoomScale.toFixed(1);
  const isDisableReset = zoomScale === 1;

  const handleIncrementZoom = () => {
    if (isDisableZoomOut) return;
    setZoomScale(zoomScale + zoomScaleStep);
  };
  const handleDecrementZoom = () => {
    if (isDisableZoomIn) return;
    setZoomScale(zoomScale - zoomScaleStep);
  };
  const handleTriggerZoom = () => {
    if (zoomScale !== defaultScale) {
      setZoomScale(defaultScale);
    } else {
      setZoomScale(maxZoomScale);
    }
  };
  const handleResetZoom = () => {
    setZoomScale(defaultScale);
  };
  return {
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
    handleResetZoom,
    zoomScale,
    setZoomScale,
    isDisableReset,
    isDisableZoomIn,
    isDisableZoomOut,
  };
};
