import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { ErrorOutlineRounded } from '@mui/icons-material';

type Props = {
  title?: string;
};

export const CandidateEmptyResult: FC<Props> = ({
  title = '結果がありません',
}) => {
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      alignSelf="stretch"
      flexDirection="row"
      height="64px"
      padding="0px 16px"
      sx={{
        background: theme.palette.backgroundBlack.primary,
        gap: '4px',
        borderRadius: '4px',
      }}
    >
      <ErrorOutlineRounded
        sx={{
          color: theme.palette.icons.secondary,
          height: '20px',
          width: '20px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.textBlack.secondary,
          letterSpacing: '0.15px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
