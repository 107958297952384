import { SidebarItemButton } from '@components/molecules/sidebarItemButton/sidebarItemButton';
import { StartIcon } from '@components/molecules/startIcon/startIcon';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { SidebarValue } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { StackListSidebar } from '../stackListSidebar/stackListSidebar';

type Props = {
  sidebarValue: SidebarValue;
  notActivate: boolean;
  navigateToScanner: () => void;
  onClickTabAll: () => void;
  onClickTabFavorite: () => void;
  onClickTabLatest: () => void;
  onClickTabViewed: () => void;
};

const drawerWidth = 184;

export const RealogramsSidebar: FC<Props> = ({
  sidebarValue,
  notActivate,
  navigateToScanner,
  onClickTabAll,
  onClickTabFavorite,
  onClickTabLatest,
  onClickTabViewed,
}) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          marginTop: '1px',
          background: theme.palette.backgroundBlack.light,
        },
      }}
    >
      <Box
        component="nav"
        display="flex"
        flexDirection="column"
        gap={5}
        padding={1.5}
      >
        <Stack
          direction="column"
          alignSelf="stretch"
          alignItems="center"
          gap="8px"
        >
          <SidebarItemButton
            variant="contained"
            startIcon={<StartIcon />}
            onClick={navigateToScanner}
          >
            <Typography
              component="span"
              sx={{
                width: '100%',
                fontWeight: 700,
                fontSize: '0.875rem',
                textAlign: 'center',
                color: theme.palette.white.primary,
              }}
            >
              新規スキャン
            </Typography>
          </SidebarItemButton>
        </Stack>
        <StackListSidebar
          sidebarValueItems={['all', 'favorite', 'latest', 'viewed']}
          sidebarValue={sidebarValue}
          onClickTabAll={onClickTabAll}
          onClickTabFavorite={onClickTabFavorite}
          onClickTabLatest={onClickTabLatest}
          onClickTabViewed={onClickTabViewed}
          notActivate={notActivate}
        />
      </Box>
    </Drawer>
  );
};
