import { useMemo, useState } from 'react';
import { RealogramShelfBoard } from 'types/realogram';
import { toastMessages } from '@utils/const';
import { useAppDispatch } from '@store/index';
import { openToast } from '@reducers/toast';
import { Crop } from 'react-image-crop';
import { useAddProductFaceMutation } from '@reducers/shelfAppsApi';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { secondShotIndex } from '../utils';

export const useAddFace = (
  realogramCandidateId: number,
  shelfBoards?: RealogramShelfBoard[]
) => {
  const dispatch = useAppDispatch();
  const [isAddFaceMode, setIsAddFaceMode] = useState(false);
  const [compartmentNumber, setCompartmentNumber] = useState<number>(0);
  const isFlatBucket = useMemo(
    () => shelfBoards?.some((board) => board.shot_index === secondShotIndex),
    [shelfBoards]
  );
  const addFaceModeLabel = isFlatBucket ? 'カゴ目' : '段目';

  // 区画追加用イメージ
  const {
    image: firstImage,
    error: firstImageError,
    isLoading: isFirstImageLoading,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 1,
    },
    { skip: !isAddFaceMode, isNoCache: true }
  );
  const {
    image: secondImage,
    error: secondImageError,
    isLoading: isSecondImageLoading,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 2,
    },
    { skip: !isFlatBucket || !isAddFaceMode, isNoCache: true }
  );

  const selectedCompartment =
    shelfBoards && shelfBoards[compartmentNumber]?.shot_index;

  const shotBboxes = useMemo(
    () => shelfBoards?.filter((el) => el.shot_index === selectedCompartment),
    [shelfBoards, selectedCompartment]
  );

  const canNotShowImage =
    selectedCompartment === 1
      ? !!firstImageError || isFirstImageLoading
      : !!secondImageError || isSecondImageLoading;

  const handleAddFace = (compartmentNum: number) => {
    setCompartmentNumber(compartmentNum);
    setIsAddFaceMode(!isAddFaceMode);
  };

  const [addProductFace] = useAddProductFaceMutation();
  const handleSaveNewFace = async (
    crop: Crop,
    zoomScale: number,
    ratio: number
  ) => {
    try {
      if (!shelfBoards) throw new Error('棚板が存在しません。');
      await addProductFace({
        realogramCandidateId,
        shelfBoardId:
          shelfBoards[shelfBoards.length - 1 - compartmentNumber].id,
        bbox: {
          x: crop.x / ratio / zoomScale,
          y: crop.y / ratio / zoomScale,
          width: crop.width / ratio / zoomScale,
          height: crop.height / ratio / zoomScale,
        },
        // 店頭在庫状態: 2024年7月時点では常にtrueを返す
        inStock: true,
      }).unwrap();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.addFace,
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.addFace,
        })
      );
    } finally {
      setIsAddFaceMode(false);
    }
  };

  return {
    isAddFaceMode,
    compartmentNumber,
    canNotShowImage,
    shotBboxes,
    realogramImage: selectedCompartment === 1 ? firstImage : secondImage,
    addFaceModeLabel,
    setCompartmentNumber,
    handleAddFace,
    handleSaveNewFace,
  };
};
