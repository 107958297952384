/* eslint filename-consistency/match: off -- ファイル名変更に伴い、呼び出し元もLintによる修正をする必要があるため */

// Imports
// Custom made components import
import {
  realogramDirectorySearchQueryKey,
  useRealogramDirectoriesSearch,
} from '@api/index';
import { queryClient } from '@api/query-client';
import { realogramsApi } from '@api/services/realogramDirectories';
import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { AppBar, DeleteRealogramDialog } from '@components/organisms';
import {
  GridModeSort,
  ListOrder,
} from '@components/organisms/gridModeSort/gridModeSort';
import { RealogramsSidebar } from '@components/organisms/realogramsSidebar/realogramsSidebar';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { useRerenderingScanner } from '@hooks/rerenderingComponents';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Box, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  setLastVisitedRealogramListURL,
  setRealogramScrollPos,
  setRealogramsPageNumber,
  setSelectedDirectoryId,
} from '@reducers/scanner';
import { selectRealogramsScrollPos } from '@reducers/scanner/selectors';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import { useLazyListStoresQuery } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SidebarValue,
  getListPageTitle,
  getSortOptions,
  pageTitle,
  paths,
  realogramsRowsPerPage,
  removeFirstLastSpaces,
  toastMessages,
} from '@utils/const';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import {
  RealogramDirectory,
  RealogramDirectoryType,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { SearchText } from '../planograms/fragments/searchText';
import { realogramDirectoriesQueryKey } from '../storeBays';
import { RealogramsDirectoryGrid } from './fragments/ralogramsDirectoryGrid/realogramsDirectoryGrid';
import { RealogramsDirectoryTable } from './fragments/realogramsDirectoryTable/realogramsDirectoryTable';
import { RealogramSearchDialog } from './fragments/realogramSearchDialog/realogramSearchDialog';
import { SearchTipsArea } from './fragments/realogramSearchDialog/searchTipsArea';

const queryList = ['owner_id', 'name'];

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (sidebarValue === 'latest') {
    return '最近のスキャン';
  } else if (isRoot) {
    return 'スキャン結果';
  } else {
    return directoryParentName;
  }
};

export const directoryTypes = [
  {
    label: 'すべて',
    value: 'all',
  },
  {
    label: '地域・店舗・什器',
    value: 'directory',
  },
  {
    label: '棚割実績',
    value: 'file',
  },
];
const tableCellHeight = 56;

export const Realograms: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedRealogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedRealogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Scanner);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [currentRealogramId, setCurrentRealogramId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [folders, setFolders] = useState([
    { id: '0', label: 'すべてのフォルダ' },
  ]);
  const [isStarCondition, setIsStarCondition] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isShowFolderTip, setIsShowFolderTip] = useState<boolean>(false);
  const [directoryIdCondition, setDirectoryIdCondition] = useState('0');
  const [directoryTypeCondition, setDirectoryTypeCondition] = useState<
    string | undefined
  >();
  // フォルダの検索チップ用
  const [directoryName, setDirectoryName] = useState('');

  const [ownerIdCondition, setOwnerIdCondition] = useState<
    number | undefined
  >();
  const { pathname, search } = location;
  const currentUrl = pathname + search;
  const params = useParams();
  const directoryId = params.id;
  const {
    viewMode,
    condition,
    setViewMode,
    setCondition,
    conditionData: { userData, users },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByLatest,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  } = useRerenderingScanner({
    isSearching,
    directoryId,
    setIsSearching,
    setIsShowFolderTip,
  });

  const { hasQueryParameters } = useQueryParameterPresence();
  const hasQueries = hasQueryParameters(queryList);
  const { name } = condition ?? {};

  const [fetchStoreData] = useLazyListStoresQuery();

  const isSkipListRealogramDirectoriesQuery =
    isFilteredByFavorite ||
    isFilteredByLatest ||
    isFilteredByViewed ||
    isSearching ||
    condition.name;

  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
  } = useQuery({
    queryKey: [realogramDirectoriesQueryKey, directoryId, condition.firstOrder],
    queryFn: () =>
      realogramsApi.getRealogramDirectories({
        id: directoryId,
        status: condition?.status ? condition.status : undefined,
        first_order: condition?.firstOrder as RealogramOrder,
      }),
  });

  const isSkipSearchRealogramDirectoriesQuery =
    (!isSkipListRealogramDirectoriesQuery && !condition?.ownerId) ||
    (isFilteredByFavorite && !userData?.user.id);
  const directoryType =
    condition.directoryType === 'all' ? undefined : condition.directoryType;
  const {
    data: searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isFetchingNextPage,
    isLoading: isSearchLoading,
    hasNextPage,
  } = useRealogramDirectoriesSearch({
    enabled: !isSkipSearchRealogramDirectoriesQuery,
    name: condition?.name,
    first_order: condition.firstOrder as RealogramOrder,
    limit: realogramsRowsPerPage,
    owner_id: condition?.ownerId ? condition.ownerId : undefined,
    status: condition?.status ? condition.status : undefined,
    favorite_owner_id:
      condition.isStar && userData?.user.id ? [userData.user.id] : undefined,
    directory_type: isFilteredByLatest
      ? 'file'
      : (directoryType as RealogramDirectoryType),
    directory_ancestor_id:
      condition.directoryAncestorId === '0'
        ? undefined
        : condition.directoryAncestorId,
  });
  const { mutateAsync: deleteRealogram } = useMutation({
    mutationFn: (id: number) => realogramsApi.deleteRealogram(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
    },
  });

  const { mutateAsync: addRealogramToFavorite } = useMutation({
    mutationFn: realogramsApi.addRealogramToFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });
  const { mutateAsync: deleteRealogramFromFavorite } = useMutation({
    mutationFn: realogramsApi.deleteRealogramFromFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });

  // these 2 fields only used to determine hightlight filter button or not
  const planogramConiditionFilters = {
    statusId: condition?.status,
    ownerId: condition?.ownerId,
  };

  const hasCondition = Object.values(planogramConiditionFilters).some((el) => {
    if (Array.isArray(el)) {
      return !!el.length;
    }
    return !!el;
  });

  const isLoadingData = isSearchLoading || isDirectoryLoading;
  const isFetchingData = isSearchFetching || isDirectoryFetching;
  const isRoot = directory?.parent.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const realogramDirectories = isSkipSearchRealogramDirectoriesQuery
    ? directory?.realogram_directories
    : searchedData;

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });

  useEffect(() => {
    if (!isFirstRender && name) {
      setIsFirstRender(true);
    }
  }, [isFirstRender, name]);

  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory &&
      directory.parent.type !== 'root'
    ) {
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  const navigateToScanner = async () => {
    dispatch(setRealogramsPageNumber(1));
    dispatch(updateLoadingIndicatorState(true));
    const stores = await fetchStoreData({
      filterByRole: 'editor',
    });
    if (stores.data?.stores?.length) {
      navigate(paths.actuals.scan);
    } else {
      setIsPermissionDialogOpen(true);
    }
    dispatch(updateLoadingIndicatorState(false));
  };

  const handleClickDirectory = (item: RealogramDirectory) => {
    const directoryId = item.id;
    dispatch(setSelectedDirectoryId(directoryId));
    setIsSearching(false);
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
        ownerId: undefined,
        isStar: false,
        directoryAncestorId: '0',
        isSearching: false,
      },
      {
        directoryId,
      }
    );
    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };
  const realogramScrollPos = useAppSelector(selectRealogramsScrollPos);
  const ref = useRef<HTMLElement | Window | null>(null);
  const handleScrollerRef = (refTable: HTMLElement | Window | null) => {
    if (!refTable) return;
    ref.current = refTable;
  };

  const handleClickFile = () => {
    dispatch(
      setRealogramScrollPos((ref.current as HTMLElement)?.scrollTop ?? 0)
    );
    dispatch(setLastVisitedRealogramListURL(currentUrl));
    setSessionLastVisitedRealogramListURL(currentUrl);
  };

  const handleRowClick = (item: RealogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
      setIsSearching(false);
      setCondition(
        { ...condition, isSearching: false },
        { directoryId: item.id }
      );
    } else if (item.type === 'file' && item.realogram_candidate_id) {
      handleClickFile();
    }
  };
  const handleOpenDeleteDialog = (id: number) => {
    setDeleteDialogOpen(true);
    setCurrentRealogramId(id);
  };

  const handleDeleteRealogram = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteRealogram(currentRealogramId ?? 0);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteScanResult,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteScanResult,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  usePageTitle('スキャン結果一覧');

  const handleClickMoveParentDirectory = (parentId?: string) => {
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;

    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };

  const afterHandleChangeBreadCrumb = (data: {
    isBackToRootDirectory: boolean;
    hasCondition: boolean;
    directoryId: string;
  }) => {
    const { directoryId, isBackToRootDirectory } = data;
    dispatch(setSelectedDirectoryId(directoryId));
    const newCondition = {
      ...condition,
      name: undefined,
    };
    if (isBackToRootDirectory) {
      setCondition(newCondition, {
        isToRootDirectory: true,
      });
    } else {
      setCondition(newCondition, {
        directoryId,
      });
    }
  };

  const onSubmit = (text: string) => {
    const name = removeFirstLastSpaces(text);
    if (name === '') return;
    // always search in the tab all
    const firstOrder = defaultFirstOrderBySideBar['all'];
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        firstOrder,
        name,
      },
      { isToRootDirectory: !isSearching }
    );
  };

  const clearTextSearch = () => {
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        isToRootDirectory: !isSearching,
      }
    );
  };

  const handleChangeOrder = (firstOrder?: ListOrder) => {
    const newFirstOrder =
      isFilteredByViewed && !firstOrder
        ? defaultFirstOrderBySideBar['viewed']
        : firstOrder;
    setCondition({
      ...condition,
      firstOrder: newFirstOrder as RealogramListOrder,
    });
  };

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteRealogramFromFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addRealogramToFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSidebar = (value: SidebarValue) => {
    const firstOrder = defaultFirstOrderBySideBar[value];
    dispatch(setSelectedDirectoryId(''));
    ref.current?.scrollTo({ top: 0 });
    setIsSearching(false);
    if (isSearching) {
      handleResetConditions();
    }
    if (value === 'favorite') {
      setCondition(
        {
          name: undefined,
          isStar: true,
          ownerId: undefined,
          status: undefined,
          firstOrder,
          directoryType: undefined,
          isSearching: false,
        },
        {
          sideBarValue: value,
        }
      );
    } else {
      setCondition(
        {
          ...condition,
          name: undefined,
          ownerId: undefined,
          status: undefined,
          firstOrder: firstOrder,
          isStar: false,
          directoryType: undefined,
          isSearching: false,
        },
        {
          sideBarValue: value,
        }
      );
    }
  };

  const handleEndReached = (isAtBottom: boolean) => {
    const tableHeight =
      document.querySelector('.MuiTableBody-root')?.clientHeight ?? 0;
    if (
      !isAtBottom ||
      !hasNextPage ||
      isFetchingNextPage ||
      // NOTE: This condition is to prevent fetching next page when initializing table
      tableHeight <= tableCellHeight
    )
      return;
    fetchNextPage();
  };

  const { showLicenseModal } = useSharePermissionModal();

  const handleSharePermission = (item: RealogramSharePermissionProps) => {
    if (!item.directoryId) return;
    dispatch(setDirectoryId(`${item?.directoryId}`));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: item.type,
    });
  };

  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const isDisplayLoadingSkeleton = isApiLoading && !isFetchingNextPage;
  const emptyTableMessage = hasQueries
    ? '条件を変更してください。'
    : '左上の「新規スキャン」ボタンから追加してください。';

  const handleSubmitCondition = () => {
    setIsSearching(true);
    setIsOpen(false);
    setDirectoryName(directory?.parent?.name ?? '');
    directoryIdCondition === '0'
      ? setIsShowFolderTip(false)
      : setIsShowFolderTip(true);
    setCondition(
      {
        name: condition.name,
        isStar: isStarCondition,
        ownerId: ownerIdCondition ? [+ownerIdCondition] : undefined,
        firstOrder: condition.firstOrder ?? 'created_at_desc',
        directoryAncestorId: directoryIdCondition,
        directoryType: directoryTypeCondition,
        isSearching: true,
      },
      {
        isToRootDirectory: directoryIdCondition === '0',
        directoryId: directoryIdCondition !== '0' ? directoryIdCondition : '',
        sideBarValue: 'all',
      }
    );
  };

  const handleConditionModalOpen = () => {
    setIsOpen(true);
    if (sidebarValue == 'favorite') {
      setIsStarCondition(true);
    }
    if (sidebarValue === 'latest' || sidebarValue === 'viewed') {
      setDirectoryTypeCondition('file');
    }
    if (directory?.parent?.type === 'root') {
      setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
      setDirectoryIdCondition('0');
    } else if (directory?.parent) {
      setFolders([
        { id: '0', label: 'すべてのフォルダ' },
        { id: directory?.parent?.id, label: directory?.parent?.name },
      ]);
      setDirectoryIdCondition(directory?.parent?.id);
    }
  };

  const handleResetConditions = () => {
    setDirectoryIdCondition('0');
    setIsStarCondition(false);
    setOwnerIdCondition(undefined);
    setDirectoryTypeCondition('all');
  };

  const handleDeleteTip = (key: keyof typeof condition) => {
    switch (key) {
      case 'directoryAncestorId':
        setIsShowFolderTip(false);
        setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
        setCondition(
          {
            ...condition,
            directoryAncestorId: '0',
          },
          {
            isToRootDirectory: true,
          }
        );
        break;
      case 'isStar':
        setIsStarCondition(false);
        setCondition(
          {
            ...condition,
            isStar: false,
          },
          {
            isToRootDirectory: directoryId === '0',
            directoryId: directoryId === '0' ? '' : directoryId,
          }
        );
        break;
      case 'ownerId':
        setOwnerIdCondition(undefined);
        setCondition({
          ...condition,
          ownerId: undefined,
        });
        break;
      case 'directoryType':
        setDirectoryTypeCondition(undefined);
        setCondition({
          ...condition,
          directoryType: undefined,
        });
        break;
    }
  };

  useEffect(() => {
    if (
      !isShowFolderTip &&
      !condition.isStar &&
      !condition.name &&
      !condition.ownerId &&
      !condition.directoryType
    ) {
      setIsSearching(false);
      setCondition({ isSearching: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setCondition will cause infinite loop
  }, [condition, isShowFolderTip, setIsSearching]);

  useEffect(() => {
    if (condition.directoryAncestorId === '0') {
      setDirectoryName('');
    }
    if (directory?.parent?.name) {
      setDirectoryName(directory?.parent?.name);
    }
  }, [condition, setDirectoryName, directory?.parent?.name]);

  return (
    <>
      <AppBar title="棚割実績（棚スキャナ）" />
      <Box component="div" display="flex">
        <RealogramsSidebar
          navigateToScanner={navigateToScanner}
          sidebarValue={sidebarValue}
          onClickTabAll={() => handleClickSidebar('all')}
          onClickTabFavorite={() => handleClickSidebar('favorite')}
          onClickTabLatest={() => handleClickSidebar('latest')}
          onClickTabViewed={() => handleClickSidebar('viewed')}
          notActivate={isSearching}
        />
        <Box component="div" mt={2} px={3} flex={1}>
          {/*56px = 40 AppBar + 16px margin*/}
          <Box
            component="div"
            width="100%"
            height="calc(100vh - 57px)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Box component="div" display="flex" justifyContent="space-between">
              <SearchText
                key={condition.name}
                searchText={condition.name}
                handleSearch={onSubmit}
                clearSearch={clearTextSearch}
                isShowSearch
                handleConditionModalOpen={handleConditionModalOpen}
              />
              <ShelvesViewToggle
                mode={viewMode}
                onChange={(mode) => {
                  setViewMode(mode);
                }}
              />
            </Box>

            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={-1}
            >
              {isShowBreadcrumbList && !isSearching ? (
                <Box component="div">
                  {!isRoot && (
                    <Box component="div" marginLeft={5}>
                      <BreadcrumbList
                        breadcrumbs={
                          directory.breadcrumb as unknown as DirectoryBreadcrumbs
                        }
                        parentDirectory={
                          directory.parent as unknown as ParentDirectory
                        }
                        screenType="Main"
                        fontSize="12px"
                        handleClickBreadcrumbs={handleClickBreadcrumbs}
                        directoryRootName="スキャン結果"
                      />
                    </Box>
                  )}
                  <Box component="div" display="flex" alignItems="center">
                    {!isRoot && (
                      <GoParentDirectoryButton
                        width={32}
                        height={32}
                        handleClick={() => {
                          handleClickMoveParentDirectory(
                            directory.parent.parent_id
                          );
                        }}
                      />
                    )}
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      mr={!isRoot ? '6px' : ''}
                    >
                      {getDirectoryPageTitle(
                        sidebarValue,
                        isRoot,
                        directory.parent.name
                      )}
                    </Typography>
                    {!isRoot && (
                      <ActionVisible
                        isCanUpdate={isCanUpdate}
                        isCanRead={isCanRead}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  {isSearching && sidebarValue === 'all' ? (
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="16px"
                      height="52px"
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        {getListPageTitle({
                          defaultTitle: pageTitle.planogram,
                          sidebarValue,
                          isSearched: isSearching,
                        })}
                      </Typography>
                      <SearchTipsArea
                        isShowFolderTip={isShowFolderTip}
                        directoryName={directoryName}
                        condition={condition}
                        users={users}
                        handleConditionModalOpen={handleConditionModalOpen}
                        handleDeleteTip={handleDeleteTip}
                      />
                    </Box>
                  ) : (
                    <Typography fontWeight="bold" fontSize="20px">
                      {getListPageTitle({
                        defaultTitle: pageTitle.realogram,
                        sidebarValue,
                        isSearched: !!condition?.name,
                      })}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {viewMode === 'grid' && (
              <GridModeSort
                sortOptions={getSortOptions(
                  'realogram',
                  sidebarValue === 'viewed'
                )}
                isLoadingData={isFetchingData || isLoadingData}
                firstOrder={condition?.firstOrder}
                handleChangeOrder={handleChangeOrder}
              />
            )}

            {!(
              directory?.realogram_directories.length || searchedData?.length
            ) &&
            !isLoadingData &&
            !isFetchingData &&
            viewMode === 'grid' ? (
              <Box component="div" height="calc(100% - 90px)">
                <EmptyResult
                  title="スキャン結果がありません"
                  message={emptyTableMessage}
                  isErrorIcon
                />
              </Box>
            ) : (
              <Box component="div" mb={2} height="100%">
                {viewMode === 'table' ? (
                  <RealogramsDirectoryTable
                    isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
                    isEmpty={!realogramDirectories?.length && !isLoadingData}
                    isRefetching={isFetchingData}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={condition.firstOrder}
                    viewMode={viewMode}
                    handleRowClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleChangeOrder={handleChangeOrder}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                    emptyTableMessage={emptyTableMessage}
                    emptyTableTitle="スキャン結果がありません"
                    handleScrollerRef={handleScrollerRef}
                    scrollPos={realogramScrollPos}
                  />
                ) : (
                  <RealogramsDirectoryGrid
                    scrollPos={realogramScrollPos}
                    handleScrollerRef={handleScrollerRef}
                    isLoading={
                      (isLoadingData || isFetchingData) && !isFetchingNextPage
                    }
                    isRefetching={isFetchingNextPage}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    viewMode={viewMode}
                    isFilteredByViewed={isFilteredByViewed}
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteRealogramDialog
        open={deleteDialogOpen}
        handleClickCancelButton={() => setDeleteDialogOpen(false)}
        handleClickConfirmButton={handleDeleteRealogram}
      />
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />

      <RealogramSearchDialog
        handleSubmit={handleSubmitCondition}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleResetConditions={handleResetConditions}
        folders={folders}
        me={userData?.user}
        users={
          userData?.user
            ? [
                {
                  id: userData?.user?.id,
                  label: `自分 (${userData?.user?.fullname})`,
                },
                ...(users?.map((user) => {
                  return {
                    id: user.id,
                    label: user.fullname,
                  };
                }) ?? []),
              ]
            : users?.map((user) => {
                return {
                  id: user.id,
                  label: user.fullname,
                };
              }) ?? []
        }
        setDirectoryIdCondition={setDirectoryIdCondition}
        setIsStarCondition={setIsStarCondition}
        setOwnerIdCondition={setOwnerIdCondition}
        setDirectoryTypeCondition={setDirectoryTypeCondition}
        directoryTypeCondition={directoryTypeCondition}
        directoryIdCondition={directoryIdCondition}
        isStarCondition={isStarCondition}
        ownerIdCondition={ownerIdCondition}
      />
    </>
  );
};
