// Recognition score magic number
import { keyframes } from '@emotion/react';
import {
  browserVersion,
  fullBrowserVersion,
  isChrome,
  isIOS,
  isMobile,
} from 'react-device-detect';
import {
  DirectoryType,
  FaceFrontValues,
  FaceOrientationValues,
  Product,
  ProfitTab,
  ShelfDetailView,
  ShelvesViewMode,
  ToastMessages,
} from 'types/common';
import { Planogram, PreviewMode } from 'types/planogram';
import { Pointer, ProductSalesEstimateSummary } from 'types/products';
import { RealogramDirectory } from 'types/realogram';
import { User } from 'types/user';
import { theme } from '../theme';
import { t } from 'i18next';

export const rowsPerPage = 20;
export const contextMenuSize = 55;
export const editorMenuAnimation = keyframes`
  from {
   opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
export const rowHeight = 48;

export const appName = '棚アプリ';
export const pathPrefix = '/api/v1';

export const faceFrontValues: FaceFrontValues = {
  front: 1,
  top: 2,
  right: 3,
  left: 4,
  back: 5,
  bottom: 6,
} as const;

export const faceOrientationValues: FaceOrientationValues = {
  zeroDegree: { id: 0, value: 0 }, //0 degree
  leftNinetyDegree: { id: 1, value: 270 },
  oneEightyDegree: { id: 2, value: 180 }, // 180 degree
  rightNinetyDegree: { id: 3, value: 90 }, //right 90 degree
} as const;

// eslint-disable-next-line no-magic-numbers -- scaleに必要
export const planogramScale = 300;

// eslint-disable-next-line no-magic-numbers -- 棚エディタの拡大倍率
export const planogramZoomScale = isMobile ? 1.2 : 1.5;

export const productTags = [
  'new_products_first_week',
  'new_products_second_week',
  'base_product',
  'sales_ended',
  'not_3D_scanned',
] as const;

export const getProfitTagName = (tagKey: string) => {
  switch (true) {
    case tagKey === 'new_products_first_week':
      return '新商品(1週目)';
    case tagKey === 'new_products_second_week':
      return '新商品(2週目)';
    case tagKey === 'base_product':
      return '基本商品';
    case tagKey === 'sales_ended':
      return t('sales_ended');
    case tagKey === 'not_3D_scanned':
      return '未3Dスキャン';
    default:
      return tagKey;
  }
};
export const getProfitKeyName = (tagValue: string) => {
  switch (true) {
    case tagValue === '新商品(1週目)':
      return 'new_products_first_week';
    case tagValue === '新商品(2週目)':
      return 'new_products_second_week';
    case tagValue === '基本商品':
      return 'base_product';
    case tagValue === '推奨取消' || tagValue === t('sales_ended'):
      return 'sales_ended';
    case tagValue === '未3Dスキャン':
      return 'not_3D_scanned';
    default:
      return tagValue;
  }
};

export const rateValues = [
  'スコア',
  '評価OK',
  '欠品区画数',
  '不明な商品フェイス数',
  'フェイスアップNG',
] as const;

export const profitTabSales = 'sales';
export const profitTabQuantity = 'quantity';
export const profitTabGrossProfit = 'grossProfit';
export const profitTabSalesQueryParamater = 'sales';
export const profitTabQuantityQueryParamater = 'quantity';
export const profitTabGrossProfitQueryParamater = 'gross_profit';
export const profitTabsKey = [
  profitTabSales,
  profitTabQuantity,
  profitTabGrossProfit,
] as const;
export const profitTabs = (grossProfitName: string) =>
  [
    {
      key: profitTabSales,
      value: '売上',
    },
    {
      key: profitTabQuantity,
      value: '販売数',
    },
    {
      key: profitTabGrossProfit,
      value: grossProfitName,
    },
  ] as const;
export const getProfitTabsValueByKey = (
  key: ProfitTab,
  grossProfitName: string
) => {
  const profitTab = profitTabs(grossProfitName).find((tab) => tab.key === key);

  return profitTab?.value ?? '';
};

//svhやdvhが使えるかどうか判定する
const isValidLatestViewport = () => {
  if (!isMobile) {
    return false;
  }
  if (isIOS) {
    // eslint-disable-next-line no-magic-numbers -- dvhが使えるiOSのVersion
    return Number(fullBrowserVersion) >= 15.4;
  }
  if (isChrome) {
    // eslint-disable-next-line no-magic-numbers -- dvhが使えるChromeのVersion
    return Number(browserVersion) >= 108;
  }
  return false;
};
export const appBarHeight = 40;
export const drawerWidth = 160;
export const fullWidth = isValidLatestViewport() ? '100dvw' : '100vw';
export const fullHeight = isValidLatestViewport() ? '100dvh' : '100vh';
export const homePageUrl = '/';

export const formatNumberToYen = (value: number) => {
  const numberFormat = Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  });
  return numberFormat.format(value);
};

//NOTE: this is mock condition
export const getBasisDiffColor = (view: ShelfDetailView) => {
  switch (view) {
    case 'default':
      return '#D62A2A';
    case 'productFlag':
      return '#D62A2A';
    case 'profit':
      return theme.palette.heatmap['03_line'];
    case 'rate':
      return '#219F5B';
    default:
      return theme.palette.white.primary;
  }
};

//NOTE: this is mock condition
export const getComparisonDiffColor = (view: ShelfDetailView) => {
  switch (view) {
    case 'default':
      return '#219F5B';
    case 'productFlag':
      return '#219F5B';
    case 'profit':
      return theme.palette.heatmap['03_line'];
    case 'rate':
      return '#219F5B';
    default:
      return theme.palette.white.primary;
  }
};

// サーバーサイドではブラウザ環境でのみアクセス可能な window オブジェクトを使用できない
export const tenantName =
  typeof window !== 'undefined' ? window.document.location.hostname : undefined;

// Note: ローカル環境用に環境変数からの注入を判定する
export const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL ?? '/api/v1';

//TODO: 環境変数で定義すべきか
export const googleAuthenticatorIos =
  'https://apps.apple.com/jp/app/google-authenticator/id388497605';

//TODO: 環境変数で定義すべきか
export const googleAuthenticatorAndroid =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ja&gl=US';

export const faceFrontThreshold = 0.85;
export const foregroundThreshold = 0.85;

// todo: 一括で一覧を取得できるAPIがないため暫定で大きな値を指定
export const bayPlansLimit = 100;
export const usersLimit = 100;

//planogram zoom scale variables
export const defaultScale = 1;
export const maxZoomScale = 2;
export const minZoomScale = 0.5;
export const zoomScaleStep = 0.1;

export const productFaceDirection = [
  'top',
  'left',
  'front',
  'right',
  'back',
  'bottom',
] as const;
//realogram product candidate image box size
export const imageBoxSize = 104;

export const productDetailTabs = ['情報', '編集'];

export const mockupImageFace = ['front', 'top'] as const;

export const convertMilliMeterToMeter = (value: number) => {
  // eslint-disable-next-line no-magic-numbers -- エラーが出るが、なぜ1000で割っているかわからないので確認し次第、その理由を記載する。
  return value / 1000;
};

export const faceFrontJpValues = [
  '正面',
  '上面',
  '右側面',
  '左側面',
  '背面',
  '底面',
] as const;

export const faceIdFront = 1;
export const faceIdTop = 2;

export const fullRotation = 360;
export const rotationAngleFlatPlanogram = 180;
export const maxChildrenOfArea = 2;
export const baseSizeDefault = 54;
export const baseSizeInCompareMode = 40;
export const fontSizeDefault = 13;
export const maxLineDefault = 2;
export const maxLineInCompareMode = 2;
export const fontSizeInCompareMode = 12;

export enum ComparisonTabModal {
  REALOGRAM_TAB = 0,
  PLANOGRAM_TAB = 1,
}

// Planogram search api max limit
export const planogramSearchLimit = 100;

export const getProfitsMenu = (grossProfitName: string) => {
  return ['売上', grossProfitName, '販売数'];
};
export const pointersDummy: Pointer[] = [
  {
    value: 'store',
    label: '店舗',
  },
];

export const accessRoles = [
  {
    value: 'editor',
    label: '編集者',
  },
  {
    value: 'viewer',
    label: '閲覧者',
  },
];

export const isRoleAllowedForCrud = (role: string | undefined) => {
  if (!role) return false;
  return ['owner', 'editor', 'admin'].includes(role);
};

export const emptyText = 'データなし';

// TODO: change types
export const getDisplayValue = (
  profit: string,
  data?: ProductSalesEstimateSummary,
  grossProfitName?: string
) => {
  if (!data) return '';
  switch (profit) {
    case '売上':
      return data?.total_gross_sales_price?.toString()
        ? formatNumberToYen(data.total_gross_sales_price)
        : '';
    case '販売数':
      return data?.total_sales_count?.toString() ?? '';
    case grossProfitName:
      return data?.total_gross_profit_price?.toString()
        ? formatNumberToYen(data?.total_gross_profit_price)
        : '';
    default:
      return '';
  }
};

export type SidebarValue = 'all' | 'favorite' | 'latest' | 'viewed';
export const sidebarLabel: Record<SidebarValue, string> = {
  all: 'すべて',
  favorite: 'スター付き',
  latest: '最近のスキャン',
  viewed: '閲覧履歴',
};
export const pageTitle = {
  realogram: 'スキャン結果',
  planogram: '棚割計画',
  storeBays: '什器一覧',
};

export const defaultShelvesViewMode: ShelvesViewMode = 'table';

export const standardSortOption = {
  value: 'standard',
  name: '標準',
};

export const getSortOptions = (
  page: 'realogram' | 'planogram',
  isViewed: boolean
) => {
  const nameObj = !isViewed
    ? [
        {
          value: 'name',
          name: '名前',
        },
      ]
    : [];
  if (page === 'realogram') {
    return [
      ...(!isViewed ? [standardSortOption] : []),
      {
        value: isViewed ? 'current_user_accessed_at' : 'created_at',
        name: isViewed ? '閲覧日時 ' : '作成日時',
      },
      ...nameObj,
    ];
  }
  return [
    {
      value: isViewed ? 'current_user_accessed_at' : 'updated_at',
      name: isViewed ? '閲覧日時 ' : '編集日時',
    },
    ...nameObj,
  ];
};

export const defaultDirectoryType = 'planogram' as const;

export const displayRoleName = (role: string) => {
  switch (role) {
    case 'editor':
      return '編集者';

    case 'viewer':
      return '閲覧者';

    default:
      return '';
  }
};

export type ApiErrorResponse = {
  status: number;
  data: {
    detail: string;
    type: string;
    trace_id: string;
    user_message: string;
    user_help_url: string;
  };
};

export const isApiResponse = (error: unknown): error is ApiErrorResponse => {
  return typeof error === 'object' && !!error && 'data' in error;
};

export const widthList = {
  eanCodeWidth: 137,
  scoreWidth: 89,
  face: 73,
  profit: 89,
};

export const embed = 'sej-virtualshowroom.com';

export const toastMessages: ToastMessages = {
  success: {
    changePassword: 'パスワードを変更しました。',
    setNewPassword: '新しいパスワードを設定しました。',
    resendAuthCode: '認証コードを再送信しました。',
    deleteMfa: '二要素認証デバイスを削除しました。',
    deleteFaceMutation: '認識結果を削除しました。',
    deleteScanResult: 'スキャン結果を削除しました。',
    updatePlanogramName: 'ゴンドラ名等を変更しました。',
    deleteShelfBoard: '棚板を削除しました。',
    savePlanogram: '棚割を保存しました。',
    clonePlanogram: '棚割を複製しました。',
    deletePlanogram: '棚割を削除しました。',
    createDirectory: 'フォルダを作成しました。',
    updateDirectoryName: 'フォルダ名を変更しました。',
    moveDirectoryName: (name, parentType) => {
      if (parentType === 'root') return '棚割計画に移動しました。';
      return `「${name}」に移動しました。`;
    },
    deleteShelfEditorItem: (typeName) => `${typeName}を削除しました。`,
    addStar: 'スターを付けました。',
    removeStar: 'スターを外しました。',
    corrected: '訂正しました。',
    correctedUnknown: '不明な商品を訂正しました。',
    saveProduct: '商品を保存しました。',
    successImport: 'インポートが完了しました。',
    createUser: 'ユーザーを追加しました。',
    updateUser: 'ユーザー情報を変更しました。',
    deleteMfaByAdmin: '二要素認証を削除しました。',
    divideCompartment: '区画を分割しました。',
    addFace: '区画を追加しました。',
  },
  error: {
    login: 'ログインできませんでした。',
    changePassword: 'パスワードを変更できませんでした。',
    resendAuthCode: '認証コードの再送信に失敗しました。',
    deleteMfa: '二要素認証デバイスを削除できませんでした。',
    deleteFaceMutation: '認識結果を削除できませんでした。',
    deleteScanResult: 'スキャン結果を削除できませんでした。',
    updatePlanogramName: 'ゴンドラ名等を変更できませんでした。',
    createPlanogram: '棚割を作成できませんでした。',
    deleteShelfBoard: '棚板を削除できませんでした。',
    savePlanogram: '編集内容を保存できませんでした。',
    clonePlanogram: '棚割を複製できませんでした。',
    deletePlanogram: '棚割を削除できませんでした。',
    createDirectory: 'フォルダを作成できませんでした。',
    updateDirectoryName: 'フォルダ名の更新に失敗しました。',
    moveDirectoryName: (typeName) => `${typeName}を移動できませんでした。`,
    deleteShelfEditorItem: (typeName: 'フォルダ' | '棚割') =>
      `${typeName}を削除できませんでした。`,
    notPermittedCreateDirectory:
      '作成権限がないため、フォルダを作成できません。',
    notPermittedCreatePlanogram: '作成権限がないため、棚割を作成できません。',
    directoryIsNotEmpty: 'フォルダ内が空ではないため、削除できません。',
    directoryNotPermitted: 'フォルダの編集権限がないため、削除できません。',
    addStar: 'スターを付けられませんでした。',
    removeStar: 'スターを外せませんでした。',
    saveProduct: '商品を保存できませんでした。',
    successImport: 'インポートに失敗しました。',
    createUser: 'ユーザーを追加できませんでした。',
    updateUser: 'ユーザー情報を変更できませんでした。',
    deleteMfaByAdmin: '二要素認証を削除できませんでした。',
    divideCompartment: '区画を分割できませんでした。',
    addFace: '区画を追加できませんでした。',
  },
};

export const paths = {
  home: '/',
  login: '/login',
  setting: '/settings',
  mfa: '/mfa',
  account: {
    root: '/account',
    mfa: '/account/mfa',
  },
  password: {
    passwordInit: '/password-init',
    reset: '/password-reset',
  },
  actuals: {
    id: (id: RealogramDirectory['id']) => `/actuals/${id}`,
    scan: '/actuals/scan',
    folders: '/actuals/folders',
    foldersRoot: '/actuals/folders/root',
    foldersId: (selectedDirectoryId: DirectoryType['id']) =>
      `/actuals/folders/${selectedDirectoryId}`,
    foldersStarred: '/actuals/folders/starred',
    foldersRecentlyScanned: '/actuals/folders/recently-scanned',
    foldersRecentlyViewed: '/actuals/folders/recently-viewed',
    viewList: '/actuals?view=list',
    recentlyScan: '/actuals/folders/recently-scanned',
  },
  plans: {
    root: 'plans',
    folders: '/plans/folders',
    foldersRoot: '/plans/folders/root',
    foldersId: (id: DirectoryType['id']) => `/plans/folders/${id}`,
    foldersWithParams: (urlParams: string) => `/plans/folders?${urlParams}`,
    foldersStarred: '/plans/folders/starred',
    foldersRecentlyViewed: '/plans/folders/recently-viewed',
    planogramId: (id: Planogram['id']) => `/plans/${id}`,
    edit: (id: Planogram['id']) => `/plans/${id}/edit`,
    navigateView: (id: Planogram['id']) => `/plans/${id}/view`,
    preview: (id: Product['id'], value: PreviewMode) =>
      `/plans/${id}/view/${value}`,
  },
  products: {
    root: '/products',
    product: (id: Product['id']) => `/products/${id}`,
  },
  users: {
    root: '/users',
    user: (id: User['id']) => `/users/${id}`,
  },
  directoryType: (pathPrefix: string) => `${pathPrefix}/folders`,
  directoryTypeId: (pathPrefix: string, directoryId: DirectoryType['id']) =>
    `${pathPrefix}/folders/${directoryId}`,
  storeBays: {
    home: '/storebays',
    folders: '/storebays/folders',
    foldersId: (selectedDirectoryId: DirectoryType['id']) =>
      `/storebays/folders/${selectedDirectoryId}`,
    foldersStarred: '/storebays/folders/starred',
  },
};

export const moveDirectoryLimit = 100;

// query param string is displayed on the URL
export const getQueryParams = (urlSearchParams?: URLSearchParams) =>
  urlSearchParams ? `?${urlSearchParams.toString()}` : '';

export const getSidebarSx = (isSelected: boolean) => {
  return {
    [`& .MuiChip-label`]: {
      color: isSelected
        ? theme.palette.textBlack.primary
        : theme.palette.textBlack.secondary,
      lineHeight: '100%',
    },
    background: isSelected
      ? theme.palette.primary.selected
      : theme.palette.backgroundBlack.light,
    '&:hover': {
      background: isSelected ? theme.palette.primary.selected : '',
    },
    cursor: isSelected ? 'default' : '',
  };
};

export const getListPageTitle = (data: {
  defaultTitle: string;
  sidebarValue: SidebarValue;
  isSearched: boolean;
}) => {
  const { sidebarValue, isSearched, defaultTitle } = data;
  if (['favorite', 'latest', 'viewed'].includes(sidebarValue)) {
    return sidebarLabel[sidebarValue];
  }
  if (isSearched) return '検索結果';
  return defaultTitle;
};

export const userRoleTags = [
  {
    key: 'user',
    value: '利用者',
  },
  {
    key: 'admin',
    value: '管理者',
  },
] as const;
export const userStatusTags = [
  {
    key: 'active',
    value: '利用可能',
  },
  {
    key: 'deleted',
    value: '停止中',
  },
] as const;

export const removeFirstLastSpaces = (text: string) => {
  return text.replace(/^[ \t\uFEFF\xA0]+|[ \t\uFEFF\xA0]+$/g, '').trim();
};

export const isOnlySpaces = (text: string) => {
  // 正規表現で半角スペース（\u0020）および全角スペース（\u3000）のみかどうかをチェック
  const regex = /^[\u0020\u3000]*$/;
  return text !== '' && regex.test(text);
};

export const planogramRightSideHeight = 416;

export const appConstants = {
  zoomButtonPosition: 40,
  tableScaleDown: 0.2,
  tableScalePaddingRight: 1.1,
  bayButtonPosition: 24,
  reserveValue: 50,
  allowedFirstOrder: [
    // except in access tab
    'created_at_asc',
    'created_at_desc',
    'updated_at_asc',
    'updated_at_desc',
    'name_asc',
    'name_desc',
    'bay_plan_code_asc',
    'bay_plan_code_desc',
  ],
  allowedFirstOrderInViewedTab: [
    'current_user_accessed_at_asc',
    'current_user_accessed_at_desc',
  ],
};

export const realogramsRowsPerPage = 100;
