import { SearchDialogLayout } from '@components/organisms/search/searchDialogLayout';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import { theme } from 'theme';
import { components } from 'types/api';

type Option = {
  id: string | number;
  label: string;
};

type Props = {
  isOpen: boolean;
  folders: Option[];
  users: Option[];
  bayPlans: Option[];
  status: Option[];
  directoryIdCondition: string;
  me?: components['schemas']['UserInfo'];
  setDirectoryIdCondition: (directoryIdCondition: string) => void;
  isStarCondition: boolean;
  setIsStarCondition: (isStarCondition: boolean) => void;
  ownerIdCondition: number | undefined;
  setOwnerIdCondition: (ownerIdCondition: number | undefined) => void;
  bayPlanIdCondition: number | undefined;
  setBayPlanIdCondition: (bayPlanIdCondition: number | undefined) => void;
  statusCondition: number | undefined;
  setStatusCondition: (statusCondition: number | undefined) => void;
  handleClose: VoidFunction;
  handleResetConditions: VoidFunction;
  handleSubmit: VoidFunction;
};
export const PlanogramSearchDialog = ({
  isOpen,
  folders,
  users,
  bayPlans,
  status,
  directoryIdCondition,
  setDirectoryIdCondition,
  isStarCondition,
  setIsStarCondition,
  ownerIdCondition,
  setOwnerIdCondition,
  bayPlanIdCondition,
  setBayPlanIdCondition,
  statusCondition,
  me,
  setStatusCondition,
  handleClose,
  handleResetConditions,
  handleSubmit,
}: Props) => {
  return (
    <SearchDialogLayout
      isOpen={isOpen}
      handleClose={handleClose}
      handleResetConditions={handleResetConditions}
      handleSubmit={handleSubmit}
    >
      <Box
        component="div"
        display="flex"
        alignItems="center"
        width={452}
        pt="16px"
        position="relative"
        pb="44px"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          場所
        </Typography>
        <Autocomplete
          options={folders}
          onChange={(e, newValue) => {
            if (!newValue) setDirectoryIdCondition('0');
            else setDirectoryIdCondition(`${newValue.id}`);
          }}
          value={
            folders.find((folder) => folder.id === directoryIdCondition) || null
          }
          sx={{
            width: 332,
            '& .MuiAutocomplete-inputRoot': {
              py: 0,
            },
            '& .MuiInputLabel-root': {
              top: -7,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="場所"
              sx={{
                '& .MuiInputBase-root': {
                  py: 0,
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
        />
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: '50px',
            left: '120px',
            alignItems: 'center',
          }}
        >
          <Checkbox
            checked={isStarCondition}
            onChange={(e, newValue) => setIsStarCondition(newValue)}
          />
          <Typography variant="body1" textAlign="center">
            スター付き
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          作成者
        </Typography>
        <Autocomplete
          options={users}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          sx={{
            width: 332,
            '& .MuiAutocomplete-inputRoot': {
              py: 0,
            },
            '& .MuiInputLabel-root': {
              top: -7,
            },
          }}
          onChange={(e, newValue) => {
            if (!newValue) setOwnerIdCondition(undefined);
            else setOwnerIdCondition(+newValue.id);
          }}
          value={users.find((user) => user.id === ownerIdCondition) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="作成者"
              sx={{
                '& .MuiInputBase-root': {
                  py: 0,
                },
              }}
            />
          )}
          renderOption={(props, option) => {
            if (option.id === me?.id && option.label === me?.fullname) return;
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
        />
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          ゴンドラ番号
        </Typography>
        <Autocomplete
          options={bayPlans}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            width: 332,
            '& .MuiAutocomplete-inputRoot': {
              py: 0,
            },
            '& .MuiInputLabel-root': {
              top: -7,
            },
          }}
          onChange={(e, newValue) => {
            if (!newValue) setBayPlanIdCondition(undefined);
            else setBayPlanIdCondition(+newValue.id);
          }}
          value={
            bayPlans.find((bayPlan) => bayPlan.id === bayPlanIdCondition) ||
            null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="ゴンドラ番号"
              sx={{
                '& .MuiInputBase-root': {
                  py: 0,
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
        />
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          ステータス
        </Typography>
        <Autocomplete
          options={status}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            width: 332,
            '& .MuiAutocomplete-inputRoot': {
              py: 0,
            },
            '& .MuiInputLabel-root': {
              top: -7,
            },
          }}
          onChange={(e, newValue) => {
            if (!newValue) setStatusCondition(undefined);
            else setStatusCondition(+newValue.id);
          }}
          value={status.find((st) => st.id === statusCondition) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ステータス"
              sx={{
                '& .MuiInputBase-root': {
                  py: 0,
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
        />
      </Box>
    </SearchDialogLayout>
  );
};
