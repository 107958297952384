import { usePlanogramDirectories } from '@api/hooks/usePlanogramDirectories';
import { useSearchPlanogramDirectories } from '@api/hooks/useSearchPlanogramDirectories';
import { ListOrder } from '@components/organisms/gridModeSort/gridModeSort';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRerenderingSidebar } from '@hooks/rerenderingComponents/useRenderingSidebar';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useNavigateToDirectory } from '@hooks/useNavigateToDirectory';
import { useUsersApi } from '@hooks/useUsersApi';
import { setSelectedDirectoryId } from '@reducers/planogram';
import {
  useGetUserQuery,
  useOrganizationStatusesQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  appConstants,
  defaultShelvesViewMode,
  paths,
  rowsPerPage,
  SidebarValue,
} from '@utils/const';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ShelvesViewMode } from 'types/common';
import { PlanogramListOrder } from 'types/planogram';
import { User } from 'types/user';
import {
  BayPlanCodes,
  FilterCondition,
  FormData,
  OrganizationStatuses,
  OwnerId,
  QueryKey,
  schema,
} from '../types';

export const usePlanogramSearch = (directoryId?: string) => {
  const dispatch = useAppDispatch();
  const [isInitRendering, setIsInitRendering] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isConditionModal, setIsConditionModal] = useState(false);
  const [isShowFolderTip, setIsShowFolderTip] = useState(false);
  // directoryIdCondition 0 = root
  const [directoryIdCondition, setDirectoryIdCondition] = useState('0');
  const [isStarCondition, setIsStarCondition] = useState<boolean>(false);
  const [ownerIdCondition, setOwnerIdCondition] = useState<
    number | undefined
  >();
  const [bayPlanIdCondition, setBayPlanIdCondition] = useState<
    number | undefined
  >();
  const [statusCondition, setStatusCondition] = useState<number | undefined>();
  const [folders, setFolders] = useState([
    { id: '0', label: 'すべてのフォルダ' },
  ]);
  const { users, userApiLoading } = useUsersApi();
  const { user: me } = useAppSelector((state) => state.Auth);
  const { data: userData, isLoading: userDataLoading } = useGetUserQuery({
    userId: 'me',
  });
  const { data: organizationStatuses, isLoading: organizationStatusesLoading } =
    useOrganizationStatusesQuery();
  const { bayPlanCodes, bayPlanCodeLoading } = useBayPlanCodes();

  const { navigateToRootDirectory, navigateToSelectedDirectory } =
    useNavigateToDirectory({ folderPath: paths.plans.folders });
  const { navigateBySidebarValue, getFirstOrderQueryParamsForCurrentSideBar } =
    useRerenderingSidebar<PlanogramListOrder>({
      allowedFirstOrder: appConstants.allowedFirstOrder,
      allowedFirstOrderInViewedTab: appConstants.allowedFirstOrderInViewedTab,
      paths: {
        folders: paths.plans.folders,
        foldersStarred: paths.plans.foldersStarred,
        foldersRecentlyScanned: '',
        foldersRecentlyViewed: paths.plans.foldersRecentlyViewed,
      },
      defaultFirstOrderBySideBar: {
        all: 'updated_at_desc',
        favorite: 'updated_at_desc',
        latest: 'updated_at_desc',
        viewed: 'current_user_accessed_at_desc',
      },
    });

  // ゴンドラ番号を取得
  // 関数で扱わないといけない
  const getBayPlanCodeIdQueryParams = useCallback(
    (bayPlanCodes?: BayPlanCodes) => {
      const param = searchParams.get(QueryKey.bayPlanCodeId);
      if (bayPlanCodes && param) {
        const codeArray = param.split(',').map((item) => parseInt(item, 10));
        const bayPlanCodeIdArray = bayPlanCodes
          .filter((code) => codeArray.includes(code.id))
          .map((item) => item.id);

        // 該当しないクエリの値が入った場合、フィルタリングで空配列が返ってくる。空の場合は選択状態ではないため、クエリを削除する
        if (bayPlanCodeIdArray.length === 0) {
          return undefined;
        }

        return bayPlanCodeIdArray;
      }
      // bayPlanCodesに値がないなどの例外
      else {
        return undefined;
      }
    },
    [searchParams]
  );

  // owner_idを取得
  const getOwnerIdQueryParams = useCallback(
    (users?: User[], me?: User): OwnerId => {
      const param = searchParams.get(QueryKey.ownerId);

      // meが取得できない場合は、undefined
      if (!me) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータが存在しない場合、undefined
      if (!param) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータがmeであれば、meデータを返す
      if (param === 'me') {
        return {
          ownerId: [me.id],
          param: 'me',
        };
      } else {
        const ownerId = users
          ?.filter((user) => user.id == parseInt(param, 10))
          .map((user) => user.id);

        if (!ownerId || ownerId.length === 0) {
          return {
            ownerId: undefined,
            param: undefined,
          };
        }

        return {
          ownerId,
          param: ownerId,
        };
      }
    },
    [searchParams]
  );

  // ステータスを取得
  const getOrganizationStatusIdQueryParams = useCallback(
    (statusList?: OrganizationStatuses) => {
      const param = searchParams.get(QueryKey.organizationStatusId);

      // パラメータに指定されている値が存在する場合はidを返す
      if (statusList && param) {
        const id = statusList
          .filter((item) => item.id === parseInt(param, 10))
          .map((item) => item.id);
        return id;
      }
      // 存在しない場合、undefined
      else {
        return undefined;
      }
    },
    [searchParams]
  );

  const getNameQueryParams = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.name);
    if (!param) return;
    return param;
  }, [searchParams]);
  const getIsStarQueryParams = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.isStar);
    if (!param) return;
    return param;
  }, [searchParams]);
  const getDirectoryIdQueryParams = useCallback((): string => {
    return directoryId ? directoryId : '0';
  }, [directoryId]);
  const getConditionFromQueryUrl = useCallback(
    (
      bayPlanCodes?: BayPlanCodes,
      users?: User[],
      me?: User,
      statusList?: OrganizationStatuses
    ) => {
      return {
        firstOrder: getFirstOrderQueryParamsForCurrentSideBar(),
        bayPlanCodeId: getBayPlanCodeIdQueryParams(bayPlanCodes),
        ownerId: getOwnerIdQueryParams(users, me),
        organizationStatusId: getOrganizationStatusIdQueryParams(statusList),
        name: getNameQueryParams(),
        isStar: getIsStarQueryParams(),
        directoryId: getDirectoryIdQueryParams(),
      };
    },
    [
      getBayPlanCodeIdQueryParams,
      getFirstOrderQueryParamsForCurrentSideBar,
      getOrganizationStatusIdQueryParams,
      getOwnerIdQueryParams,
      getNameQueryParams,
      getIsStarQueryParams,
      getDirectoryIdQueryParams,
    ]
  );
  // 検索条件
  const filterCondition = useMemo((): FilterCondition => {
    const selector = getConditionFromQueryUrl(
      bayPlanCodes,
      users,
      me,
      organizationStatuses?.planogram_organization_statuses
    );
    return {
      firstOrder: selector.firstOrder ?? 'updated_at_desc',
      owner_id: selector.ownerId?.ownerId?.at(0),
      bay_plan_code_id: selector.bayPlanCodeId?.at(0),
      status: selector.organizationStatusId?.at(0)?.toString(),
      name: selector.name,
      isStar: selector.isStar === 'true' ? true : false,
      directoryId: selector.directoryId,
    };
  }, [
    bayPlanCodes,
    getConditionFromQueryUrl,
    me,
    organizationStatuses?.planogram_organization_statuses,
    users,
  ]);

  // 検索条件があるか
  const [isSearching, setIsSearching] = useState(
    (directoryIdCondition !== '0' && filterCondition.directoryId !== '0') ||
      !!filterCondition?.name ||
      !!filterCondition?.isStar ||
      !!filterCondition?.owner_id ||
      filterCondition?.status !== undefined ||
      !!filterCondition?.bay_plan_code_id
  );

  const {
    sidebarValue,
    isFilteredByFavorite,
    isFilteredByViewed,
    defaultFirstOrderBySideBar,
  } = useRerenderingSidebar<PlanogramListOrder>({
    allowedFirstOrder: appConstants.allowedFirstOrder,
    allowedFirstOrderInViewedTab: appConstants.allowedFirstOrderInViewedTab,
    paths: {
      folders: paths.plans.folders,
      foldersStarred: paths.plans.foldersStarred,
      foldersRecentlyScanned: '',
      foldersRecentlyViewed: paths.plans.foldersRecentlyViewed,
    },
    defaultFirstOrderBySideBar: {
      all: 'updated_at_desc',
      favorite: 'updated_at_desc',
      latest: 'updated_at_desc',
      viewed: 'current_user_accessed_at_desc',
    },
  });

  // directory か searchedDataどちらかをスキップさせるための条件
  const isSkipListPlanogramDirectoriesQuery =
    isFilteredByFavorite || isFilteredByViewed || isSearching;
  const isSkipSearchPlanogramDirectoriesQuery =
    (!isSkipListPlanogramDirectoriesQuery &&
      !filterCondition?.bay_plan_code_id &&
      !filterCondition?.owner_id &&
      !filterCondition?.status) ||
    (isFilteredByFavorite && !userData?.user.id);

  // 検索条件がない場合はこちらの値が出る
  const {
    data: directory,
    fetchNextPage,
    isFetching: isDirectoryFetching,
    isFetchingNextPage: isDirectoryFetchingNextPage,
    isLoading: isDirectoryLoading,
    hasNextPage: hasDirectoryNextPage,
  } = usePlanogramDirectories({
    id: directoryId,
    ...{
      limit: rowsPerPage,
      firstOrder: appConstants.allowedFirstOrderInViewedTab.includes(
        filterCondition.firstOrder
      )
        ? 'updated_at_desc'
        : filterCondition.firstOrder,
      name: undefined,
      ownerId: undefined,
      bayPlanCodeId: undefined,
      organizationStatusId: undefined,
      enabled: !isSkipListPlanogramDirectoriesQuery,
    },
  });

  /**
   * 検索条件が一つでもあればこちらを使う
   * APIのオプションが異なるので2つを使い分ける必要がある
   */
  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    fetchNextPage: fetchSearchNextPage,
    isFetchingNextPage: isSearchFetchingNextPage,
    hasNextPage: hasSearchNextPage,
  } = useSearchPlanogramDirectories({
    directory_ancestor_id:
      filterCondition.directoryId === '0'
        ? undefined
        : filterCondition.directoryId,
    limit: rowsPerPage,
    first_order: filterCondition.firstOrder,
    name: filterCondition?.name,
    owner_id: filterCondition.owner_id ? [filterCondition.owner_id] : [],
    bay_plan_code_id: filterCondition.bay_plan_code_id
      ? [filterCondition.bay_plan_code_id]
      : [],
    organization_status_id:
      filterCondition.status === '0' || !!filterCondition.status
        ? [+filterCondition.status]
        : undefined,
    favorite_owner_id:
      filterCondition.isStar && userData?.user.id
        ? [userData.user.id]
        : undefined,
    enabled: !isSkipSearchPlanogramDirectoriesQuery,
  });

  const hasNextPage = isSkipListPlanogramDirectoriesQuery
    ? hasSearchNextPage
    : hasDirectoryNextPage;
  const isFetchingNextPage = isSkipListPlanogramDirectoriesQuery
    ? isSearchFetchingNextPage
    : isDirectoryFetchingNextPage;

  /**
   * isSearchLoadingとisDirectoryLoadingは検索リストのloading
   * スター付きタブでは、userDataLoadingの読み込みも待つ必要がある
   */
  const isLoadingData =
    isSearchLoading ||
    isDirectoryLoading ||
    (isFilteredByFavorite && userDataLoading);
  const isFetchingData = isSearchFetching || isDirectoryFetching;

  const isRoot = directory?.parent?.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const planogramDirectories = isSkipSearchPlanogramDirectoriesQuery
    ? directory?.planogram_directories
    : searchedData?.planogram_directories;
  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const isDisplayLoadingSkeleton =
    isApiLoading && !isFetchingNextPage && !isSearchFetchingNextPage;

  const makeSearchParams = useCallback(
    (conditionData: FilterCondition, viewMode: ShelvesViewMode, me?: User) => {
      const allowedKeys = [
        QueryKey.view,
        QueryKey.firstOrder,
        QueryKey.bayPlanCodeId,
        QueryKey.ownerId,
        QueryKey.organizationStatusId,
        QueryKey.name,
        QueryKey.isStar,
      ];
      const {
        firstOrder,
        bay_plan_code_id: bayPlanCodeId,
        owner_id: ownerId,
        status: organizationStatusId,
      } = conditionData;

      const ownerIdQuery =
        me?.id && ownerId === me?.id ? 'me' : ownerId?.toString();
      // value order should accord to allowedKeys
      const queryValues = [
        viewMode,
        firstOrder,
        bayPlanCodeId,
        ownerIdQuery,
        organizationStatusId,
        conditionData?.name,
        conditionData.isStar,
      ];
      // remove invalid query from query URL
      searchParams.forEach((_, key) => {
        if (!allowedKeys.includes(key as QueryKey)) searchParams.delete(key);
      });

      allowedKeys.forEach((key, index) => {
        const value = queryValues[index];
        if (!value) {
          searchParams.delete(key);
        } else {
          let newValue = '';
          switch (key) {
            case QueryKey.bayPlanCodeId || QueryKey.isStar:
              newValue = value.toString();
              break;
            case QueryKey.organizationStatusId:
              newValue = String(value);
              break;
            default:
              newValue = value as string;
          }
          searchParams.set(key, newValue);
        }
      });
      return searchParams;
    },
    [searchParams]
  );
  const viewMode = useMemo((): ShelvesViewMode => {
    const param = searchParams.get(QueryKey.view) as ShelvesViewMode;
    if (['table', 'grid'].includes(param)) return param;
    return defaultShelvesViewMode;
  }, [searchParams]);

  // add query params
  const setFilterCondition = useCallback(
    (
      newCondition: FilterCondition,
      options?: {
        sideBarValue?: SidebarValue;
        isToRootDirectory?: boolean;
        directoryId?: string;
        replace?: boolean;
      }
    ) => {
      const newSearchParams = makeSearchParams(newCondition, viewMode, me);
      if (options?.directoryId) {
        // navigate to selected directory
        navigateToSelectedDirectory(options.directoryId, newSearchParams);
        return;
      }
      if (options?.isToRootDirectory) {
        // navigate to root directory
        navigateToRootDirectory(newSearchParams);
        return;
      }
      if (options?.sideBarValue) {
        // navigate to side bar value
        navigateBySidebarValue(options.sideBarValue, newSearchParams);
        return;
      }

      setSearchParams(newSearchParams, { replace: !!options?.replace });
    },
    [
      makeSearchParams,
      me,
      navigateBySidebarValue,
      navigateToRootDirectory,
      navigateToSelectedDirectory,
      setSearchParams,
      viewMode,
    ]
  );
  const { control, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: filterCondition?.name ?? undefined,
    },
  });
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  /**
   * キーワード入力でキーワードをXボタンで消す時の処理
   */
  const reset = () => {
    setIsFocused(false);
    setValue('searchText', '');
    setFilterCondition(
      {
        ...filterCondition,
        name: undefined,
      },
      {
        isToRootDirectory: false,
      }
    );
  };

  /**
   * 検索モーダルからリセットボタンを押した時の処理
   */
  const handleResetConditions = () => {
    setDirectoryIdCondition('0');
    setIsStarCondition(false);
    setOwnerIdCondition(undefined);
    setBayPlanIdCondition(undefined);
    setStatusCondition(undefined);
  };

  /**
   * 検索モーダルから検索ボタンを押した時の処理
   */
  const handleConditionSubmit = () => {
    setIsConditionModal(false);
    setIsSearching(true);
    directoryIdCondition === '0'
      ? setIsShowFolderTip(false)
      : setIsShowFolderTip(true);
    setFilterCondition(
      {
        name: filterCondition.name,
        directoryId: directoryIdCondition,
        isStar: isStarCondition,
        owner_id: ownerIdCondition,
        bay_plan_code_id: bayPlanIdCondition,
        status:
          statusCondition === 0
            ? statusCondition.toString()
            : statusCondition
            ? statusCondition.toString()
            : undefined,
        firstOrder: filterCondition.firstOrder,
      },
      {
        isToRootDirectory: directoryIdCondition === '0',
        directoryId: directoryIdCondition !== '0' ? directoryIdCondition : '',
        sideBarValue: 'all',
      }
    );
  };

  /**
   * tipを削除した時の処理
   */
  const handleDeleteTip = (key: keyof typeof filterCondition) => {
    switch (key) {
      case 'directoryId':
        setIsShowFolderTip(false);
        setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
        setFilterCondition(
          {
            ...filterCondition,
            directoryId: '0',
          },
          {
            isToRootDirectory: true,
          }
        );
        break;
      case 'isStar':
        setIsStarCondition(false);
        setFilterCondition(
          {
            ...filterCondition,
            isStar: false,
          },
          {
            isToRootDirectory: directoryId === '0',
            directoryId: directoryId === '0' ? '' : directoryId,
          }
        );
        break;
      case 'owner_id':
        setOwnerIdCondition(undefined);
        setFilterCondition({
          ...filterCondition,
          owner_id: undefined,
        });
        break;
      case 'bay_plan_code_id':
        setBayPlanIdCondition(undefined);
        setFilterCondition({
          ...filterCondition,
          bay_plan_code_id: undefined,
        });
        break;
      case 'status':
        setStatusCondition(undefined);
        setFilterCondition({
          ...filterCondition,
          status: undefined,
        });
        break;
    }
  };

  /**
   * 検索モーダルを開いた時の処理
   * 階層がある場合は「場所」に現在のフォルダを追加する
   */
  const handleConditionModalOpen = () => {
    setIsConditionModal(true);
    if (sidebarValue == 'favorite') {
      setIsStarCondition(true);
    }
    if (directory?.parent?.type === 'root') {
      setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
      setDirectoryIdCondition('0');
    } else if (directory?.parent) {
      setFolders([
        { id: '0', label: 'すべてのフォルダ' },
        { id: directory?.parent?.id, label: directory?.parent?.name },
      ]);
      setDirectoryIdCondition(directory?.parent?.id);
    }
  };

  /**
   * ソートを変える
   */
  const handleChangeOrder = (firstOrder?: ListOrder) => {
    setFilterCondition({
      ...filterCondition,
      firstOrder: firstOrder as PlanogramListOrder,
    });
  };

  /**
   * キーワド検索でEnterを押して検索した場合の処理
   */
  const handleSearchText = (keyword: string) => {
    if (keyword === '') return;
    setIsSearching(true);
    if (filterCondition.directoryId === '0') {
      dispatch(setSelectedDirectoryId(''));
      const firstOrder = defaultFirstOrderBySideBar['all'];
      setFilterCondition(
        {
          ...filterCondition,
          name: keyword,
          firstOrder: firstOrder ? firstOrder : filterCondition.firstOrder,
        },
        {
          isToRootDirectory: filterCondition.directoryId === '0',
          sideBarValue: 'all',
        }
      );
    } else {
      // フォルダ内検索
      setIsShowFolderTip(true);
      setFilterCondition(
        {
          ...filterCondition,
          name: keyword,
        },
        {
          isToRootDirectory: false,
        }
      );
    }
  };

  const isConditionDataLoading =
    userApiLoading ||
    bayPlanCodeLoading ||
    organizationStatusesLoading ||
    userDataLoading;

  const setViewMode = useCallback(
    (newViewMode: ShelvesViewMode) => {
      const newSearchParams = makeSearchParams(
        filterCondition,
        newViewMode,
        me
      );
      setSearchParams(newSearchParams);
    },
    [filterCondition, makeSearchParams, me, setSearchParams]
  );

  useEffect(() => {
    // 初回レンダリング時
    if (!isConditionDataLoading && !isInitRendering) {
      setFilterCondition(filterCondition, { replace: true });
      setIsInitRendering(true);
      if (
        !!filterCondition.bay_plan_code_id ||
        !!filterCondition.isStar ||
        !!filterCondition.name ||
        !!filterCondition.owner_id ||
        !!filterCondition.status
      ) {
        setIsSearching(true);
        if (filterCondition.directoryId !== '0') {
          setIsShowFolderTip(true);
        }
      }
    }
  }, [
    filterCondition,
    isInitRendering,
    isConditionDataLoading,
    setFilterCondition,
  ]);

  useBrowserOperate(() => {
    if (isInitRendering) {
      setIsInitRendering(false);
    }
  });

  // check filter conditions to check has tips
  useEffect(() => {
    if (
      !isShowFolderTip &&
      !filterCondition.bay_plan_code_id &&
      !filterCondition.isStar &&
      !filterCondition.name &&
      !filterCondition.owner_id &&
      filterCondition.status === undefined
    ) {
      setIsSearching(false);
    }
  }, [filterCondition, isShowFolderTip, setIsSearching]);

  return {
    directory,
    searchedData,
    hasNextPage,
    isFetchingNextPage,
    isSkipListPlanogramDirectoriesQuery,
    isConditionModal,
    isDisplayLoadingSkeleton,
    isShowBreadcrumbList,
    isRoot,
    isFetchingData,
    isLoadingData,
    planogramDirectories,
    directoryIdCondition,
    isStarCondition,
    ownerIdCondition,
    bayPlanIdCondition,
    filterCondition,
    folders,
    statusCondition,
    control,
    isFocused,
    me,
    viewMode,
    bayPlanCodes,
    users,
    organizationStatuses,
    userData,
    userDataLoading,
    isSearching,
    isShowFolderTip,
    // ↓related sidebar
    sidebarValue,
    defaultFirstOrderBySideBar,
    isFilteredByViewed,
    // ↑↓related sidebar
    setIsShowFolderTip,
    handleFocus,
    setIsSearching,
    reset,
    handleSubmit,
    setIsConditionModal,
    setDirectoryIdCondition,
    setIsStarCondition,
    setOwnerIdCondition,
    setBayPlanIdCondition,
    setStatusCondition,
    setFolders,
    setFilterCondition,
    handleResetConditions,
    handleConditionSubmit,
    handleDeleteTip,
    makeSearchParams,
    setViewMode,
    setSearchParams,
    fetchSearchNextPage,
    fetchNextPage,
    handleConditionModalOpen,
    handleChangeOrder,
    handleSearchText,
  };
};
