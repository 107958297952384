import { FC } from 'react';
import { Product } from '../../../../types/common';
import {
  ProductCandidate,
  RealogramCandidateFace,
} from '../../../../types/realogram';
import { ProductCandidatesList } from '@components/organisms/productCandidatesList/productCandidatesList';
import { CollapsibleListWrapper } from './collapsibleListWrapper';

export const ProductCandidates: FC<{
  products?: Product[];
  selectedCandidate?: ProductCandidate;
  primaryCandidate?: ProductCandidate;
  productCandidates?: ProductCandidate[];
  productCandidatesLength: number;
  handleProductClick: (productCandidate: ProductCandidate) => void;
  face?: RealogramCandidateFace;
}> = ({
  products,
  selectedCandidate,
  primaryCandidate,
  productCandidates,
  productCandidatesLength,
  handleProductClick,
  face,
}) => {
  const offsetWithHighScoreCandidate = 2;
  return (
    <CollapsibleListWrapper
      title="訂正候補"
      hasCollapse={productCandidatesLength >= 1 ? true : false} //NOTE: 最も確信度の高い商品が1番目に入るため
    >
      <ProductCandidatesList
        //offset starts from 1 if product is selected: the selected product is taken to be the highest score candidate
        offset={!face?.is_unknown ? 1 : offsetWithHighScoreCandidate}
        products={products}
        selectedCandidate={selectedCandidate}
        primaryCandidate={primaryCandidate}
        productCandidates={productCandidates?.slice(!face?.is_unknown ? 0 : 1)} //NOTE:render candidates from the second item in the list if product is unknown (first item will be shown in a separate section as the highest score candidate)
        handleClick={handleProductClick}
        face={face}
      />
    </CollapsibleListWrapper>
  );
};
