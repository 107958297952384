import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useNavigateToDirectory } from '@hooks/useNavigateToDirectory';
import { useUsersApi } from '@hooks/useUsersApi';
import {
  GetSearchPlanogramDirectoriesParams,
  useGetUserQuery,
  useOrganizationStatusesQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { SidebarValue, defaultShelvesViewMode, paths } from '@utils/const';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ShelvesViewMode } from 'types/common';
import { User } from 'types/user';
import { useRerenderingSidebar } from './useRenderingSidebar';
import { PlanogramListOrder } from 'types/planogram';

type BayPlanCodes = {
  id: number;
  name: string;
}[];

type OrganizationStatuses = {
  id: number;
  label: string;
}[];

type OwnerId = {
  ownerId: number[] | undefined;
  param: 'me' | number[] | undefined;
};

enum QueryKey {
  firstOrder = 'first_order',
  ownerId = 'owner_id',
  bayPlanCodeId = 'bay_plan_code_id',
  organizationStatusId = 'organization_status_id',
  view = 'view',
  name = 'name',
}
const allowedFirstOrder = [
  // except in access tab
  'created_at_asc',
  'created_at_desc',
  'updated_at_asc',
  'updated_at_desc',
  'name_asc',
  'name_desc',
  'bay_plan_code_asc',
  'bay_plan_code_desc',
];

const allowedFirstOrderInViewedTab = [
  'current_user_accessed_at_asc',
  'current_user_accessed_at_desc',
];

export const useRerenderingPlanogram = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isInitRendering, setIsInitRendering] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const dispatch = useAppDispatch();
  const { user: me } = useAppSelector((state) => state.Auth);

  // utils data for filter form
  const { users, userApiLoading } = useUsersApi();
  const { bayPlanCodes, bayPlanCodeLoading } = useBayPlanCodes();
  const { data: organizationStatuses, isLoading: organizationStatusesLoading } =
    useOrganizationStatusesQuery();
  const { data: userData, isLoading: userDataLoading } = useGetUserQuery({
    userId: 'me',
  });
  const { getFirstOrderQueryParamsForCurrentSideBar } =
    useRerenderingSidebar<PlanogramListOrder>({
      allowedFirstOrder,
      allowedFirstOrderInViewedTab,
      paths: {
        folders: paths.plans.folders,
        foldersStarred: paths.plans.foldersStarred,
        foldersRecentlyScanned: '',
        foldersRecentlyViewed: paths.plans.foldersRecentlyViewed,
      },
      defaultFirstOrderBySideBar: {
        all: 'updated_at_desc',
        favorite: 'updated_at_desc',
        latest: 'updated_at_desc',
        viewed: 'current_user_accessed_at_desc',
      },
    });

  const { navigateToRootDirectory, navigateToSelectedDirectory } =
    useNavigateToDirectory({ folderPath: paths.plans.folders });

  const isConditionDataLoading =
    userApiLoading ||
    bayPlanCodeLoading ||
    organizationStatusesLoading ||
    userDataLoading;

  // Mapping condition to sync to search query url
  const makeSearchParams = useCallback(
    (
      conditionData: GetSearchPlanogramDirectoriesParams,
      viewMode: ShelvesViewMode,
      me?: User
    ) => {
      const allowedKeys = [
        QueryKey.view,
        QueryKey.firstOrder,
        QueryKey.bayPlanCodeId,
        QueryKey.ownerId,
        QueryKey.organizationStatusId,
        QueryKey.name,
      ];
      const { firstOrder, bayPlanCodeId, ownerId, organizationStatusId } =
        conditionData ?? {};

      const ownerIdQuery =
        me?.id && ownerId?.at(0) === me?.id ? 'me' : ownerId?.toString();
      // value order should accord to allowedKeys
      const queryValues = [
        viewMode,
        firstOrder,
        bayPlanCodeId,
        ownerIdQuery,
        organizationStatusId,
        conditionData?.name,
      ];
      // remove invalid query from query URL
      searchParams.forEach((_, key) => {
        if (!allowedKeys.includes(key as QueryKey)) searchParams.delete(key);
      });

      allowedKeys.forEach((key, index) => {
        const value = queryValues[index];
        if (!value) {
          searchParams.delete(key);
        } else {
          let newValue = '';
          switch (key) {
            case QueryKey.bayPlanCodeId:
              newValue = (value as number[]).join(',');
              break;
            case QueryKey.organizationStatusId:
              newValue = String(value.at(0));
              break;
            default:
              newValue = value as string;
          }
          searchParams.set(key, newValue);
        }
      });
      return searchParams;
    },
    [searchParams]
  );

  // ゴンドラ番号を取得
  // 関数で扱わないといけない
  const getBayPlanCodeIdQueryParams = useCallback(
    (bayPlanCodes?: BayPlanCodes) => {
      const param = searchParams.get(QueryKey.bayPlanCodeId);

      if (bayPlanCodes && param) {
        const codeArray = param.split(',').map((item) => parseInt(item, 10));
        const bayPlanCodeIdArray = bayPlanCodes
          .filter((code) => codeArray.includes(code.id))
          .map((item) => item.id);

        // 該当しないクエリの値が入った場合、フィルタリングで空配列が返ってくる。空の場合は選択状態ではないため、クエリを削除する
        if (bayPlanCodeIdArray.length === 0) {
          return undefined;
        }

        return bayPlanCodeIdArray;
      }
      // bayPlanCodesに値がないなどの例外
      else {
        return undefined;
      }
    },
    [searchParams]
  );

  // owner_idを取得
  const getOwnerIdQueryParams = useCallback(
    (users?: User[], me?: User): OwnerId => {
      const param = searchParams.get(QueryKey.ownerId);

      // meが取得できない場合は、undefined
      if (!me) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータが存在しない場合、undefined
      if (!param) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータがmeであれば、meデータを返す
      if (param === 'me') {
        return {
          ownerId: [me.id],
          param: 'me',
        };
      } else {
        const ownerId = users
          ?.filter((user) => user.id == parseInt(param, 10))
          .map((user) => user.id);

        if (!ownerId || ownerId.length === 0) {
          return {
            ownerId: undefined,
            param: undefined,
          };
        }

        return {
          ownerId,
          param: ownerId,
        };
      }
    },
    [searchParams]
  );

  // ステータスを取得
  const getOrganizationStatusIdQueryParams = useCallback(
    (statusList?: OrganizationStatuses) => {
      const param = searchParams.get(QueryKey.organizationStatusId);

      // パラメータに指定されている値が存在する場合はidを返す
      if (statusList && param) {
        const id = statusList
          .filter((item) => item.id === parseInt(param, 10))
          .map((item) => item.id);
        return id;
      }
      // 存在しない場合、undefined
      else {
        return undefined;
      }
    },
    [searchParams]
  );

  const getNameQueryParams = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.name);
    if (!param) return;
    return param;
  }, [searchParams]);

  const getConditionFromQueryUrl = useCallback(
    (
      bayPlanCodes?: BayPlanCodes,
      users?: User[],
      me?: User,
      statusList?: OrganizationStatuses
    ) => {
      return {
        firstOrder: getFirstOrderQueryParamsForCurrentSideBar(),
        bayPlanCodeId: getBayPlanCodeIdQueryParams(bayPlanCodes),
        ownerId: getOwnerIdQueryParams(users, me),
        organizationStatusId: getOrganizationStatusIdQueryParams(statusList),
        name: getNameQueryParams(),
      };
    },
    [
      getBayPlanCodeIdQueryParams,
      getFirstOrderQueryParamsForCurrentSideBar,
      getOrganizationStatusIdQueryParams,
      getOwnerIdQueryParams,
      getNameQueryParams,
    ]
  );

  const hasFilteredData = (condition: GetSearchPlanogramDirectoriesParams) => {
    return Boolean(
      condition?.bayPlanCodeId ||
        condition?.organizationStatusId?.length ||
        condition?.ownerId?.length
    );
  };

  /**
   * Mapping condition from query URL
   */
  const condition = useMemo((): GetSearchPlanogramDirectoriesParams => {
    const selector = getConditionFromQueryUrl(
      bayPlanCodes,
      users,
      me,
      organizationStatuses?.planogram_organization_statuses
    );
    return {
      // firstOrder: selector.firstOrder,
      ownerId: selector.ownerId?.ownerId,
      bayPlanCodeId: selector.bayPlanCodeId,
      organizationStatusId: selector.organizationStatusId,
      name: selector.name,
    };
  }, [
    bayPlanCodes,
    getConditionFromQueryUrl,
    me,
    organizationStatuses?.planogram_organization_statuses,
    users,
  ]);

  const viewMode = useMemo((): ShelvesViewMode => {
    const param = searchParams.get(QueryKey.view) as ShelvesViewMode;
    if (['table', 'grid'].includes(param)) return param;
    return defaultShelvesViewMode;
  }, [searchParams]);

  const setViewMode = useCallback(
    (newViewMode: ShelvesViewMode) => {
      const newSearchParams = makeSearchParams(condition, newViewMode, me);
      setSearchParams(newSearchParams);
    },
    [condition, makeSearchParams, me, setSearchParams]
  );

  /**
   * @description set condition and sync its value to query url
   * @param conditionData new condition value
   * @param sideBarValue sync condition to query url then navigate to the sidebar value
   * @param isToRootDirectory sync condition to query url then navigate to the root directory
   * @param directoryId sync condition to query url then navigate to the selected directory
   * @param replace should create new entry on the history stack.
   */
  const setCondition = useCallback(
    (
      newCondition: GetSearchPlanogramDirectoriesParams,
      options?: {
        sideBarValue?: SidebarValue;
        isToRootDirectory?: boolean;
        directoryId?: string;
        replace?: boolean;
      }
    ) => {
      const newSearchParams = makeSearchParams(newCondition, viewMode, me);
      if (options?.directoryId) {
        // navigate to selected directory
        navigateToSelectedDirectory(options.directoryId, newSearchParams);
        return;
      }
      if (options?.isToRootDirectory) {
        // navigate to root directory
        navigateToRootDirectory(newSearchParams);
        return;
      }
      if (options?.sideBarValue) {
        // navigate to side bar value
        // navigateBySidebarValue(options.sideBarValue, newSearchParams);
        return;
      }
      // just sync query url
      setSearchParams(newSearchParams, { replace: !!options?.replace });
    },
    [
      makeSearchParams,
      me,
      // navigateBySidebarValue,
      navigateToRootDirectory,
      navigateToSelectedDirectory,
      setSearchParams,
      viewMode,
    ]
  );

  /**
   * Set condition from query url when the page is initialized at the first time.
   */
  useEffect(() => {
    if (!isConditionDataLoading && !isInitRendering) {
      setCondition(condition, { replace: true });
      setIsShowFilters(hasFilteredData(condition));
      setIsInitRendering(true);
    }
  }, [
    condition,
    dispatch,
    isInitRendering,
    isConditionDataLoading,
    setCondition,
  ]);

  /**
   * if a user click browser back button, reset rendering condition
   * Set condition from query url when the page is initialized at the first time.
   */
  useBrowserOperate(() => {
    if (isInitRendering) {
      setIsInitRendering(false);
    }
  });

  return {
    isInitRendering,
    condition,
    isShowFilters,
    viewMode,

    setCondition,
    setIsShowFilters,
    setViewMode,

    conditionData: {
      bayPlanCodes,
      users,
      organizationStatuses,
      me,
      userData,
      userDataLoading,
    },
    sidebarData: {
      // isFilteredByFavorite,
      // isFilteredByViewed,
      // sidebarValue,
      // defaultFirstOrderBySideBar,
    },
  };
};
