import { FC } from 'react';

export const ZoomOut: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5859 10.5859C19.5859 15.5565 15.5565 19.5859 10.5859 19.5859C5.61537 19.5859 1.58594 15.5565 1.58594 10.5859C1.58594 5.61537 5.61537 1.58594 10.5859 1.58594C15.5565 1.58594 19.5859 5.61537 19.5859 10.5859ZM17.5859 10.5859C17.5859 14.4519 14.4519 17.5859 10.5859 17.5859C6.71994 17.5859 3.58594 14.4519 3.58594 10.5859C3.58594 6.71994 6.71994 3.58594 10.5859 3.58594C14.4519 3.58594 17.5859 6.71994 17.5859 10.5859ZM16.5351 17.9499L17.9493 16.5357L21.4848 20.0712L20.0706 21.4854L16.5351 17.9499ZM5.58594 9.58594L15.5859 9.58594V11.5859L5.58594 11.5859L5.58594 9.58594Z"
        fill="black"
        fillOpacity="0.65"
      />
    </svg>
  );
};
