import { StyledListItemButton } from '@components/organisms/productCandidatesList/productCandidatesList';
import { Search } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { CommonColors } from '@utils/colors';
type Props = {
  handleClick: () => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledIconWrapper = styled(Box)(() => ({
  width: 20,
  minHeight: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledBox = styled(Box)(() => ({
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 16px',
  gap: '8px',
  width: '100%',
  borderRadius: 4,
  border: `1px solid ${CommonColors.appBlue}`,
}));

export const ProductChoiceCard: FC<Props> = ({ handleClick }) => {
  return (
    <StyledListItemButton data-testid="ProductChoiceCard" onClick={handleClick}>
      <StyledBox>
        <StyledIconWrapper>
          <Search sx={{ width: 20, height: 20, color: CommonColors.appBlue }} />
        </StyledIconWrapper>

        <Typography
          variant="buttonText1"
          ml={0.5}
          sx={{ color: CommonColors.appBlue }}
        >
          自分で商品を選ぶ
        </Typography>
      </StyledBox>
    </StyledListItemButton>
  );
};
