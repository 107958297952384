import { createSelector } from '@reduxjs/toolkit';
import { selectAppState } from '@store/index';

export const selectScannerState = createSelector(
  selectAppState,
  (state) => state.Scanner
);

export const selectRealogramsScrollPos = createSelector(
  selectAppState,
  (state) => state.Scanner.realogramScrollPos
);
