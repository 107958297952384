import { useGetRealogramAnalyticsQuery } from '@reducers/shelfAppsApi';
import { Format, format } from '@utils/date';
import { useEffect, useState } from 'react';
import { ProductSalesReport } from 'types/products';

const displayWeeks = 6;
export const useRealogramAnalyticsData = (
  realogramCandidateId: number,
  isSkip: boolean,
  createdAt?: string
) => {
  const {
    data: analyticsData,
    refetch: refetchRealogramAnalytics,
    isLoading: isLoadingAnalyticsData,
  } = useGetRealogramAnalyticsQuery(
    {
      timeWindow: 'weekly',
      limit: 21,
      realogramCandidateId,
    },
    {
      skip: isSkip,
    }
  );

  const analyticsDataLength = analyticsData?.reports.length
    ? analyticsData.reports.length - 1
    : 0;

  const [displayAnalyticsData, setDisplayAnalyticsData] =
    useState<ProductSalesReport>();
  const [displayAnalyticsDataIndex, setDisplayAnalyticsDataIndex] = useState(0);
  const [minDisplayAnalyticsDataIndex, setMinDisplayAnalyticsDataIndex] =
    useState(0);
  const [maxDisplayAnalyticsDataIndex, setMaxDisplayAnalyticsDataIndex] =
    useState(0);
  const [analyticsDataTerm, setAnalyticsDataTerm] = useState('データなし');

  useEffect(() => {
    if (!analyticsData || analyticsData.reports.length === 0) return;
    setDisplayAnalyticsData(analyticsData.reports[displayAnalyticsDataIndex]);
    setAnalyticsDataTerm(
      createTerm(
        analyticsData.reports.at(displayAnalyticsDataIndex)?.summary
          .aggregation_period.start_date,
        analyticsData.reports.at(displayAnalyticsDataIndex)?.summary
          .aggregation_period.end_date
      )
    );
  }, [analyticsData, displayAnalyticsDataIndex]);
  useEffect(() => {
    if (createdAt && analyticsData) {
      const givenTime = new Date(createdAt);
      analyticsData.reports.map((repo, index) => {
        const startDate = new Date(repo.summary.aggregation_period.start_date);
        const endDate = new Date(repo.summary.aggregation_period.end_date);
        const min = index - displayWeeks <= 0 ? 0 : index - displayWeeks;
        const max =
          index + displayWeeks > analyticsDataLength
            ? analyticsDataLength
            : index + displayWeeks;

        if (givenTime >= startDate && givenTime <= endDate) {
          setDisplayAnalyticsDataIndex(index);
          setMinDisplayAnalyticsDataIndex(min);
          setMaxDisplayAnalyticsDataIndex(max);
          return;
        }
        if (givenTime > endDate && index === 0) {
          setMaxDisplayAnalyticsDataIndex(max);
        }
      });
    }
  }, [createdAt, analyticsData, analyticsDataLength]);

  const createTerm = (startDate?: string, endDate?: string) => {
    if (!startDate || !endDate) return 'データなし';
    return `${format(startDate, Format.dateWithoutYear)} ~ ${format(
      endDate,
      Format.dateWithoutYear
    )}`;
  };

  const disableChangeToPreviousWeek =
    analyticsDataTerm === 'データなし' ||
    displayAnalyticsDataIndex === maxDisplayAnalyticsDataIndex;

  const disableChangeToNextWeek =
    analyticsDataTerm === 'データなし' ||
    displayAnalyticsDataIndex === minDisplayAnalyticsDataIndex;

  const handleNextWeekTerm = () => {
    if (displayAnalyticsDataIndex <= minDisplayAnalyticsDataIndex) return;
    setDisplayAnalyticsDataIndex(displayAnalyticsDataIndex - 1);
  };
  const handlePreviousWeekTerm = () => {
    if (displayAnalyticsDataIndex === maxDisplayAnalyticsDataIndex) return;
    setDisplayAnalyticsDataIndex(displayAnalyticsDataIndex + 1);
  };

  return {
    analyticsData,
    refetchRealogramAnalytics,
    isLoadingAnalyticsData,
    analyticsDataTerm,
    displayAnalyticsData,
    disableChangeToPreviousWeek,
    disableChangeToNextWeek,
    displayAnalyticsDataIndex,
    handleNextWeekTerm,
    handlePreviousWeekTerm,
  };
};
