import { PlanogramListOrder } from 'types/planogram';
import { InferType, object, string } from 'yup';

export type BayPlanCodes = {
  id: number;
  name: string;
}[];

export type OrganizationStatuses = {
  id: number;
  label: string;
}[];

export const schema = object({
  searchText: string().required(),
});
export type FormData = InferType<typeof schema>;

export enum QueryKey {
  firstOrder = 'first_order',
  ownerId = 'owner_id',
  bayPlanCodeId = 'bay_plan_code_id',
  organizationStatusId = 'organization_status_id',
  view = 'view',
  name = 'name',
  isStar = 'star',
  directoryId = 'directoryId',
}

export type OwnerId = {
  ownerId: number[] | undefined;
  param: 'me' | number[] | undefined;
};

export type FilterCondition = {
  name: string | undefined;
  directoryId: string;
  isStar: boolean;
  owner_id: number | undefined;
  bay_plan_code_id: number | undefined;
  status: string | undefined;
  firstOrder: PlanogramListOrder;
};

export type CustomErrorResponse = {
  detail: string;
};
