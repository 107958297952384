import { BottleIconVisibility } from '@components/molecules/bottleIconVisibility/bottleIconVisibility';
import { BottleIconVisibilityOff } from '@components/molecules/bottleIconVisibilityOff/bottleIconVisibilityOff';
import { StyledIconButton } from '@components/molecules/faceAreaControllerBox/fragments/createdAt';
import { FC } from 'react';
import { theme } from 'theme';
import { SxProps } from '@mui/material';

type Props = {
  enabled: boolean;
  onClick: () => void;
  styleBtn?: SxProps;
};

export const FaceAreaButton: FC<Props> = ({ enabled, onClick, styleBtn }) => {
  const backgroundColor = enabled ? theme.palette.primary.selected : '#fff';
  return (
    <StyledIconButton
      onClick={onClick}
      sx={{
        width: '40px',
        height: '48px',
        backgroundColor,
        border: enabled
          ? `1px solid ${theme.palette.primary.main}`
          : '1px solid #CCCCCC',
        '&:hover': {
          backgroundColor,
        },
        ...styleBtn,
      }}
    >
      {enabled ? (
        <BottleIconVisibility
          sx={{ fill: theme.palette.primary.selected }}
          color="primary"
        />
      ) : (
        <BottleIconVisibilityOff sx={{ fill: 'white' }} />
      )}
    </StyledIconButton>
  );
};
