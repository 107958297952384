import { planogramDirectoriesQueryKey } from '@api/hooks/usePlanogramDirectories';
import { planogramDirectoriesSearchQueryKey } from '@api/hooks/useSearchPlanogramDirectories';
import { queryClient } from '@api/query-client';
import { planogramsApi } from '@api/services/planogram';
import { CloneRequest, PutPlanogram } from '@api/types/generated';
import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { planogramDirectoryEmptyMessage } from '@components/molecules/emptyResult/emptyResult';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import {
  AppBar,
  EditPlanogramModalProps,
  PlanogramDeleteDialog,
  PlanogramsTable,
} from '@components/organisms';
import { ClonePlanogramModalProps } from '@components/organisms/clonePlanogramForm/clonePlanogramForm';
import { GridModeSort } from '@components/organisms/gridModeSort/gridModeSort';
import { PlanogramsGrid } from '@components/organisms/planogramsGrid/planogramsGrid';
import { PlonogramsSidebar } from '@components/organisms/planogramsSidebar/planogramsSidebar';
import { useCreatePlanogram } from '@hooks/useCreatePlanogram';
import { useDirectoryManagement } from '@hooks/useDirectoryManagement';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useModal } from '@hooks/useModal';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  setLastVisitedPlanogramListURL,
  setSelectedDirectoryId,
} from '@reducers/planogram';
import {
  setCurrentTypeFile,
  setDirectoryId,
  setDirectoryType,
  setIsViewFolder,
  setPlanogramDirectoryParent,
} from '@reducers/sharePermission';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import {
  SidebarValue,
  getListPageTitle,
  getSortOptions,
  pageTitle,
  paths,
  toastMessages,
} from '@utils/const';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { DirectoryBreadcrumbs, DirectoryType } from 'types/common';
import { PlanogramDirectory } from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { Close, Search } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import TuneIcon from '@mui/icons-material/Tune';
import { PlanogramSearchDialog } from './fragments/planogramSearchDialog';
import { usePlanogramSearch } from './hooks/usePlanogramSearch';
import { CustomErrorResponse } from './types';
import { SearchTipsArea } from './fragments/searchTipsArea';

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (isRoot) {
    return '棚割計画';
  } else {
    return directoryParentName;
  }
};

const queryList = ['bay_plan_code_id', 'owner_id', 'organization_status_id'];

export const Planograms: FC = () => {
  usePageTitle('棚割計画一覧');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedPlanogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedPlanogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const handleCreatePlanogram = useCreatePlanogram();
  const { showModal: showClonePlanogramModal } =
    useModal<ClonePlanogramModalProps>('clonePlanogram', '棚割を複製');
  const { showModal: showMoveDirectoryModal } = useModal('moveDirectory', '');
  const { showModal: showRenamePlanogramModal } =
    useModal<EditPlanogramModalProps>(
      'editPlanogram',
      'ゴンドラ名・ゴンドラ番号変更'
    );
  const { showLicenseModal } = useSharePermissionModal();
  const { hasQueryParameters } = useQueryParameterPresence();
  const hasQueries = hasQueryParameters(queryList);

  const { mutateAsync: clonePlanogram } = useMutation({
    mutationFn: (params: CloneRequest) => planogramsApi.clonePlanogram(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });
  const { mutateAsync: putPlanogram } = useMutation({
    mutationFn: ({
      planogramId,
      ...params
    }: PutPlanogram & { planogramId: number }) =>
      planogramsApi.putPlanogram(planogramId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });
  const { mutateAsync: deletePlanogramDirectory } = useMutation({
    mutationFn: (planogramId: string) =>
      planogramsApi.deletePlanogramDirectory(planogramId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });
  const { mutateAsync: addToFavorite } = useMutation({
    mutationFn: (planogramId: string) =>
      planogramsApi.addToFavorite(planogramId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });
  const { mutateAsync: removeFromFavorite } = useMutation({
    mutationFn: (planogramId: string) =>
      planogramsApi.removeFromFavorite(planogramId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectPlanogramDirectory, setSelectPlanogramDirectory] =
    useState<PlanogramDirectory>();
  const params = useParams();
  const directoryId = params.id;

  const { pathname, search } = location;
  const currentUrl = pathname + search;

  const {
    directory,
    hasNextPage,
    isFetchingNextPage,
    isSkipListPlanogramDirectoriesQuery,
    isConditionModal,
    isShowBreadcrumbList,
    isRoot,
    isFetchingData,
    isLoadingData,
    planogramDirectories,
    directoryIdCondition,
    isStarCondition,
    ownerIdCondition,
    bayPlanIdCondition,
    filterCondition,
    folders,
    statusCondition,
    control,
    isFocused,
    viewMode,
    bayPlanCodes,
    users,
    me,
    organizationStatuses,
    isSearching,
    isShowFolderTip,
    isDisplayLoadingSkeleton,
    sidebarValue,
    defaultFirstOrderBySideBar,
    isFilteredByViewed,
    setIsSearching,
    handleSubmit,
    handleFocus,
    reset,
    setFilterCondition,
    setIsConditionModal,
    setDirectoryIdCondition,
    setIsStarCondition,
    setOwnerIdCondition,
    setBayPlanIdCondition,
    setStatusCondition,
    handleResetConditions,
    handleConditionSubmit,
    handleDeleteTip,
    setViewMode,
    fetchSearchNextPage,
    fetchNextPage,
    handleConditionModalOpen,
    handleChangeOrder,
    handleSearchText,
  } = usePlanogramSearch(directoryId);

  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: directory?.parent as PlanogramDirectory,
    isPlanogram: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: directory?.parent as PlanogramDirectory,
    isPlanogram: false,
    isCan: true,
  });

  // 閲覧権限関連
  useEffect(() => {
    if (directory?.parent?.type === 'directory') {
      dispatch(setIsViewFolder(true));
      dispatch(
        setPlanogramDirectoryParent(directory.parent as PlanogramDirectory)
      );
    } else {
      dispatch(setIsViewFolder(false));
      dispatch(setPlanogramDirectoryParent(undefined));
    }
  }, [directory, dispatch]);

  // 閲覧権限関連
  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory?.parent?.id &&
      directory?.parent?.type !== 'root'
    ) {
      dispatch(setIsViewFolder(true));
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  const handleClickDirectory = (item: PlanogramDirectory) => {
    const directoryId = item.id;
    dispatch(setSelectedDirectoryId(directoryId));
    setIsSearching(false);
    setFilterCondition(
      {
        name: undefined,
        directoryId: '0',
        isStar: false,
        owner_id: undefined,
        bay_plan_code_id: undefined,
        status: undefined,
        firstOrder: filterCondition.firstOrder,
      },
      {
        directoryId,
      }
    );
  };

  const handleRowClick = (item: PlanogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
      setIsSearching(false);
      setFilterCondition(filterCondition, {
        directoryId: item.id,
      });
    } else if (item.type === 'file' && item.planogram_id) {
      dispatch(setLastVisitedPlanogramListURL(currentUrl));
      setSessionLastVisitedPlanogramListURL(currentUrl);
      navigate(paths.plans.planogramId(Number(item.planogram_id)));
    }
  };

  const handleClickMoveParentDirectory = (parentId?: string) => {
    // ディレクトリ移動したら検索モードではなくなる
    setIsSearching(false);
    if (directory?.breadcrumb?.length === 1 || !parentId) {
      dispatch(setSelectedDirectoryId(''));
      setFilterCondition(
        {
          ...filterCondition,
          directoryId: '0',
        },
        {
          isToRootDirectory: true,
        }
      );
    } else {
      dispatch(setSelectedDirectoryId(parentId));
      setFilterCondition(
        {
          ...filterCondition,
          directoryId: parentId,
        },
        {
          directoryId: parentId,
        }
      );
    }
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    // パンくずをクリックしたら検索モードではなくなる
    setIsSearching(false);
    const isBackToRootDirectory = directory.type === 'root';
    if (isBackToRootDirectory) {
      setFilterCondition(
        {
          ...filterCondition,
          directoryId: '0',
        },
        {
          isToRootDirectory: true,
        }
      );
      dispatch(setSelectedDirectoryId(''));
    } else {
      setFilterCondition(
        {
          ...filterCondition,
          directoryId: directory.id,
        },
        {
          directoryId: directory.id,
        }
      );
      dispatch(setSelectedDirectoryId(directory.id));
    }
  };

  const handleOpenDeleteDialog = (planogramDirecotry?: PlanogramDirectory) => {
    if (!planogramDirecotry) return;
    setDeleteDialogOpen(true);
    setSelectPlanogramDirectory(planogramDirecotry);
  };

  const handleDeleteShelfEditorItem = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    const typeName =
      selectPlanogramDirectory?.type === 'directory' ? 'フォルダ' : '棚割';
    try {
      if (!selectPlanogramDirectory) return;
      await deletePlanogramDirectory(selectPlanogramDirectory.id);

      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteShelfEditorItem(typeName),
        })
      );
    } catch (error) {
      const axiosError = error as AxiosError<CustomErrorResponse>;

      if (axiosError.isAxiosError) {
        if (axiosError?.response?.data?.detail === 'Directory is not empty') {
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.directoryIsNotEmpty,
            })
          );
          return;
        } else if (
          axiosError.response?.data.detail === 'Operation not permitted'
        ) {
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.directoryNotPermitted,
            })
          );
          return;
        }
      }
      console.error(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteShelfEditorItem(typeName),
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleClonePlanogram = (planogramDirecotry: PlanogramDirectory) => {
    const { planogram, parent_id } = planogramDirecotry;
    if (!planogram) return;
    showClonePlanogramModal({
      name: `${planogram.name}（コピー）`,
      bayPlanId: planogram.bay_plan_id,
      onSubmit: async ({ name, bayPlanId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await clonePlanogram({
            name,
            origin_planogram_id: planogram.id,
            bay_plan_id: bayPlanId,
            directory_parent_id: parent_id,
          });
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.clonePlanogram,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.clonePlanogram,
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  const handleMoveDirectory = (targetDirectory?: PlanogramDirectory) => {
    if (!targetDirectory) return;
    showMoveDirectoryModal({
      sidebarValue,
      targetDirectory,
      displayFolder: () => {
        handleClickSidebar('all'); // reset all conditions and moving to the all tab
      },
    });
  };
  const handleUpdatePlanogramName = (
    planogramDirectory?: PlanogramDirectory
  ) => {
    if (!planogramDirectory?.planogram) return;
    const { planogram } = planogramDirectory;
    showRenamePlanogramModal({
      name: planogram.name,
      bayPlanCodeId: planogram.bay_plan_code_id,
      onSubmit: async ({ name, bayPlanCodeId }) => {
        dispatch(updateLoadingIndicatorState(true));

        try {
          if (planogramDirectory.type === 'file') {
            await putPlanogram({
              planogramId: planogram.id,
              name,
              bay_plan_id: planogram.bay_plan_id,
              bay_plan_code_id: bayPlanCodeId,
            });
          }

          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.updatePlanogramName,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.updatePlanogramName,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await removeFromFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addToFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSidebar = (value: SidebarValue) => {
    setIsSearching(false);
    if (value === sidebarValue) return;
    const firstOrder = defaultFirstOrderBySideBar[value];
    dispatch(setSelectedDirectoryId(''));
    setIsSearching(false);
    if (!firstOrder) return;
    if (value === 'favorite') {
      setFilterCondition(
        {
          name: undefined,
          directoryId: '0',
          isStar: true,
          owner_id: undefined,
          bay_plan_code_id: undefined,
          status: undefined,
          firstOrder,
        },
        {
          sideBarValue: value,
        }
      );
    } else {
      setFilterCondition(
        {
          name: undefined,
          directoryId: '0',
          isStar: false,
          owner_id: undefined,
          bay_plan_code_id: undefined,
          status: undefined,
          firstOrder,
        },
        {
          sideBarValue: value,
        }
      );
    }
  };

  const goToCreatedDirectory = (newDirectoryId: string) => {
    const firstOrder =
      defaultFirstOrderBySideBar[sidebarValue] ?? 'updated_at_desc';
    setIsSearching(false);
    const newCondition = {
      ...filterCondition,
      firstOrder,
      name: undefined,
      bayPlanCodeId: undefined,
      ownerId: undefined,
      status: undefined,
    };
    dispatch(setSelectedDirectoryId(newDirectoryId));
    setFilterCondition(
      {
        ...newCondition,
        directoryId: newDirectoryId,
      },
      {
        directoryId: newDirectoryId,
      }
    );
  };

  const afterCreateDirectorySuccessfully = (newDirectoryId: string) => {
    dispatch(
      openToast({
        type: 'success',
        message: toastMessages.success.createDirectory,
        renderProps: (
          <Typography
            variant="buttonText2"
            color={theme.palette.primary.main}
            mx="12px"
            onClick={() => {
              if (newDirectoryId) {
                goToCreatedDirectory(newDirectoryId);
              }
            }}
            sx={{ cursor: 'pointer' }}
          >
            フォルダを開く
          </Typography>
        ),
      })
    );
  };

  const { handleCreateDirectory, handleUpdateDirectoryName } =
    useDirectoryManagement({
      afterCreateDirectorySuccessfully,
    });

  const handleEndReached = (isAtBottom: boolean) => {
    if (!isAtBottom || !hasNextPage || isFetchingNextPage) return;
    if (isSkipListPlanogramDirectoriesQuery) {
      fetchSearchNextPage();
    } else {
      fetchNextPage();
    }
  };

  const handleSharePermission = (item: PlanogramSharePermissionProps) => {
    if (!item.planogramDirectory.id) return;
    dispatch(setDirectoryId(`${item.planogramDirectory.id}`));
    dispatch(setDirectoryType('planogram'));
    dispatch(setCurrentTypeFile(item.planogramDirectory.type));
    showLicenseModal({ type: item.type });
  };
  return (
    <>
      <Box component="div" sx={{ overflowY: 'hidden' }}>
        <AppBar title="棚割計画（棚エディタ）" />
        <Box component="div" display="flex">
          <PlonogramsSidebar
            handleCreatePlanogram={handleCreatePlanogram}
            handleCreateDirectory={handleCreateDirectory}
            sidebarValue={sidebarValue}
            notActivate={isSearching}
            onClickTabAll={() => handleClickSidebar('all')}
            onClickTabFavorite={() => handleClickSidebar('favorite')}
            onClickTabViewed={() => handleClickSidebar('viewed')}
          />
          <Box component="div" mt={2} px={3} flex={1}>
            {/*56px = 40 AppBar + 16px margin*/}
            <Box
              component="div"
              height="calc(100vh - 56px)"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Box component="div">
                <Box
                  width="100%"
                  component="div"
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={handleSubmit((data) =>
                      handleSearchText(data.searchText)
                    )}
                    noValidate
                    display="flex"
                  >
                    <Controller
                      name="searchText"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="キーワードで検索"
                          onFocus={handleFocus}
                          size="small"
                          sx={{
                            width: '320px',
                            'input::placeholder': {
                              fontSize: '14px',
                            },
                          }}
                          InputProps={{
                            sx: {
                              borderRadius: '40px',
                              backgroundColor: '#F7F7F7',
                            },
                            startAdornment: (
                              <Search
                                sx={{
                                  color: theme.palette.icons.primary,
                                  mr: '4px',
                                }}
                              />
                            ),
                            endAdornment: (
                              <>
                                {isFocused && (
                                  <IconButton
                                    onClick={() => {
                                      reset();
                                    }}
                                  >
                                    <Close
                                      sx={{
                                        width: '18px',
                                        height: '18px',
                                        cursor: 'pointer',
                                        color: theme.palette.icons.primary,
                                      }}
                                    />
                                  </IconButton>
                                )}
                                <IconButton onClick={handleConditionModalOpen}>
                                  <TuneIcon
                                    sx={{
                                      width: '18px',
                                      height: '18px',
                                      cursor: 'pointer',
                                      color: theme.palette.icons.primary,
                                    }}
                                  />
                                </IconButton>
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <PlanogramSearchDialog
                    isOpen={isConditionModal}
                    folders={folders}
                    me={me}
                    users={
                      me
                        ? [
                            {
                              id: me?.id,
                              label: `自分 (${me?.fullname})`,
                            },
                            ...(users?.map((user) => {
                              return {
                                id: user.id,
                                label: user.fullname,
                              };
                            }) ?? []),
                          ]
                        : users?.map((user) => {
                            return {
                              id: user.id,
                              label: user.fullname,
                            };
                          }) ?? []
                    }
                    bayPlans={
                      bayPlanCodes?.map((bay) => {
                        return {
                          id: bay.id,
                          label: bay.name,
                        };
                      }) ?? []
                    }
                    status={
                      organizationStatuses?.planogram_organization_statuses ??
                      []
                    }
                    directoryIdCondition={directoryIdCondition}
                    setDirectoryIdCondition={setDirectoryIdCondition}
                    isStarCondition={isStarCondition}
                    setIsStarCondition={setIsStarCondition}
                    ownerIdCondition={ownerIdCondition}
                    setOwnerIdCondition={setOwnerIdCondition}
                    bayPlanIdCondition={bayPlanIdCondition}
                    setBayPlanIdCondition={setBayPlanIdCondition}
                    statusCondition={statusCondition}
                    setStatusCondition={setStatusCondition}
                    handleClose={() => {
                      setIsConditionModal(false);
                    }}
                    handleResetConditions={handleResetConditions}
                    handleSubmit={handleConditionSubmit}
                  />
                  <ShelvesViewToggle
                    mode={viewMode}
                    onChange={(mode) => {
                      setViewMode(mode);
                    }}
                  />
                </Box>

                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {isShowBreadcrumbList && !isSearching ? (
                    <Box component="div" flex={1}>
                      {!isRoot && (
                        <Box component="div" marginLeft={5} mb={0.5}>
                          <BreadcrumbList
                            breadcrumbs={
                              directory?.breadcrumb as DirectoryBreadcrumbs
                            }
                            parentDirectory={directory?.parent}
                            screenType="Main"
                            fontSize="12px"
                            handleClickBreadcrumbs={handleClickBreadcrumbs}
                            directoryRootName="棚割計画"
                          />
                        </Box>
                      )}
                      <Box component="div" display="flex" alignItems="center">
                        {!isRoot && (
                          <GoParentDirectoryButton
                            width={32}
                            height={32}
                            handleClick={() => {
                              handleClickMoveParentDirectory(
                                directory?.parent?.parent_id
                              );
                            }}
                          />
                        )}
                        <Typography
                          fontSize="20px"
                          fontWeight="bold"
                          display="flex"
                          alignItems="center"
                          mr={!isRoot ? '6px' : ''}
                        >
                          {getDirectoryPageTitle(
                            sidebarValue,
                            isRoot,
                            directory?.parent?.name ?? ''
                          )}
                          {/* TODO: フォルダを作成したユーザーのみ編集を可能にすること */}
                          {/* NOTE: フォルダ名変更するためのユーザー情報が取得できないため、誰もフォルダ名の変更を許可しません。そのためペンアイコンは非表示とします。 */}
                          {/* {directory.parent.type === 'directory' && (
                              <IconButton
                                component="button"
                                onClick={() =>
                                  handleUpdateDirectoryName(directory.parent)
                                }
                              >
                                <CreateIcon fontSize="small" />
                              </IconButton>
                            )} */}
                        </Typography>
                        {!isRoot && (
                          <ActionVisible
                            isCanUpdate={isCanUpdate}
                            isCanRead={isCanRead}
                          />
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {isSearching && sidebarValue === 'all' ? (
                        <Box
                          component="div"
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          gap="16px"
                          height="52px"
                        >
                          <Typography fontSize="20px" fontWeight="bold">
                            {getListPageTitle({
                              defaultTitle: pageTitle.planogram,
                              sidebarValue,
                              isSearched: isSearching,
                            })}
                          </Typography>
                          <SearchTipsArea
                            isShowFolderTip={isShowFolderTip}
                            directoryName={directory?.parent?.name}
                            filterCondition={filterCondition}
                            users={users}
                            bayPlanCodes={bayPlanCodes}
                            status={
                              organizationStatuses?.planogram_organization_statuses
                            }
                            handleConditionModalOpen={handleConditionModalOpen}
                            handleDeleteTip={handleDeleteTip}
                          />
                        </Box>
                      ) : (
                        <Typography fontSize="20px" fontWeight="bold">
                          {getListPageTitle({
                            defaultTitle: pageTitle.planogram,
                            sidebarValue,
                            isSearched: false,
                          })}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              {viewMode === 'grid' && (
                <GridModeSort
                  sortOptions={getSortOptions(
                    'planogram',
                    sidebarValue === 'viewed'
                  )}
                  isLoadingData={isFetchingData || isLoadingData}
                  firstOrder={filterCondition.firstOrder}
                  handleChangeOrder={handleChangeOrder}
                />
              )}
              <Box component="div" mb={2} mt={1} height="100%">
                {viewMode === 'table' ? (
                  <PlanogramsTable
                    handleRowClick={handleRowClick}
                    planogramDirectories={
                      planogramDirectories as PlanogramDirectory[]
                    }
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleClonePlanogram={handleClonePlanogram}
                    handleUpdatePlanogramName={handleUpdatePlanogramName}
                    handleUpdateDirectoryName={handleUpdateDirectoryName}
                    handleMoveDirectory={handleMoveDirectory}
                    isRefetching={isFetchingData}
                    emptyTableMessage={planogramDirectoryEmptyMessage(
                      hasQueries,
                      directory?.parent?.type === 'directory'
                    )}
                    emptyTableTitle="棚割・フォルダがありません"
                    handleFavoriteClick={handleFavoriteClick}
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={filterCondition.firstOrder}
                    handleChangeOrder={handleChangeOrder}
                    handleSharePermission={handleSharePermission}
                    handleEndReached={handleEndReached}
                    isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
                    isEmpty={!planogramDirectories?.length && !isLoadingData}
                  />
                ) : (
                  <PlanogramsGrid
                    parent={directory?.parent as PlanogramDirectory}
                    planogramDirectories={
                      planogramDirectories as PlanogramDirectory[]
                    }
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleClonePlanogram={handleClonePlanogram}
                    handleUpdatePlanogramName={handleUpdatePlanogramName}
                    handleUpdateDirectoryName={handleUpdateDirectoryName}
                    handleMoveDirectory={handleMoveDirectory}
                    isLoading={
                      (isLoadingData || isFetchingData) && !isFetchingNextPage
                    }
                    isRefetching={isFetchingNextPage}
                    isFilteredByViewed={isFilteredByViewed}
                    handleFavoriteClick={handleFavoriteClick}
                    handleSharePermission={handleSharePermission}
                    handleEndReached={handleEndReached}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <PlanogramDeleteDialog
        open={deleteDialogOpen}
        directoryType={selectPlanogramDirectory?.type}
        handleCloseDialog={() => setDeleteDialogOpen(false)}
        handleDeleteShelfEditorItem={handleDeleteShelfEditorItem}
      />
    </>
  );
};
