import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateProductsListScrollPos,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { ProductCategory } from 'types/productCategories';
import { SearchFilterCondition } from 'types/productSearch';
import { SelectChangeEvent } from '@mui/material';
import { useProductsApi } from '@api/hooks/useProductsApi';

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

/**
 * - orgamisms/products
 * @param fetchFilteredCategories
 * @returns
 */

type Props = {
  categoryType: string;
  productCategoryName: string;
  filteredCategoriesByProductDivisionCode?: ProductCategory[];
  storeId?: number;
};
export const useProductsSearch = ({
  categoryType,
  productCategoryName,
  filteredCategoriesByProductDivisionCode,
  storeId,
}: Props) => {
  const dispatch = useAppDispatch();

  // 検索条件画面
  const [isSearch, setIsSearch] = useState(false);
  // 検索結果画面
  const [isSearchResults, setIsSearchResults] = useState(false);

  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  const [filterCondition, setFilterCondition] = useState<SearchFilterCondition>(
    {
      category: [],
      tags: [] as string[],
      isSalesEnded: true,
      isUsingProducts: true,
      name: '',
    }
  );

  const [selectedCategory, setSelectedCategory] = useState('全カテゴリ');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSalesEnded, setIsSalesEnded] = useState(true);
  const [isUsingProducts, setIsUsingProducts] = useState(true);

  const { selectedProductCompartment, isShowProductDetail } = useAppSelector(
    selectPlanogramEditorState
  );

  const handleChangeSelectedCategory = (e: SelectChangeEvent) => {
    if (!e.target.value) return;
    setSelectedCategory(e.target.value);
  };
  const handleChangeSelectedTag = (tags: string[]) => {
    setSelectedTags(tags);
  };

  const handleChangeSwitch = (key: keyof SearchFilterCondition) => {
    if (key === 'isSalesEnded') {
      setFilterCondition({
        ...filterCondition,
        isSalesEnded: !filterCondition.isSalesEnded,
      });
      setIsSalesEnded(!filterCondition.isSalesEnded);
    } else if (key === 'isUsingProducts') {
      setFilterCondition({
        ...filterCondition,
        isUsingProducts: !filterCondition.isUsingProducts,
      });
      setIsUsingProducts(!filterCondition.isUsingProducts);
    }
  };

  // ---------- Search Conditions
  const handleShowSearchCondition = () => {
    setIsSearch(true);
    setIsSearchResults(false);
    dispatch(updateSelectedProductCompartment(undefined));
  };

  const handleResetConditions = () => {
    reset();
    setSelectedCategory('全カテゴリ');
    handleChangeSelectedTag([]);
    setIsSalesEnded(true);
  };

  const handleBackToList = () => {
    reset();
    handleChangeSelectedTag([]);
    setIsSalesEnded(true);
    setIsSearch(false);
    setIsSearchResults(false);
  };

  const reset = useCallback(() => {
    setValue('searchText', '');
    if (isSearchResults) {
      setFilterCondition({
        ...filterCondition,
        name: '',
      });
    }
    dispatch(updateProductsListScrollPos(0));
    dispatch(updateSelectedProductCompartment(undefined));
  }, [dispatch, setValue, filterCondition, isSearchResults]);

  const handleDeleteTip = (
    key: keyof SearchFilterCondition,
    deleteTag: string
  ) => {
    if (key === 'tags') {
      const newTags = filterCondition.tags.filter((tag) => tag !== deleteTag);
      setFilterCondition({
        ...filterCondition,
        tags: newTags,
      });
      setSelectedTags(newTags);
    } else if (key === 'category') {
      const newCategories = Array.isArray(filterCondition.category)
        ? filterCondition.category?.filter((category) => category !== deleteTag)
        : [];
      setFilterCondition({
        ...filterCondition,
        category: newCategories,
      });
      setSelectedCategory('全カテゴリ');
    }
  };

  const handleSearch = () => {
    if (selectedCategory === 'この什器に紐づくカテゴリ') {
      const filterCategory = filteredCategoriesByProductDivisionCode?.map(
        (category) => category.name
      );
      setFilterCondition({
        category: filterCategory,
        tags: selectedTags,
        isSalesEnded: isSalesEnded,
        isUsingProducts: isUsingProducts,
        name: getValues().searchText,
      });
    } else {
      const category =
        selectedCategory === '全カテゴリ' ? [] : [selectedCategory];
      setFilterCondition({
        category,
        tags: selectedTags,
        isSalesEnded: isSalesEnded,
        isUsingProducts: isUsingProducts,
        name: getValues().searchText,
      });
    }
    setIsSearchResults(true);
  };

  const {
    data,
    searchedData,
    isLoadingNextPage,
    isLoadingSearchNextPage,
    isSearchLoading,
    isLoading,
    isFetchingNextPage,
    isSearchFetchingNextPage,
    fetchNextPage,
    fetchSearchNextPage,
  } = useProductsApi({
    categoryType,
    isSalesEnded,
    productCategoryName,
    isSearchResults,
    filterCondition,
    storeId,
  });

  return {
    isSearch,
    isSearchResults,
    isSalesEnded,
    isUsingProducts,
    filterCondition,
    selectedCategory,
    selectedTags,
    control,
    handleSubmit,
    setValue,
    setSelectedCategory,
    handleChangeSelectedCategory,
    handleChangeSelectedTag,
    handleChangeSwitch,
    handleShowSearchCondition,
    handleResetConditions,
    handleDeleteTip,
    handleSearch,
    handleBackToList,
    selectedProductCompartment,
    isShowProductDetail,
    reset,

    data,
    searchedData,
    isLoadingNextPage,
    isLoadingSearchNextPage,
    isSearchLoading,
    isLoading,
    isFetchingNextPage,
    isSearchFetchingNextPage,
    fetchNextPage,
    fetchSearchNextPage,
  };
};
