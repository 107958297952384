import {
  Box,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Switch,
  Select,
  InputLabel,
  Checkbox,
  Button,
  Divider,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { Control, Controller } from 'react-hook-form';
import { FormValues } from 'types/products';
import { getProfitTagName, productTags } from '@utils/const';
import { theme } from 'theme';
import { CategoryOption, SearchFilterCondition } from 'types/productSearch';

type Props = {
  isCompareMode: boolean;
  isSalesEnded: boolean;
  control: Control<FormValues>;
  selectedTags: string[];
  categoryOptions: CategoryOption[];
  selectedCategory: string;
  handleChangeSelectedCategory: (e: SelectChangeEvent) => void;
  handleChangeSelectedTag: (tags: string[]) => void;
  handleChangeSwitch: (key: keyof SearchFilterCondition) => void;
  filteredCategoriesReset: VoidFunction;
  handleResetConditions: VoidFunction;
  handleBackToList: VoidFunction;
};

export const ProductsSearchFields = ({
  isCompareMode,
  isSalesEnded,
  control,
  selectedTags,
  categoryOptions,
  selectedCategory,
  handleChangeSelectedCategory,
  handleChangeSelectedTag,
  handleChangeSwitch,
  filteredCategoriesReset,
  handleResetConditions,
  handleBackToList,
}: Props) => {
  return (
    <Box component="div">
      <Box component="div">
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '48px',
            gap: '16px',
            ...(isCompareMode && {
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              padding: '8px',
            }),
          }}
        >
          <IconButton
            edge="start"
            sx={{
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: 1,
              width: '32px',
              height: '32px',
              ml: 0,
            }}
            onClick={handleBackToList}
          >
            <Close fontSize="small" htmlColor={theme.palette.icons.primary} />
          </IconButton>
          <Controller
            name="searchText"
            control={control}
            render={({ field }) => (
              <TextField
                id="searchedTextId"
                key="searchedTextFullWidth"
                {...field}
                placeholder="商品名・JAN・商品CDで検索"
                size="small"
                sx={{
                  width: '100%',
                  'input::placeholder': {
                    fontSize: '14px',
                  },
                  '& .MuiInputBase-input': {
                    px: 1,
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 100px #F7F7F7 inset',
                      height: 0,
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    borderRadius: '40px',
                    backgroundColor: '#F7F7F7',
                    height: '32px',
                  },
                  startAdornment: (
                    <Search
                      sx={{
                        color: theme.palette.icons.primary,
                        mr: '4px',
                      }}
                    />
                  ),
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        filteredCategoriesReset();
                      }}
                    >
                      <GridCloseIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: theme.palette.icons.primary,
                        }}
                      />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="8px"
            sx={{
              ...(isCompareMode && {
                alignSelf: 'flex-end',
              }),
            }}
          >
            <Button
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                px: '16px',
                py: 0,
                minWidth: '90px',
                height: '40px',
              }}
              onClick={handleResetConditions}
            >
              リセット
            </Button>
            <Button variant="contained" type="submit">
              検索
            </Button>
          </Box>
        </Box>
        <Divider sx={{ mx: -1 }} />
        <Box
          component="div"
          py="16px"
          px="8px"
          display="flex"
          gap="10px"
          sx={{
            ...(isCompareMode && {
              padding: '16px 16px 0px 16px',
            }),
          }}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.textBlack.secondary}
          >
            詳細条件を指定
          </Typography>
        </Box>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          flex="1 0 0"
          alignSelf="stretch"
          sx={{
            ...(isCompareMode && {
              gap: '40px',
              padding: '8px',
            }),
            ...(!isCompareMode && {
              gap: '8px',
              py: '8px',
              px: '16px',
            }),
          }}
        >
          <Box
            component="div"
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="space-between"
            height="40px"
            sx={{
              ...(isCompareMode && {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
              }),
              ...(!isCompareMode && {
                pt: '16px',
                pb: '44px',
                gap: '8px',
              }),
            }}
          >
            <Typography
              variant="body2"
              width="120px"
              color={theme.palette.textBlack.secondary}
            >
              カテゴリ
            </Typography>
            <Select
              onChange={(e) => handleChangeSelectedCategory(e)}
              value={selectedCategory}
              sx={{
                width: '325px',
                border: `1px solid ${theme.palette.dividerBlack.dark}`,
                height: '40px',
                p: '0',
              }}
            >
              {categoryOptions.map((category) => (
                <MenuItem value={category.label} key={category.id}>
                  <Typography variant="body1">{category.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            pt="16px"
            position="relative"
            pb="44px"
            justifyContent="space-between"
            height="40px"
            sx={{
              ...(isCompareMode && {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
              }),
              ...(!isCompareMode && {
                gap: '8px',
              }),
            }}
          >
            <Typography
              variant="body2"
              width="120px"
              color={theme.palette.textBlack.secondary}
            >
              商品属性
            </Typography>
            <FormControl sx={{ flex: 1, maxWidth: 160 }}>
              <InputLabel size="small">商品属性</InputLabel>
              <Select
                size="small"
                label="商品属性"
                multiple
                value={selectedTags}
                sx={{
                  minWidth: '328px',
                  border: `1px solid ${theme.palette.dividerBlack.dark}`,
                  ...(isCompareMode && {
                    minWidth: '222px',
                  }),
                }}
                onChange={(e) =>
                  handleChangeSelectedTag(e.target.value as string[])
                }
                renderValue={() =>
                  selectedTags.map((tag) => getProfitTagName(tag)).join(',')
                }
              >
                <MenuItem
                  divider
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <Button
                    disabled={productTags.length === selectedTags.length}
                    onClick={() =>
                      handleChangeSelectedTag(
                        productTags.map((productTag) =>
                          getProfitTagName(productTag)
                        )
                      )
                    }
                  >
                    すべて選択
                  </Button>
                  <Button
                    disabled={selectedTags.length === 0}
                    onClick={() => handleChangeSelectedTag([])}
                  >
                    すべて解除
                  </Button>
                </MenuItem>

                {productTags.map((productTag, index) => {
                  const checked = selectedTags.includes(
                    getProfitTagName(productTag)
                  );
                  return (
                    <MenuItem
                      divider
                      key={index}
                      value={getProfitTagName(productTag)}
                    >
                      <Checkbox checked={checked} />
                      {getProfitTagName(productTag)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            pt="16px"
            position="relative"
            pb="44px"
            justifyContent="space-between"
            height="40px"
            gap="8px"
            sx={{
              ...(isCompareMode && {
                flexDirection: 'column',
                alignItems: 'flex-start',
              }),
            }}
          >
            <Typography
              variant="body2"
              width="120px"
              color={theme.palette.textBlack.secondary}
            >
              推奨取消の商品
            </Typography>
            <Switch
              checked={isSalesEnded}
              onChange={() => handleChangeSwitch('isSalesEnded')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
