import { CategoryIconDivider } from '@components/molecules/categoryIconDivider/categoryIconDivider';
import { RealogramCandidateLabel } from '@components/organisms/productCandidateRealogram/fragments/realogramCandidateLabel';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { ArrowRight, Edit } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useGetRealogramAnalyticsQuery } from '@reducers/shelfAppsApi';
import { formatNumberToYen, getProfitTabsValueByKey } from '@utils/const';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';
import { ProductSalesReport } from 'types/products';
import { Product, ProfitTab, ShelfDetailView } from '../../../../types/common';
import {
  ProductCandidate,
  RealogramSelectedItem,
} from '../../../../types/realogram';
import { ConfidentProductCard } from './confidentProductCard';
import { EvaluationScore } from './evaluationScore';
import { ProfitAnalysisTable } from './profitAnalysisTable';
import { StatisticCard } from './statisticCard';
import {
  fourthWeekIndex,
  getComparisonLabel,
  getSalesDataByWeek,
  getSalesDataSummary,
  maxReportIndex,
  profitComparisonTab,
  salesDataDateFrom,
  secondWeekIndex,
  thirdWeekIndex,
} from '../utils/RealogramProductCandidateOptionalItem';
import { t } from 'i18next';
import { isShowFaceUp } from '@utils/realogram';

type Props = {
  detailView: ShelfDetailView;
  product?: Product;
  selectedItem: RealogramSelectedItem;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  realogramCandidateId?: number;
  isCanNotEditProductAndCompartment?: boolean;
  isTenantSalesAnalytics?: boolean;
  mostHighScoreProduct?: Product;
  mostHighScore?: ProductCandidate;
  handleModalOpen: () => void;
  handleChangeCandidate?: (mostHighScoreProduct: ProductCandidate) => void;
  isUnrevisedUnknownProduct?: boolean;
  slideButtons?: ReactNode;
  displayAnalyticsDataIndex: number;
};

export const RealogramProductCandidateOptionalItem: FC<Props> = ({
  detailView,
  product,
  selectedItem,
  productSalesReport,
  profitTab,
  realogramCandidateId,
  isCanNotEditProductAndCompartment,
  isTenantSalesAnalytics,
  mostHighScoreProduct,
  mostHighScore,
  handleModalOpen,
  handleChangeCandidate,
  isUnrevisedUnknownProduct = false,
  slideButtons,
  displayAnalyticsDataIndex,
}) => {
  const { isLarger } = useBreakpoint();
  const { data: analyticsData } = useGetRealogramAnalyticsQuery(
    {
      timeWindow: 'weekly',
      limit: 4,
      realogramCandidateId: realogramCandidateId ?? 0,
      storeAreaType: profitComparisonTab,
    },
    {
      skip:
        !profitComparisonTab ||
        !realogramCandidateId ||
        !isTenantSalesAnalytics,
    }
  );

  const comparedProductSalesReport = analyticsData?.reports
    ?.at(0)
    ?.products.find(
      (el) =>
        el.product_id === selectedItem?.item?.primary_candidate?.product_id
    );

  const comparedGrossSalesPrice =
    comparedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(comparedProductSalesReport.gross_sales_price)
      : '-';

  const comparedGrossProfitPrice =
    comparedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(comparedProductSalesReport.gross_profit_price)
      : '-';

  const comparedSalesCount =
    comparedProductSalesReport?.sales_count?.toString() ?? '-';

  const selectedProductSalesReport = productSalesReport
    ?.at(displayAnalyticsDataIndex)
    ?.products.find(
      (el) => el.product_id === selectedItem?.item.primary_candidate?.product_id
    );

  const grossSalesPrice =
    selectedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(selectedProductSalesReport.gross_sales_price)
      : '-';

  const grossProfitPrice =
    selectedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(selectedProductSalesReport.gross_profit_price)
      : '-';

  const salesCount = selectedProductSalesReport?.sales_count?.toString() ?? '-';

  const title = `${
    // profitTab could be 0, so check by string
    profitTab?.toString()
      ? getProfitTabsValueByKey(profitTab, t('gross_profit'))
      : ''
  }(4週間)`;

  const differenceGrossSalesPrice =
    selectedProductSalesReport?.gross_sales_price != null &&
    comparedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(
          selectedProductSalesReport.gross_sales_price -
            comparedProductSalesReport.gross_sales_price
        )
      : '-';

  const differenceGrossProfitPrice =
    selectedProductSalesReport?.gross_profit_price != null &&
    comparedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(
          selectedProductSalesReport.gross_profit_price -
            comparedProductSalesReport.gross_profit_price
        )
      : '-';

  const diffSalesCount =
    selectedProductSalesReport?.sales_count != null &&
    comparedProductSalesReport?.sales_count != null
      ? (
          selectedProductSalesReport.sales_count -
          comparedProductSalesReport.sales_count
        ).toFixed(1)
      : '-';

  const profitTableHeads = [
    { name: '' },
    { name: '店舗' },
    { name: getComparisonLabel(profitComparisonTab) },
    { name: '差分' },
  ];

  const profitAnalysisTableData = [
    {
      name: '導入率',
      value: '-',
      targetValue: '-',
      diffValue: '-',
    },
    {
      name: '売上',
      value: grossSalesPrice,
      targetValue: comparedGrossSalesPrice,
      diffValue: differenceGrossSalesPrice,
    },
    {
      name: t('gross_profit'),
      value: grossProfitPrice,
      targetValue: comparedGrossProfitPrice,
      diffValue: differenceGrossProfitPrice,
    },
    {
      name: '販売数',
      value: salesCount,
      targetValue: comparedSalesCount,
      diffValue: diffSalesCount,
    },
  ];

  switch (detailView) {
    case 'default':
      return (
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {selectedItem && (
            <>
              {/* 確信度の高い商品 */}
              {isUnrevisedUnknownProduct ? (
                <ConfidentProductCard
                  selectedItem={selectedItem}
                  mostHighScoreProduct={mostHighScoreProduct}
                  mostHighScore={mostHighScore}
                  isCanNotEditProductAndCompartment={
                    isCanNotEditProductAndCompartment
                  }
                  handleModalOpen={handleModalOpen}
                  handleChangeCandidate={handleChangeCandidate}
                  slideButtons={slideButtons}
                />
              ) : (
                <>
                  {/* 訂正済ラベル */}
                  <RealogramCandidateLabel selectedItem={selectedItem} />
                  <Button
                    disabled={isCanNotEditProductAndCompartment}
                    variant="text"
                    onClick={handleModalOpen}
                    sx={{
                      marginLeft: selectedItem?.item.is_unknown ? 0 : '16px',
                    }}
                    startIcon={<Edit fontSize="small" />}
                  >
                    <Typography variant="buttonText2">訂正</Typography>
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      );
    case 'productFlag':
      return (
        <>
          <Stack direction="row" spacing={0.5} my={1}>
            {product?.detail?.tags?.map((tag) => (
              <ProductFlagLabel key={tag} name={tag} />
            ))}
          </Stack>
          <Typography
            sx={{ fontSize: 12, color: theme.palette.textBlack.secondary }}
          >
            推奨売価：-
          </Typography>
          <Typography
            sx={{ fontSize: 12, color: theme.palette.textBlack.secondary }}
          >
            商品カテゴリ：
            <CategoryIconDivider categories={product?.detail?.categories} />
          </Typography>
        </>
      );
    case 'profit':
      return (
        <>
          <Box component="div" mb={1} mt={isLarger ? 1 : 0}>
            <Box component="div" mt={1} />
            <ProfitAnalysisTable
              tableHeads={profitTableHeads}
              tableRows={profitAnalysisTableData}
            />
          </Box>
          <Box
            component="div"
            display="flex"
            borderRadius="4px"
            border={`1px solid ${theme.palette.divider}`}
            py={0.5}
          >
            <Stack
              direction="row"
              divider={
                <ArrowRight sx={{ color: theme.palette.dividerBlack.dark }} />
              }
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              px={{ xs: 1, breakpoint: 3 }}
            >
              <StatisticCard
                title={salesDataDateFrom(
                  productSalesReport?.at(
                    displayAnalyticsDataIndex + fourthWeekIndex
                  )
                )}
                value={
                  getSalesDataByWeek(
                    displayAnalyticsDataIndex + fourthWeekIndex,
                    productSalesReport,
                    profitTab,
                    selectedItem?.item.primary_candidate?.product_id
                  ) ?? '-'
                }
              />
              <StatisticCard
                title={salesDataDateFrom(
                  productSalesReport?.at(
                    displayAnalyticsDataIndex + thirdWeekIndex
                  )
                )}
                value={
                  getSalesDataByWeek(
                    displayAnalyticsDataIndex + thirdWeekIndex,
                    productSalesReport,
                    profitTab,
                    selectedItem?.item.primary_candidate?.product_id
                  ) ?? '-'
                }
              />
              <StatisticCard
                title={salesDataDateFrom(
                  productSalesReport?.at(
                    displayAnalyticsDataIndex + secondWeekIndex
                  )
                )}
                value={
                  getSalesDataByWeek(
                    displayAnalyticsDataIndex + secondWeekIndex,
                    productSalesReport,
                    profitTab,
                    selectedItem?.item.primary_candidate?.product_id
                  ) ?? '-'
                }
              />
              <StatisticCard
                title={salesDataDateFrom(
                  productSalesReport?.at(displayAnalyticsDataIndex)
                )}
                value={
                  getSalesDataByWeek(
                    displayAnalyticsDataIndex,
                    productSalesReport,
                    profitTab,
                    selectedItem?.item.primary_candidate?.product_id
                  ) ?? '-'
                }
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Box component="div" px={{ xs: 1, breakpoint: 3 }}>
              <StatisticCard
                title={title}
                value={
                  getSalesDataSummary(
                    productSalesReport?.slice(
                      displayAnalyticsDataIndex,
                      displayAnalyticsDataIndex + maxReportIndex
                    ),
                    profitTab,
                    selectedItem?.item.primary_candidate?.product_id
                  ) ?? '-'
                }
              />
            </Box>
          </Box>
        </>
      );
    case 'rate':
      return (
        <>
          {isShowFaceUp(selectedItem.item) &&
            selectedItem.item.evaluation?.face_front_score &&
            selectedItem.item.evaluation.foreground_score && (
              <EvaluationScore
                //NOTE: 型定義上selectedItem.ite.evaluationを渡すとそれ以下のtypeがoptionalとなるため個別に値を渡す
                evaluation={{
                  face_front_score:
                    selectedItem.item.evaluation.face_front_score,
                  foreground_score:
                    selectedItem.item.evaluation.foreground_score,
                }}
              />
            )}
        </>
      );
    default:
      return <></>;
  }
};
