import { ZoomIn } from '@components/molecules/zoomInOut/zoomIn';
import { ZoomOut } from '@components/molecules/zoomInOut/zoomOut';
import {
  StopPropagationButton,
  StyledButtonGroup,
} from '@components/organisms';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { FC } from 'react';

type Props = {
  isDisabled?: boolean;
  isDisableReset: boolean;
  isDisableZoomIn: boolean;
  isDisableZoomOut: boolean;
  handleIncrementZoom: VoidFunction;
  handleDecrementZoom: VoidFunction;
  handleTriggerZoom: VoidFunction;
};

export const RealogramZoomController: FC<Props> = ({
  isDisabled = false,
  isDisableReset,
  isDisableZoomIn,
  isDisableZoomOut,
  handleIncrementZoom,
  handleDecrementZoom,
  handleTriggerZoom,
}) => {
  return (
    <StyledButtonGroup
      orientation="horizontal"
      sx={{
        flexDirection: 'row-reverse',
        width: '146px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        height: '40px',
        marginLeft: '10px',
      }}
    >
      <StopPropagationButton
        onClick={handleTriggerZoom}
        disabled={isDisableReset}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomOutMapIcon
          sx={{
            opacity: 0.65,
          }}
        />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={handleIncrementZoom}
        disabled={isDisableZoomOut || isDisabled}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomIn />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={handleDecrementZoom}
        disabled={isDisableZoomIn || isDisabled}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomOut />
      </StopPropagationButton>
    </StyledButtonGroup>
  );
};
