import { faceFrontThreshold, foregroundThreshold } from '@utils/const';
import { MutableRefObject } from 'react';
import { Product, ProductTag, Rate, ShelfDetailView } from 'types/common';
import { ProductReport } from 'types/products';
import {
  BBox,
  ProductCandidateWithName,
  ProductFaceEvaluation,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import {
  hasProductTag,
  isInferredAsProduct,
  isUnknownProduct,
} from './product';
export const calculateRealogramStatistics = (
  shelfBoards: RealogramShelfBoard[],
  products?: Product[]
) => {
  if (!shelfBoards?.length) return;
  const uniqueProductIds = extractRealogramProductIds(shelfBoards);

  const {
    totalFaces,
    totalOutOfStocks,
    totalUnknowns,
    totalMainFaces,
    totalRecomendedToCancelationFaces,
    totalNewFacesFirstWeek,
    totalNewFacesSecondWeek,
    totalNotFaceUp,
    totalEvaluationFaces,
    totalFaceUp,
    totalNot3DScannedFaces,
  } = shelfBoards.reduce(
    (acc, { compartments }) => {
      const faces = compartments.flatMap(({ faces }) => faces);
      const inferredFaces = faces.filter((face) => isInferredAsProduct(face));
      const outOfStockFaces = faces.filter(
        (face) => !face.in_stock && !face.primary_candidate
      );
      const unknownFaces = faces.filter((face) => isUnknownProduct(face));

      const totalMainFaces = filterByProductTag(
        'base_product',
        products ?? [],
        inferredFaces
      );
      const totalRecomendedToCancelationFaces = filterByProductTag(
        'sales_ended',
        products ?? [],
        inferredFaces
      );
      const totalNewFacesFirstWeek = filterByProductTag(
        'new_products_first_week',
        products ?? [],
        faces
      );
      const totalNewFacesSecondWeek = filterByProductTag(
        'new_products_second_week',
        products ?? [],
        faces
      );
      const totalNot3DScannedFaces = filterByProductTag(
        'not_3D_scanned',
        products ?? [],
        inferredFaces
      );

      //TODO: castを使わない型定義にする
      const totalEvaluationFaces = faces.filter((face) =>
        isShowFaceUp(face)
      ) as Array<
        Omit<RealogramCandidateFace, 'evaluation'> & {
          evaluation: Required<ProductFaceEvaluation>;
        }
      >;
      const totalNotFaceUp = totalEvaluationFaces.filter(
        ({ evaluation }) => !isFaceUpOk(evaluation)
      );
      const totalFaceUp = totalEvaluationFaces.filter(({ evaluation }) =>
        isFaceUpOk(evaluation)
      );
      return {
        totalFaces: acc.totalFaces + inferredFaces.length + unknownFaces.length,
        totalOutOfStocks: acc.totalOutOfStocks + outOfStockFaces.length,
        totalUnknowns: acc.totalUnknowns + unknownFaces.length,
        totalMainFaces: acc.totalMainFaces + totalMainFaces.length,
        totalRecomendedToCancelationFaces:
          acc.totalRecomendedToCancelationFaces +
          totalRecomendedToCancelationFaces.length,
        totalNewFacesFirstWeek:
          acc.totalNewFacesFirstWeek + totalNewFacesFirstWeek.length,
        totalNewFacesSecondWeek:
          acc.totalNewFacesSecondWeek + totalNewFacesSecondWeek.length,
        totalNotFaceUp: acc.totalNotFaceUp + totalNotFaceUp.length,
        totalEvaluationFaces:
          acc.totalEvaluationFaces + totalEvaluationFaces.length,
        totalFaceUp: acc.totalFaceUp + totalFaceUp.length,
        totalNot3DScannedFaces:
          acc.totalNot3DScannedFaces + totalNot3DScannedFaces.length,
      };
    },
    {
      totalFaces: 0,
      totalOutOfStocks: 0,
      totalUnknowns: 0,
      totalMainFaces: 0,
      totalRecomendedToCancelationFaces: 0,
      totalNewFacesFirstWeek: 0,
      totalNewFacesSecondWeek: 0,
      totalNotFaceUp: 0,
      totalEvaluationFaces: 0,
      totalFaceUp: 0,
      totalNot3DScannedFaces: 0,
    }
  );

  const faces = shelfBoards.flatMap((shelfBoard) =>
    shelfBoard.compartments.flatMap((compartment) => compartment.faces)
  );
  //NOTE: １つでも評価対象ではない商品が含まれる場合スコアを計算しない
  const score = faces?.every((face) => {
    if (!face.in_stock) return true; //evaluation値は入っていないが欠品はスコア計算に必要なためtrueを返す
    return hasFaceUpEvaluation(face);
  })
    ? calcEvaluationScore(
        totalNotFaceUp,
        totalOutOfStocks,
        totalEvaluationFaces
      )
    : undefined;

  return {
    score,
    totalShelves: String(shelfBoards.length),
    totalFaces: String(totalFaces),
    totalUniques: String(uniqueProductIds.length),
    totalOutOfStocks: String(totalOutOfStocks),
    totalUnknowns: String(totalUnknowns),
    totalNotFaceUp: String(totalNotFaceUp),
    totalEvaluationFaces: String(totalEvaluationFaces),
    totalFaceUp: String(totalFaceUp),
    productFlag: {
      totalMainFaces: String(totalMainFaces),
      totalNewFacesFirstWeek: String(totalNewFacesFirstWeek),
      totalNewFacesSecondWeek: String(totalNewFacesSecondWeek),
      totalRecomendedToCancelationFaces: String(
        totalRecomendedToCancelationFaces
      ),
      totalNot3DScannedFaces: String(totalNot3DScannedFaces),
    },
  };
};

const filterByProductTag = (
  productTag: ProductTag,
  products: Product[],
  faces: RealogramCandidateFace[]
) => {
  return faces.filter(({ primary_candidate }) =>
    products?.some(
      ({ id, detail }) =>
        primary_candidate?.product_id === id &&
        hasProductTag(productTag, detail?.tags)
    )
  );
};

export const isSelectedItem = (
  view: ShelfDetailView,
  targetItem: RealogramSelectedItem,
  currentItem?: RealogramSelectedItem
) => {
  if (!currentItem) return false;
  if (isCompartmentMode(view)) {
    // NOTE: 不明な商品選択時に別タブに移動した際、不明な商品が所属するコンパートメントが選択状態になることを防ぐ。
    if (currentItem.item.is_unknown) return false;
    return targetItem.compartmentId === currentItem?.compartmentId;
  }

  return targetItem.item.id === currentItem.item.id;
};

//TODO: add more type when you need to filter shelfBoards by another condition
type Args =
  | {
      realogramDetailView: 'productFlag';
      productTag: ProductTag;
      products: Product[];
    }
  | { realogramDetailView: 'profit'; report: ProductReport[] }
  | { realogramDetailView: 'rate'; rate: Rate; products: Product[] };

export const filterShelfBoards = (
  shelfBoards: RealogramShelfBoard[],
  args?: Args
) => {
  if (args?.realogramDetailView === 'productFlag') {
    const { products, productTag } = args;
    const productIds =
      products
        .filter((p) => {
          switch (true) {
            case productTag === 'new_products_first_week':
              return !!p.detail?.tags?.includes('新商品(1週目)');
            case productTag === 'new_products_second_week':
              return !!p.detail?.tags?.includes('新商品(2週目)');
            case productTag === 'base_product':
              return !!p.detail?.tags?.includes('基本商品');
            case productTag === 'sales_ended':
              return !!p.detail?.tags?.includes('推奨取消');
            case productTag === 'not_3D_scanned':
              return !!p.detail?.tags?.includes('未3Dスキャン');
            default:
              return false;
          }
        })
        .map((p) => p.id) ?? [];
    return filterFacesInShelfBoards(
      shelfBoards,
      ({ primary_candidate, is_unknown }) => {
        if (!primary_candidate || is_unknown) return false;
        return productIds.includes(primary_candidate.product_id);
      }
    );
  }
  if (args?.realogramDetailView === 'profit') {
    return filterFacesInShelfBoards(
      shelfBoards,
      ({ in_stock, is_unknown, primary_candidate }) =>
        !is_unknown &&
        (in_stock || (!in_stock && !!primary_candidate)) &&
        !!args.report.find(
          (product) => product.product_id === primary_candidate?.product_id
        )
    );
  }
  if (args?.realogramDetailView === 'rate') {
    switch (args.rate) {
      case '評価OK':
        return filterFacesInShelfBoards(
          shelfBoards,
          (face) =>
            isShowFaceUp(face) &&
            !!face.evaluation?.face_front_score &&
            !!face.evaluation.foreground_score &&
            isFaceUpOk({
              face_front_score: face.evaluation.face_front_score,
              foreground_score: face.evaluation.foreground_score,
            })
        );
      case '欠品区画数':
        return filterFacesInShelfBoards(
          shelfBoards,
          (face) => !face.in_stock && !face.primary_candidate
        );
      case 'フェイスアップNG':
        return filterFacesInShelfBoards(
          shelfBoards,
          (face) =>
            isShowFaceUp(face) &&
            !!face.evaluation?.face_front_score &&
            !!face.evaluation?.foreground_score &&
            !isFaceUpOk({
              face_front_score: face.evaluation.face_front_score,
              foreground_score: face.evaluation.foreground_score,
            })
        );
      case '不明な商品フェイス数':
        return filterFacesInShelfBoards(shelfBoards, (face) =>
          isUnknownProduct(face)
        );
      default:
        return shelfBoards;
    }
  }
  return shelfBoards;
};

type FaceFilterFn = (face: RealogramCandidateFace) => boolean;

const filterFacesInShelfBoards = (
  shelfBoards: RealogramShelfBoard[],
  faceFilterFn: FaceFilterFn
) =>
  shelfBoards
    .map((shelfBoard) => ({
      ...shelfBoard,
      compartments: shelfBoard.compartments
        .map((compartment) => ({
          ...compartment,
          faces: compartment.faces.filter(faceFilterFn), // NOTE: このコード行にコメントすると、データは正しく表示されますが、各タブをクリックすると対応する製品がハイライトされません。
        }))
        .filter(({ faces }) => faces.length),
    }))
    .filter(({ compartments }) => compartments.length);

//TODO: custom hookでlocationを取得しbooleanを返す
export const isCompartmentMode = (
  realogramDetailView: ShelfDetailView,
  pathname?: string
) =>
  realogramDetailView === 'profit' ||
  realogramDetailView === 'productFlag' ||
  !!pathname?.includes('plans');

export type Refs = Map<string, null | HTMLDivElement>;
export type MutableRefs = MutableRefObject<Refs>;

export const scrollToCenterOfList = (refs: MutableRefs, id: string) => {
  refs.current.get(id)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

export const getNextRealogramItem = (
  shelfBoards: RealogramShelfBoard[],
  selectedItem: RealogramSelectedItem,
  shelfBoardIndex: number,
  index = 1
): RealogramSelectedItem => {
  if (shelfBoardIndex < 1) return selectedItem;
  const shelfboard = shelfBoards.at(shelfBoardIndex - index);
  const compartment = shelfboard?.compartments.at(0);
  if (shelfboard && compartment?.faces.at(0)) {
    return {
      item: compartment.faces[0],
      compartmentId: compartment.id,
      shelfBoardId: shelfboard.id,
    };
  }
  return getNextRealogramItem(
    shelfBoards,
    selectedItem,
    shelfBoardIndex,
    index + 1
  );
};

export const getPrevRealogramItem = (
  shelfBoards: RealogramShelfBoard[],
  selectedItem: RealogramSelectedItem,
  shelfBoardIndex: number,
  index = 1
): RealogramSelectedItem => {
  // 現在の棚が最後の棚の場合は現在の選択商品を返す
  if (shelfBoardIndex >= shelfBoards.length - 1) return selectedItem;
  const shelfboard = shelfBoards.at(shelfBoardIndex + index);
  if (shelfboard?.compartments.length) {
    const prevLastCompartment = shelfboard.compartments.at(-1);
    const prevLastFace = prevLastCompartment?.faces.at(-1);
    if (prevLastFace && prevLastCompartment) {
      return {
        item: prevLastFace,
        compartmentId: prevLastCompartment.id,
        shelfBoardId: shelfboard.id,
      };
    }
  }
  return getPrevRealogramItem(
    shelfBoards,
    selectedItem,
    shelfBoardIndex,
    index + 1
  );
};

// TODO:リファクタ shelfBoardsから選択中のfaceのインデックスを取得する
export const findSelectedItemIndexes = (
  selectedItem?: RealogramSelectedItem,
  shelfBoards?: RealogramShelfBoard[]
):
  | { shelfBoardIndex: number; compartmentIndex: number; faceIndex: number }
  | undefined => {
  if (!selectedItem || !shelfBoards) return undefined;

  const shelfBoardIndex = shelfBoards.findIndex(
    ({ id }) => id === selectedItem.shelfBoardId
  );
  if (shelfBoardIndex === -1) return undefined;

  const compartmentIndex = shelfBoards[shelfBoardIndex].compartments.findIndex(
    ({ faces }) => {
      const selectedFace = faces.find(
        (face) => face.id === selectedItem.item.id
      );
      return selectedFace !== undefined;
    }
  );
  if (compartmentIndex === -1) return undefined;

  const faceIndex = shelfBoards[shelfBoardIndex].compartments[
    compartmentIndex
  ].faces.findIndex(({ id }) => id === selectedItem.item.id);
  if (faceIndex === -1) return undefined;

  return { shelfBoardIndex, compartmentIndex, faceIndex };
};

export const calcEvaluationScore = (
  totalNotFaceUp: number,
  totalOutOfStock: number,
  totalEvaluationFaces: number
) => {
  const hundredfold = 100;
  return Math.floor(
    (1 -
      (totalNotFaceUp + totalOutOfStock) /
        (totalEvaluationFaces + totalOutOfStock)) *
      hundredfold
  );
};

/** 評価対象商品であるか */
export const hasFaceUpEvaluation = (face: RealogramCandidateFace) =>
  !!face.evaluation?.face_front_score && !!face.evaluation.foreground_score;

/** 正面評価と最前列評価のどちらか一方でも NG ならば、フェイスアップ評価としては NG とする。*/
export const isFaceUpOk = (evaluation: Required<ProductFaceEvaluation>) =>
  evaluation.face_front_score >= faceFrontThreshold &&
  evaluation.foreground_score >= foregroundThreshold;

export const isShowFaceUp = (face: RealogramCandidateFace) =>
  //不明な商品または欠品の時はリストに評価対象商品から除外する
  isInferredAsProduct(face) && hasFaceUpEvaluation(face);

export const isActuals = (pathname: string) => pathname.includes('actuals');
export const extractRealogramProductIds = (
  shelfBoards: RealogramShelfBoard[]
) => {
  const productIds = shelfBoards
    .flatMap(({ compartments }) =>
      compartments.flatMap(({ faces }) =>
        faces.flatMap((face) =>
          isInferredAsProduct(face) &&
          face.primary_candidate?.product_id !== undefined
            ? [face.primary_candidate?.product_id]
            : []
        )
      )
    )
    .filter((productId) => typeof productId === 'number');

  const uniqueProductIds = Array.from(new Set(productIds));

  return uniqueProductIds;
};

export const getArgs = (
  realogramCandidateId: number,
  selectedItem: RealogramSelectedItem,
  selectedUnknown: boolean,
  selectedCandidate?: ProductCandidateWithName
) => {
  if (selectedUnknown) {
    return {
      realogramCandidateId,
      faceId: selectedItem.item.id,
      primaryCandidateProductId: undefined,
      outOfStock: !selectedItem.item.in_stock,
      reviseSource: undefined,
    };
  }
  return {
    realogramCandidateId,
    faceId: selectedItem.item.id,
    primaryCandidateProductId: selectedCandidate?.product_id ?? 0,
    outOfStock: !selectedItem.item.in_stock,
    reviseSource: selectedCandidate?.sourceType,
  };
};

export const getNewSelectedItem = (
  shelfBoards: RealogramShelfBoard[],
  selectedItem: RealogramSelectedItem
): RealogramSelectedItem | undefined => {
  const { shelfBoardIndex, compartmentIndex, faceIndex } =
    findSelectedItemIndexes(selectedItem, shelfBoards) ?? {};
  if (
    shelfBoardIndex === undefined ||
    compartmentIndex === undefined ||
    faceIndex === undefined
  ) {
    return;
  }
  const shelfboard = shelfBoards.at(shelfBoardIndex);
  const compartment = shelfboard?.compartments.at(compartmentIndex);
  const item = compartment?.faces.at(faceIndex);
  if (!shelfboard || !compartment || !item) return;
  return {
    item,
    shelfBoardId: shelfboard.id,
    compartmentId: compartment.id,
  };
};

//realogramCandidateImageからproductを切り出す処理
export const cropProductImage = (
  imageElement: HTMLImageElement,
  bbox: BBox
) => {
  const crop = {
    unit: 'px',
    x: bbox.x,
    y: bbox.y,
    width: bbox.width,
    height: bbox.height,
  };
  const canvas = document.createElement('canvas');
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return '';
  }
  ctx.drawImage(
    imageElement,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height
  );
  return canvas.toDataURL('image/png', 1);
};
