import { SelectionModalActions } from '@components/molecules/selectionModalActions/selectionModalActions';
import { theme } from 'theme';
import { Box, Typography } from '@mui/material';
import { ModalInnerProps } from '@components/modal';
import { SelectionStoreBaysList } from './fragments/selectionStoreBaysList';
import { useGetUserQuery } from '@reducers/shelfAppsApi';
import { StoreBay } from 'types/storeBay';
import { SelectedStoreBayImage } from './fragments/selectedStoreBayImage';
import { useStoreBayModal } from './hooks/useStoreBayModal';

type SelectionStoreBayModalProps = {
  data: StoreBay[];
  storeName: string;
  handleChangeSelectedStoreBayModal: (selectedItem?: StoreBay) => void;
};
type Props = ModalInnerProps<SelectionStoreBayModalProps>;
export const SelectionStoreBayModal = ({ handleClose, data }: Props) => {
  const {
    data: storeBays,
    storeName,
    handleChangeSelectedStoreBayModal,
  } = data;
  const { data: userData } = useGetUserQuery({
    userId: 'me',
  });

  const {
    selectedItem,
    selectedItemId,
    selectedItemIndex,
    recentRealograms,
    isLoadingImage,
    image,
    secondImage,
    selectedRecentImage,
    setSelectedRecentImage,
    handleClickListItem,
    handleUpdateSelectedItem,
  } = useStoreBayModal(storeBays);

  return (
    <Box
      component="div"
      sx={{
        width: '664px',
        maxWidth: '100%',
        height: '560px',
        borderRadius: '4px',
        background: theme.palette.white.primary,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      whiteSpace="nowrap"
    >
      <Box
        component="div"
        sx={{
          background: theme.palette.backgroundBlack.active,
          height: '44px',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.textBlack.secondary,
          gap: 1,
        }}
      >
        <Typography variant="subtitle2">什器</Typography>
        <Typography variant="body2">を選択</Typography>
      </Box>
      <Box
        component="div"
        sx={{
          height: '56px',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.textBlack.secondary,
          gap: 1,
          borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
        }}
      >
        <Typography variant="headingS" color={theme.palette.textBlack.primary}>
          {storeName}
        </Typography>
      </Box>

      <Box
        component="div"
        display="flex"
        flexDirection="row"
        flex={1}
        overflow="hidden"
      >
        <Box
          component="div"
          flex={1}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <SelectionStoreBaysList
            storeBays={storeBays}
            userId={userData?.user.id}
            selectedItemId={selectedItemId}
            selectedItemIndex={selectedItemIndex}
            handleClickListItem={handleClickListItem}
          />
        </Box>
        <SelectedStoreBayImage
          recentRealograms={recentRealograms}
          isLoading={isLoadingImage}
          image={image ?? ''}
          secondImage={secondImage ?? ''}
          selectedRecentImage={selectedRecentImage}
          setSelectedRecentImage={setSelectedRecentImage}
        />
      </Box>

      <Box
        component="div"
        sx={{
          height: '64px',
          borderTop: `1px solid ${theme.palette.dividerBlack.medium}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
        }}
      >
        <SelectionModalActions
          handleClose={() => {
            handleUpdateSelectedItem();
            handleClose();
          }}
          handleClick={() => {
            handleChangeSelectedStoreBayModal(selectedItem);
            handleClose();
          }}
          disabledSelect={!selectedItem?.id}
        />
      </Box>
    </Box>
  );
};
