import { SearchTip } from '@components/molecules/searchTip/searchTip';
import { Box } from '@mui/material';

import { User } from 'types/user';
import { Condition } from 'types/realogram';
import { directoryTypes } from '../..';
type Props = {
  isShowFolderTip: boolean;
  directoryName?: string;
  condition: Condition;
  users?: User[];

  handleConditionModalOpen: VoidFunction;
  handleDeleteTip: (key: keyof Condition) => void;
};
export const SearchTipsArea = ({
  isShowFolderTip,
  directoryName,
  condition,
  users,
  handleConditionModalOpen,
  handleDeleteTip,
}: Props) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}
    >
      {isShowFolderTip && directoryName !== 'ROOT' && directoryName && (
        <SearchTip
          text={`「${
            directoryName === 'ROOT' || !directoryName ? '' : directoryName
          }」フォルダ`}
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('directoryAncestorId')}
        />
      )}
      {condition?.isStar && (
        <SearchTip
          text="スター付き"
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('isStar')}
        />
      )}
      {condition?.ownerId?.at(0) && (
        <SearchTip
          text={
            users?.find((user) => user.id === condition?.ownerId?.at(0))
              ?.fullname ?? '存在しないユーザー'
          }
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('ownerId')}
        />
      )}

      {condition?.directoryType && condition.directoryType !== 'all' && (
        <SearchTip
          text={
            directoryTypes.find((el) => el.value === condition?.directoryType)
              ?.label ?? ''
          }
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('directoryType')}
        />
      )}
    </Box>
  );
};
