import { TextPaper } from '@components/molecules';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { Product, ShelfDetailView } from '../../../../types/common';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { formatNumberToYen } from '@utils/const';

type Props = {
  detailView: ShelfDetailView;
  product?: Product;
};

export const PlanogramProductCandidateOptionalItem: FC<Props> = ({
  detailView,
  product,
}) => {
  const data = usePlanogramContext();
  const analyticsData = data?.productSalesReport?.products.find(
    (el) => el.product_id === product?.id
  );
  const grossSalesPrice = analyticsData?.gross_sales_price
    ? formatNumberToYen(analyticsData?.gross_sales_price)
    : '';
  const salesCount = analyticsData?.sales_count
    ? analyticsData?.sales_count.toString()
    : '';

  switch (detailView) {
    case 'default':
      return <></>;
    case 'productFlag':
      return (
        <Stack direction="row" spacing={0.5}>
          {product?.detail?.tags?.map((tag) => (
            <ProductFlagLabel key={tag} name={tag} />
          ))}
        </Stack>
      );
    case 'profit':
      return (
        <Stack direction="row" spacing={1} minHeight="44px">
          <TextPaper title="売上" text={grossSalesPrice} />
          <TextPaper title="販売個数" text={salesCount} />
          <TextPaper title="商品の導入率" text="" />
        </Stack>
      );
    default:
      return <></>;
  }
};
