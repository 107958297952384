/* eslint-disable eslint-comments/disable-enable-pair -- `eslint-disable` をおこなうため */
/* eslint-disable @typescript-eslint/naming-convention -- 過去の仕様にあわせるため */
import { DemoReducer } from '@reducers/demo';
import { SkipRealogramStepThreeReducer } from '@reducers/skipRealogramStepThree';
import { SkipFlatRealogramStepThreeReducer } from '@reducers/skipFlatRealogramStepThree';
import { flatPlanReducer } from '@reducers/flatPlan';
import { LoadingIndicatorReducer } from '@reducers/loadingIndicator';
import { PageTitleReducer } from '@reducers/pageTitle';
import { planReducer } from '@reducers/plan';
import { PlanogramReducer } from '@reducers/planogram';
import { planogramEditorReducer } from '@reducers/planogramEditor/reducer';
import { ProductReducer } from '@reducers/product';
import { ProductCategoryReducer } from '@reducers/productCategory';
import { RealogramCandidateReducer } from '@reducers/realogramCandidate';
import { ScannerReducer } from '@reducers/scanner';
import { shelfAppsApi } from '@reducers/shelfAppsApi';
import { ToastReducer } from '@reducers/toast';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AuthReducer } from 'reducers/auth';
import { ModalReducer } from 'reducers/modal';
import { ComparisonItemModalReducer } from '@reducers/comparisonItemModal';
import { SharePermissionReducer } from '@reducers/sharePermission';
import { selectionStoreModalReducer } from '@reducers/selectionStoreModal';
import { enableMapSet } from 'immer';
import { selectionStoreBayModalReducer } from '@reducers/selectionStoreBayModal';

enableMapSet();
export const store = configureStore({
  reducer: {
    Modal: ModalReducer,
    Auth: AuthReducer,
    Scanner: ScannerReducer,
    Planogram: PlanogramReducer,
    planogramEditor: planogramEditorReducer,
    Product: ProductReducer,
    LoadingIndicator: LoadingIndicatorReducer,
    Toast: ToastReducer,
    PageTitle: PageTitleReducer,
    plan: planReducer,
    FlatPlan: flatPlanReducer,
    Demo: DemoReducer,
    SkipRealogramStepThreeReducer: SkipRealogramStepThreeReducer,
    SkipFlatRealogramStepThreeReducer: SkipFlatRealogramStepThreeReducer,
    RealogramCandidate: RealogramCandidateReducer,
    ProductCategory: ProductCategoryReducer,
    ComparisonItemModal: ComparisonItemModalReducer,
    SharePermission: SharePermissionReducer,
    SelectionStoreModal: selectionStoreModalReducer,
    SelectionStoreBayModal: selectionStoreBayModalReducer,
    [shelfAppsApi.reducerPath]: shelfAppsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(shelfAppsApi.middleware),
});

// Export Store states
export type RootState = ReturnType<typeof store.getState>;

// Store dispatch
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const selectAppState = (state: RootState) => state;
