import { SelectedItemType } from 'types/common';
import { useModal } from './useModal';
import {
  setCurrentRealogramDirectoryId,
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
} from '@reducers/selectionStoreModal';
import { useAppDispatch, useAppSelector } from '@store/index';
import { closeModal } from '@reducers/modal';
import { AppAbility } from '@utils/abac';
import {
  setCurrentSelectedItem as setCurrentSelectedStoreBayItem,
  updateCurrentSelectedItemId as updateCurrentSelectedStoreBayItemId,
} from '@reducers/selectionStoreBayModal';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';

export const useSelectionStoreModal = ({
  ability,
  hideStoreBay,
}: {
  ability: AppAbility;
  hideStoreBay?: boolean;
}) => {
  const { showModal } = useModal('selectionStoreModal', '');
  const dispatch = useAppDispatch();
  const { currentSelectedItem } = useAppSelector(selectionStoreModal);

  const handleOpenModal = () => {
    showModal({
      handleChangeSelectedStoreModal: (selectedItem?: SelectedItemType) => {
        // 異なる店舗を選んだ場合は什器をリセットする
        if (selectedItem?.id !== currentSelectedItem?.id) {
          dispatch(setCurrentSelectedStoreBayItem(undefined));
          dispatch(updateCurrentSelectedStoreBayItemId(undefined));
        }
        dispatch(setCurrentRealogramDirectoryId(selectedItem?.parent_id));
        dispatch(updateCurrentSelectedItemId(selectedItem?.id));
        dispatch(setCurrentSelectedItem(selectedItem));
        dispatch(closeModal());
      },
      ability,
      hideStoreBay,
    });
  };

  return {
    handleOpenModal,
  };
};
