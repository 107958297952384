import { StyledListItemButton } from '@components/organisms/productCandidatesList/productCandidatesList';
import { Help } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { RealogramSelectedItem } from '../../../../types/realogram';

type Props = {
  selectedUnknown: boolean;
  handleClick: () => void;
  selectedItem?: RealogramSelectedItem;
};

export const UnknownCard: FC<Props> = ({
  selectedUnknown,
  handleClick,
  selectedItem,
}) => {
  return (
    <StyledListItemButton
      selected={selectedUnknown}
      onClick={handleClick}
      isSameCandidate={selectedItem?.item.is_unknown}
    >
      <StyledBox>
        <StyledIconWrapper>
          <Help sx={{ width: 40, height: 40, color: '#B5B5B5' }} />
        </StyledIconWrapper>

        <Typography ml={0.5}>該当なし</Typography>
      </StyledBox>
    </StyledListItemButton>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledIconWrapper = styled(Box)(() => ({
  marginLeft: 16,
  marginRight: 4,
  width: 60,
  minHeight: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledBox = styled(Box)(() => ({
  height: 64,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  width: '100%',
}));
