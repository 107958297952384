import { CircularSpinner } from '@components/molecules';
import { Box, IconButton, Tabs, TextField, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC, MutableRefObject, useEffect } from 'react';
import { ProductCategory } from 'types/productCategories';
import { SalesEndSwitch } from '@components/molecules/salesEndSwitch/salesEndSwitch';
import { theme } from 'theme';
import { useLocation } from 'react-router-dom';
import { isPlans } from '@utils/planogram';
import { Product } from 'types/common';
import { ProductsSearchEmptyResults } from '@components/organisms/productsSearchResults/fragments/productsSearchEmptyResults';
import { ProductsGrid } from '@components/organisms';
import { Control, Controller } from 'react-hook-form';
import { Close, Search } from '@mui/icons-material';
import { SearchTip } from '@components/molecules/searchTip/searchTip';
import { SearchFilterCondition } from 'types/productSearch';

type Props = {
  products?: Product[];
  reset: () => void;
  filteredCategories?: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isLoading: boolean;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isDraggable?: boolean;
  handleSelectProductClick?: (productId: Product['id']) => void; // Note: actuals配下で利用
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  total?: number;
  handleClickProduct: (product: Product) => void;
  selectedItemId?: number;
  handleChangeSwitch: (key: keyof SearchFilterCondition) => void;
  isSalesEnded?: boolean;
  isSearchFetching: boolean;
  isSalesEndSwitchBorderTop?: boolean;
  control: Control<{ searchText: string }>;
  filteredCategoriesReset: VoidFunction;
  handleResetConditions: VoidFunction;
  handleBackToList: VoidFunction;
  handleShowSearchCondition: VoidFunction;
  handleDeleteTip: (
    key: keyof SearchFilterCondition,
    deleteTag: string
  ) => void;
  filterCondition: SearchFilterCondition;
};

const layoutPadding = { pl: 2, pr: 1 };

export const ProductsSearchResults: FC<Props> = ({
  products,
  reset,
  filteredCategories,
  handleClickProductCategory,
  isLoading,
  productsRef,
  isDraggable = true,
  isCompareMode,
  handleEndReached,
  handleClickProduct,
  total,
  selectedItemId,
  handleChangeSwitch,
  isSalesEndSwitchBorderTop = false,
  control,
  filteredCategoriesReset,
  handleDeleteTip,
  handleBackToList,
  handleShowSearchCondition,
  isSearchFetching,
  filterCondition,
}) => {
  const { productsListScrollPos } = useAppSelector(selectPlanogramEditorState);
  useEffect(() => {
    if (productsListScrollPos && productsRef.current) {
      productsRef.current.scrollTo({ top: productsListScrollPos });
    }
  }, [products?.length, productsListScrollPos, productsRef]);

  const handleCategoryClick = (category: ProductCategory) => {
    reset();
    handleClickProductCategory(category);
  };
  const { pathname } = useLocation();
  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        pb: `${
          isPlans(pathname)
            ? filteredCategories && selectedItemId
              ? '145px'
              : '60px'
            : selectedItemId
            ? '145px'
            : '80px'
        }`,
        ...(isDraggable ? {} : layoutPadding),
        overflow: 'hidden',
      }}
      ref={productsRef}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '48px',
          ...(isCompareMode && {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            padding: '8px',
          }),
        }}
      >
        <Box
          component="div"
          display="flex"
          gap="8px"
          flex="1 0 0"
          alignItems="center"
        >
          <IconButton
            edge="start"
            sx={{
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: 1,
              width: '32px',
              height: '32px',
              ml: 0,
            }}
            onClick={handleBackToList}
          >
            <Close fontSize="small" htmlColor={theme.palette.icons.primary} />
          </IconButton>
          <Typography
            variant="subtitle1"
            color={theme.palette.textBlack.primary}
          >
            検索結果
          </Typography>
        </Box>
        <Controller
          name="searchText"
          control={control}
          render={({ field }) => (
            <TextField
              id="searchedTextId"
              key="searchedTextFullWidth"
              {...field}
              placeholder="商品名・JAN・商品CDで検索"
              size="small"
              onFocus={handleShowSearchCondition}
              sx={{
                width: '296px',
                'input::placeholder': {
                  fontSize: '14px',
                },
                '& .MuiInputBase-input': {
                  height: '15px',
                  px: 1,
                },
                ...(isCompareMode && {
                  width: '222px',
                }),
              }}
              InputProps={{
                sx: {
                  borderRadius: '40px',
                  backgroundColor: '#F7F7F7',
                  height: '32px',
                },
                startAdornment: (
                  <Search
                    sx={{
                      color: theme.palette.icons.primary,
                      mr: '4px',
                    }}
                  />
                ),
                endAdornment: true && (
                  <IconButton
                    onClick={() => {
                      filteredCategoriesReset();
                    }}
                  >
                    <Close
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: theme.palette.icons.primary,
                      }}
                    />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Box>
      {isCompareMode ? (
        <Box
          component="div"
          sx={{
            borderTop: isSalesEndSwitchBorderTop
              ? `1px solid ${theme.palette.dividerBlack.medium}`
              : '0px',
          }}
        >
          <SalesEndSwitch
            isSalesEnded={filterCondition.isSalesEnded}
            handleChangeSalesEnded={() => handleChangeSwitch('isSalesEnded')}
            isLoading={false}
            orientation="horizontal"
          />
          <Tabs
            variant="scrollable"
            scrollButtons={false}
            sx={{
              mx: -2,
              borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
              px: 2,
              display: 'flex',
              alignItems: 'center',
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
                gap: '8px',
              },
            }}
          >
            {filterCondition.tags.length > 0 &&
              filterCondition.tags.map((tag, index) => (
                <SearchTip
                  key={index}
                  text={tag}
                  handleDelete={() =>
                    !isLoading && handleDeleteTip('tags', tag)
                  }
                />
              ))}
            {filterCondition.category &&
              filterCondition.category[0] !== '' &&
              filterCondition.category[0] !== '全カテゴリ' && (
                <>
                  {filterCondition?.category?.map((category, index) => (
                    <SearchTip
                      key={index}
                      text={category}
                      handleDelete={() =>
                        !isLoading && handleDeleteTip('category', category)
                      }
                    />
                  ))}
                </>
              )}
          </Tabs>
        </Box>
      ) : (
        <Tabs
          variant="scrollable"
          scrollButtons={false}
          sx={{
            mx: -2,
            borderTop: isSalesEndSwitchBorderTop
              ? `1px solid ${theme.palette.dividerBlack.medium}`
              : '0px',
            borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            '& .MuiTabs-flexContainer': {
              alignItems: 'center',
              gap: '8px',
            },
          }}
        >
          <SalesEndSwitch
            isSalesEnded={filterCondition.isSalesEnded}
            handleChangeSalesEnded={() => handleChangeSwitch('isSalesEnded')}
            isLoading={false}
            orientation="horizontal"
          />
          {filterCondition.tags.length > 0 &&
            filterCondition.tags.map((tag, index) => (
              <SearchTip
                key={index}
                text={tag}
                handleDelete={() => !isLoading && handleDeleteTip('tags', tag)}
              />
            ))}
          {filterCondition.category &&
            filterCondition.category[0] !== '' &&
            filterCondition.category[0] !== '全カテゴリ' && (
              <>
                {filterCondition?.category?.map((category, index) => (
                  <SearchTip
                    key={index}
                    text={category}
                    handleDelete={() =>
                      !isLoading && handleDeleteTip('category', category)
                    }
                  />
                ))}
              </>
            )}
        </Tabs>
      )}
      {products?.length === 0 && !isLoading ? (
        <ProductsSearchEmptyResults />
      ) : (
        <>
          {isLoading ? (
            <Box component="div" p={2}>
              <CircularSpinner />
            </Box>
          ) : (
            <>
              <Box
                component="div"
                width="100%"
                ml={1}
                pt={1}
                color="#757575" // set color directly at this moment
                gap={2}
                display="flex"
              >
                <Typography>商品</Typography>
                <Typography>{total}件</Typography>
              </Box>
              <ProductsGrid
                products={products}
                handleClick={handleClickProduct}
                isLoading={isLoading}
                handleEndReached={handleEndReached}
                isDraggable={isDraggable}
                isCompareMode={isCompareMode}
                selectedItemId={selectedItemId}
                handleCategoryClick={handleCategoryClick}
                isFetchingNextPage={isSearchFetching}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
