import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { theme } from 'theme';
import { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  handleClose: VoidFunction;
  handleResetConditions: VoidFunction;
  handleSubmit: VoidFunction;
};

export const SearchDialogLayout = ({
  isOpen,
  children,
  handleClose,
  handleResetConditions,
  handleSubmit,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '24px',
      }}
    >
      <DialogTitle variant="headingS" fontWeight="700">
        絞り込み
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          gap: '16px',
          paddingTop: '16px !important',
        }}
      >
        {children}
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: '16px',
          py: '12px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box component="div">
          <Button
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            }}
            onClick={handleResetConditions}
          >
            リセット
          </Button>
        </Box>
        <Box component="div" sx={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={handleClose}
            sx={{
              color: theme.palette.textBlack.primary,
              fontWeight: 'bold',
            }}
          >
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            決定
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
