import { CircularSpinner, ProductEmptyResult } from '@components/molecules';
import { ChangeBboxButton } from '@components/molecules/changeBboxButton/changeBboxButton';
import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { ZoomController } from '@components/organisms';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { useRealogramImageRatio } from '@hooks/useRealogramImageRatio';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { updateRatio } from '@reducers/realogramCandidate';
import { useAppDispatch } from '@store/index';
import {
  formatNumberToYen,
  profitTabGrossProfit,
  profitTabSales,
} from '@utils/const';
import { FC, useEffect, useRef, useState } from 'react';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { theme } from '../../../theme';
import {
  firstShot,
  getMaxValueInReport,
  refetchImageTime,
  secondShot,
  timer,
} from './utils';
import { ImageAndBbox } from './fragments/ImageAndBbox';

type Props = {
  imageIsLoading: boolean;
  markerEnabled?: boolean;
  defaultBboxColor?: BboxColors;
  realogramCandidateId: number;
  shelfBoards?: RealogramShelfBoard[];
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  handleChangeLoading: () => void;
  createdAt: string;
  rate?: Rate;
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  isComparingBox?: boolean;
  isDisabledAction?: boolean;
  isUnknownProductsReviseMode?: boolean;
  unknownProductsInfo?: UnknownProduct[];
  isNextDisabled?: boolean;
  isPrevDisabled?: boolean;
  isRealogramLoading?: boolean;
};

export const FlatRealogramImage: FC<Props> = ({
  imageIsLoading,
  markerEnabled = false,
  defaultBboxColor,
  realogramCandidateId,
  shelfBoards,
  view,
  mode,
  handleClickBbox,
  handleChangeLoading,
  createdAt,
  rate,
  onClickNext,
  onClickPrev,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  isComparingBox = false,
  isDisabledAction = false,
  isUnknownProductsReviseMode = false,
  unknownProductsInfo,
  comparedProductReports,
  isNextDisabled = false,
  isPrevDisabled = false,
  isRealogramLoading = false,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLElement>();
  const dataImage = isComparingBox ? 'comparison-image' : 'original-image';
  const imageElement: HTMLImageElement | null = document.querySelector(
    `[data-image="${dataImage}-1"]`
  );
  const { ratio, calcRatio } = useRealogramImageRatio(
    imageIsLoading,
    imageElement,
    ref,
    mode,
    true,
    true
  );
  const {
    image: firstImage,
    error: firstImageError,
    isLoading: isFirstImageLoading,
    getImage: refetchFirstImage,
    setImage: setFistImage,
    setError: setFirstImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 1,
    },
    { skip: false, isNoCache: true }
  );
  const {
    image: secondImage,
    error: secondImageError,
    isLoading: isSecondImageLoading,
    getImage: refetchSecondImage,
    setImage: setSecondImage,
    setError: setSecondImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 2,
    },
    { skip: false, isNoCache: true }
  );
  const originalMaxThreshold = getMaxValueInReport(profitTab, productReports);
  const comparedMaxThreshold = getMaxValueInReport(
    profitTab,
    comparedProductReports
  );

  const maxThreshold =
    originalMaxThreshold > comparedMaxThreshold
      ? originalMaxThreshold
      : comparedMaxThreshold;
  const isPrice = profitTab === profitTabSales;
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const firstShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === firstShot
  );
  const secondShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === secondShot
  );

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  /** 「荒利」の場合ヒートマップを表示しない */
  const isHeatMap =
    view === 'profit' &&
    mode === 'default' &&
    profitTab !== undefined &&
    profitTab !== profitTabGrossProfit;

  /** 画像の再取得時のエラー */
  const isImageRefetchError =
    firstImageError || !refetchFirstImage || !refetchSecondImage;

  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
  } = useZoomController();

  useEffect(() => {
    if (ratio) {
      dispatch(updateRatio(ratio));
    }
  }, [ratio, dispatch]);

  useEffect(() => {
    if (!firstImage && firstImageError) {
      const intervalRefetchFirstImage = setInterval(async () => {
        const result = refetchFirstImage && (await refetchFirstImage());
        if (result) {
          setFistImage && setFistImage(URL.createObjectURL(result));
          setFirstImageError && setFirstImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchFirstImage);
      };
    }
  }, [
    firstImage,
    firstImageError,
    isFirstImageLoading,
    refetchFirstImage,
    setFirstImageError,
    setFistImage,
  ]);

  useEffect(() => {
    if (!secondImage && secondImageError) {
      const intervalRefetchSecondImage = setInterval(async () => {
        const result = refetchSecondImage && (await refetchSecondImage());
        if (result) {
          setSecondImage && setSecondImage(URL.createObjectURL(result));
          setSecondImageError && setSecondImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchSecondImage);
      };
    }
  }, [
    secondImage,
    secondImageError,
    refetchSecondImage,
    setSecondImage,
    setSecondImageError,
    isSecondImageLoading,
  ]);

  useEffect(() => {
    if (isImageRefetchError || !imageElement) return;
    if (isRealogramLoading) {
      setWidth(0);
      setHeight(0);
      return;
    }
    if (ratio === 0) calcRatio();
    // 画像が表示できていないときに再取得する
    if (imageElement.naturalWidth === 0 && imageElement.naturalHeight === 0) {
      setTimeout(() => {
        refetchFirstImage().catch((error) => console.log(error));
        refetchSecondImage().catch((error) => console.log(error));
      }, refetchImageTime);
    } else {
      setWidth(imageElement.naturalWidth * ratio);
      setHeight(imageElement.naturalHeight * ratio);
    }
  }, [
    isImageRefetchError,
    imageElement,
    refetchFirstImage,
    refetchSecondImage,
    isRealogramLoading,
    createdAt,
    ratio,
    calcRatio,
  ]);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
      flex={1}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        ref={ref}
        component="div"
        display="flex"
        alignItems="self-start"
        alignSelf="center"
        position="relative"
        width={firstImageError || secondImageError ? '100%' : 'unset'}
        flex={1}
        sx={{ overflow: 'auto' }}
      >
        {firstImageError || secondImageError ? (
          <Box component="div" width="100%" height="100%">
            <ProductEmptyResult
              title="画像が表示できません"
              message="時間を置いて再度ご確認ください。"
            />
          </Box>
        ) : (
          <>
            {(isFirstImageLoading || isSecondImageLoading || ratio === 0) && (
              <Box
                component="div"
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <CircularSpinner />
              </Box>
            )}
            <Box
              component="div"
              sx={{
                transform: `scale(${zoomScale},${zoomScale})`,
                position: 'relative',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                gap: '3px',
                justifyContent: 'center',
                height: '100%',
                transformOrigin: 'left top',
              }}
            >
              {!isFirstImageLoading && firstImage && (
                <ImageAndBbox
                  markerEnabled={markerEnabled}
                  defaultBboxColor={defaultBboxColor}
                  view={view}
                  mode={mode}
                  handleClickBbox={handleClickBbox}
                  handleChangeLoading={handleChangeLoading}
                  rate={rate}
                  profitTab={profitTab}
                  productReports={productReports}
                  comparisonSameIdsSet={comparisonSameIdsSet}
                  isUnknownProductsReviseMode={isUnknownProductsReviseMode}
                  unknownProductsInfo={unknownProductsInfo}
                  height={height}
                  width={width}
                  realogramImage={firstImage}
                  dataImage={dataImage + '-1'}
                  setWidth={setWidth}
                  setHeight={setHeight}
                  imageElement={imageElement}
                  ratio={ratio}
                  imageShotBboxes={firstShotBboxes}
                  bboxEnabled={bboxEnabled}
                  maxThreshold={maxThreshold}
                />
              )}

              {!isSecondImageLoading && secondImage && (
                <ImageAndBbox
                  markerEnabled={markerEnabled}
                  defaultBboxColor={defaultBboxColor}
                  view={view}
                  mode={mode}
                  handleClickBbox={handleClickBbox}
                  handleChangeLoading={handleChangeLoading}
                  rate={rate}
                  profitTab={profitTab}
                  productReports={productReports}
                  comparisonSameIdsSet={comparisonSameIdsSet}
                  isUnknownProductsReviseMode={isUnknownProductsReviseMode}
                  unknownProductsInfo={unknownProductsInfo}
                  height={height}
                  width={width}
                  realogramImage={secondImage}
                  dataImage={dataImage + '-2'}
                  setWidth={setWidth}
                  setHeight={setHeight}
                  imageElement={imageElement}
                  ratio={ratio}
                  imageShotBboxes={secondShotBboxes}
                  bboxEnabled={bboxEnabled}
                  maxThreshold={maxThreshold}
                />
              )}
            </Box>
          </>
        )}
      </Box>
      <Box
        component="div"
        py={1.25}
        px={1.25}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={56}
        sx={{
          backgroundColor: theme.palette.white.primary,
          position: 'relative',
        }}
      >
        <FaceAreaControllerBox
          createdAt={createdAt}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          sx={{ fontSize: '14px', color: theme.palette.textBlack.primary }}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
        />
        {firstImageError || secondImageError ? (
          <></>
        ) : (
          <>
            <ZoomController
              zoomScale={zoomScale}
              handleIncrementZoom={handleIncrementZoom}
              handleDecrementZoom={handleDecrementZoom}
              handleTriggerZoom={handleTriggerZoom}
              isDisabled={isDisabledAction}
              orientation="horizontal"
              sxGroupBtnZoom={{
                flexDirection: 'row-reverse',
                width: '146px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                height: '40px',
                marginRight: '10px',
              }}
              sxBtnIncrement={{
                height: '40px !important',
                width: '48px',
                padding: '9px',
              }}
              sxBtnDecrement={{
                height: '40px !important',
                width: '48px',
                padding: '9px',
              }}
              sxBoxText={{ height: '40px', width: '48px', padding: '9px' }}
              sxTextPercent={{ fontSize: '14px' }}
            />
            <ChangeBboxButton
              bboxEnabled={bboxEnabled}
              setBboxEnabled={setBboxEnabled}
              top={0}
              left={0}
              styleBox={{ position: 'relative', transform: 'none' }}
              styleBtn={{ width: '48px', height: '40px' }}
              isDisabledAction={isDisabledAction}
            />
          </>
        )}
      </Box>
      {isHeatMap && (
        <Box component="div" sx={{ position: 'absolute', bottom: 64, left: 8 }}>
          <HeatMap
            start={isPrice ? '¥0' : '0'}
            end={
              isPrice
                ? formatNumberToYen(maxThreshold)
                : maxThreshold.toString()
            }
          />
        </Box>
      )}
    </Box>
  );
};
