import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { GramTableFooter } from '@components/organisms/gramTableFooter/gramTableFooter';
import {
  Skeleton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { rowHeight, rowsPerPage } from '@utils/const';
import { ReactNode, useRef } from 'react';
import { ItemProps, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';

export type TableRowProps<T> = ItemProps<T>;

export type ColumnProps<T> = {
  headerName: string;
  orderBy?: T;
  headerSx?: SxProps;
  hasDivider?: boolean;
};

type Props<T> = {
  data?: T[];
  columns: ColumnProps<unknown>[];
  tableHeaderContent: ReactNode;
  isDisplayLoadingSkeleton: boolean;
  isEmpty: boolean;
  isRefetching: boolean;
  emptyTableMessage: string;
  emptyTableTitle: string;
  scrollPos?: number;
  itemContent: (index: number, item: T) => ReactNode;
  renderTableRow?: (props: TableRowProps<T>) => ReactNode;
  handleEndReached: (isAtBottom: boolean) => void;
  handleScrollerRef?: (ref: HTMLElement | Window | null) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- could be any data
export const DataTable = <T extends Record<string, any>>({
  data,
  columns,
  tableHeaderContent,
  isDisplayLoadingSkeleton,
  isEmpty,
  isRefetching,
  emptyTableMessage,
  emptyTableTitle,
  scrollPos = 0,
  handleScrollerRef,
  itemContent,
  handleEndReached,
  renderTableRow = (props: TableRowProps<T>) => (
    <TableRow sx={{ height: rowHeight }} {...props} />
  ),
}: Props<T>) => {
  const ref = useRef<TableVirtuosoHandle>(null);

  return (
    <TableVirtuoso
      scrollerRef={handleScrollerRef}
      ref={ref}
      style={{
        border: '1px solid #CCCCCC',
      }}
      data={
        data?.length
          ? data
          : Array.from({ length: rowsPerPage }, () => ({} as T))
      }
      initialScrollTop={scrollPos}
      atBottomStateChange={handleEndReached}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              borderCollapse: 'separate',
              height: isEmpty ? '100%' : '',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props) => {
          return (
            <>
              {isDisplayLoadingSkeleton ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : (
                <>{renderTableRow(props as TableRowProps<T>)}</>
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          isEmpty && !isDisplayLoadingSkeleton
            ? () =>
                EmptyTable({
                  title: emptyTableTitle,
                  message: emptyTableMessage,
                })
            : TableBody,

        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      fixedHeaderContent={() => tableHeaderContent}
      fixedFooterContent={() => {
        if (isRefetching)
          return <GramTableFooter columnsLength={columns.length} />;
        return <></>;
      }}
      itemContent={(index, item) => itemContent(index, item)}
    />
  );
};

const EmptyTable = ({ title, message }: { title: string; message: string }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={12}>
          <EmptyResult title={title} message={message} isErrorIcon />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
