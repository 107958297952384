import { FC } from 'react';
import { Product } from '../../../../types/common';
import { CollapsibleListWrapper } from './collapsibleListWrapper';
import { HistoryCandidatesList } from '../historyCandidatesList/historyCandidatesList';
import { ProductCandidateWithName } from '../../../../types/realogram';

export const HistoryCandidates: FC<{
  historyCandidates?: Product[];
  selectedCandidate?: ProductCandidateWithName;
  handleProductClick: (productCandidate: ProductCandidateWithName) => void;
}> = ({ historyCandidates, selectedCandidate, handleProductClick }) => {
  const historyCandidatesLength = historyCandidates?.length ?? 0;

  return (
    <CollapsibleListWrapper
      title="最近訂正した商品"
      hasCollapse={historyCandidatesLength > 0 ? true : false}
    >
      <HistoryCandidatesList
        historyCandidates={historyCandidates}
        selectedCandidate={selectedCandidate}
        handleClick={handleProductClick}
      />
    </CollapsibleListWrapper>
  );
};
