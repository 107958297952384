import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreBay } from 'types/storeBay';

type SelectionStoreModalState = {
  selectedItemId?: string;
  currentSelectedItemId?: string;
  currentSelectedItem?: StoreBay;
};

export const initialState: SelectionStoreModalState = {
  selectedItemId: undefined,
};

const selectionStoreBayModalSlice = createSlice({
  name: 'selectStoreBay',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedItemId: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedItemId = action.payload;
    },
    // save the old value of selectedItemId to keep the currently selected element when opening the popup
    updateCurrentSelectedItemId: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentSelectedItemId = action.payload;
    },

    setCurrentSelectedItem: (
      state: SelectionStoreModalState,
      action: PayloadAction<StoreBay | undefined>
    ) => {
      state.currentSelectedItem = action.payload;
    },
  },
});

export const {
  reset,
  updateSelectedItemId,
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
} = selectionStoreBayModalSlice.actions;
export const selectionStoreBayModalReducer =
  selectionStoreBayModalSlice.reducer;
