import { ListItemButton, ListItemButtonProps } from '@mui/material';
import styled from '@mui/styled-engine';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { Product } from 'types/common';
import {
  ProductCandidate,
  ProductCandidateWithName,
  RealogramCandidateFace,
} from 'types/realogram';
import { ProductCandidatesListItem } from '../productCandidatesListItem/productCandidatesListitem';
import { theme } from '../../../theme';

type StyledListItemButtonProps = ListItemButtonProps & {
  isSameCandidate?: boolean;
};

type Props = {
  offset: number;
  products?: Product[];
  productCandidates?: ProductCandidate[];
  primaryCandidate?: ProductCandidate;
  selectedCandidate?: ProductCandidate;
  handleClick: (productCandidate: ProductCandidateWithName) => void;
  face?: RealogramCandidateFace;
};

export const ProductCandidatesList: FC<Props> = ({
  offset,
  products,
  primaryCandidate,
  productCandidates,
  selectedCandidate,
  handleClick,
  face,
}) => {
  const { product_id: primaryCandidateId } = primaryCandidate ?? {};

  return (
    <>
      {productCandidates?.map((productCandidate, i) => {
        // 区画追加した商品は訂正後も候補がないので表示しない
        if (!productCandidate) return;
        const productCandidateId = productCandidate?.product_id;
        return (
          <StyledListItemButton
            selected={selectedCandidate?.product_id === productCandidateId}
            onClick={() =>
              handleClick({ ...productCandidate, sourceType: 'top10' })
            }
            key={productCandidateId}
            isSameCandidate={
              productCandidateId === primaryCandidateId && !face?.is_unknown
            }
          >
            <ProductCandidatesListItem
              products={products}
              productCandidate={productCandidate}
              candidateIndex={i + offset}
            />
          </StyledListItemButton>
        );
      })}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (props) => props !== 'isSameCandidate',
})<StyledListItemButtonProps>(({ isSameCandidate }) => ({
  padding: 0,
  marginBottom: 8,
  border: '1px solid',
  borderColor: isSameCandidate ? CommonColors.appBlue : '#CCCCCC',
  borderRadius: 4,
  '&.Mui-selected': {
    backgroundColor: '#E5F0FF',
    borderColor: CommonColors.appBlue,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#E5F0FF',
  },
  '&:hover': {
    backgroundColor: isSameCandidate ? theme.palette.white.primary : '#F5F5F5',
    borderColor: 'none',
  },
}));
