import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rowsPerPage } from '@utils/const';
import { StorageHelper } from '@utils/storageHelper';

type ScannerState = {
  page: number;
  limit: number;
  selectedItemIndex: number;
  selectedDirectoryId: string;
  lastVisitedRealogramListURL: string;
  isRealogramSalesEnded: boolean;
  realogramScrollPos: number;
};

const initialState: ScannerState = {
  page: 1,
  limit: rowsPerPage,
  selectedItemIndex: 0,
  selectedDirectoryId: '',
  lastVisitedRealogramListURL: '',
  isRealogramSalesEnded: true,
  realogramScrollPos: 0,
};
const storageHelper = new StorageHelper();
const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    setRealogramsPageNumber: (
      state: ScannerState,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    reset: () => initialState,
    setSelectedItemIndex: (
      state: ScannerState,
      action: PayloadAction<number>
    ) => {
      state.selectedItemIndex = action.payload;
    },
    setSelectedDirectoryId: (
      state: ScannerState,
      action: PayloadAction<string>
    ) => {
      state.selectedDirectoryId = action.payload;
    },
    setLastVisitedRealogramListURL: (
      state: ScannerState,
      action: PayloadAction<string>
    ) => {
      state.lastVisitedRealogramListURL = action.payload;
    },
    toggleRealogramSalesEndedChanged: (state: ScannerState) => {
      state.isRealogramSalesEnded = !state.isRealogramSalesEnded;
      storageHelper.set(
        'isRealogramSalesEnded',
        state.isRealogramSalesEnded.toString()
      );
    },
    getRealogramSalesEnded: (state: ScannerState) => {
      const isRealogramSalesEnded = storageHelper.get('isRealogramSalesEnded');
      if (isRealogramSalesEnded === 'true') {
        state.isRealogramSalesEnded = true;
      } else if (isRealogramSalesEnded === 'false') {
        state.isRealogramSalesEnded = false;
      }
    },
    removeIsRealogramSalesEnded: () => {
      storageHelper.remove('isRealogramSalesEnded');
    },
    setRealogramScrollPos: (
      state: ScannerState,
      action: PayloadAction<number>
    ) => {
      state.realogramScrollPos = action.payload;
    },
  },
});

export const {
  setRealogramsPageNumber,
  reset,
  setSelectedItemIndex,
  setSelectedDirectoryId,
  setLastVisitedRealogramListURL,
  toggleRealogramSalesEndedChanged,
  getRealogramSalesEnded,
  removeIsRealogramSalesEnded,
  setRealogramScrollPos,
} = scannerSlice.actions;

export const ScannerReducer = scannerSlice.reducer;
