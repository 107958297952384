import { RequiredText } from '@components/molecules/requiredText/requiredText';
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  Button,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { BayPlanCode } from 'types/bayPlan';
import {
  RealogramFormControl,
  RealogramStoreBayData,
  RealogramType,
} from 'types/realogram';
import { FlatUploadRealogramAlert } from '../alerts/flatUploadRealogramAlert';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { FlatRealogramImages } from '../realogramImage/flatRealogramImages';
import { RealogramFormData } from '@components/pages/realogramCreate/types/realogram';
import { useAppDispatch, useAppSelector } from '@store/index';
import { selectionStoreBayModal } from '@reducers/selectionStoreBayModal/selector';
import { useModal } from '@hooks/useModal';
import { StoreBay } from 'types/storeBay';
import {
  updateCurrentSelectedItemId,
  setCurrentSelectedItem,
} from '@reducers/selectionStoreBayModal';
import { closeModal } from '@reducers/modal';
import { useRealogramDirectoriesSearch } from '@api/index';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { RecentFlatRealogramImage } from './recentFlatRealogramImage';

type Props = {
  firstFile?: File;
  secondFile?: File;
  onSecondFileSelect: (file: File) => void;
  failedDialogOpen: boolean;
  failedDialogOpenForbidden: boolean;
  onSubmit: (data: RealogramFormData) => Promise<void>;
  onFirstFileSelect: (file: File) => void;
  handleCloseFailedDialog: () => void;
  handleCloseFailedDialogForbidden: () => void;
  selectedRealogramType: RealogramType;
  onSwapFile: (file: File | undefined, secondFile: File | undefined) => void;
  isFlat: boolean;
  isMd: boolean;
  memoRow: number;
  control: RealogramFormControl;
  storeBayData?: RealogramStoreBayData;
  bayPlanCodes?: BayPlanCode[];
  handleOpenModal: () => void;
  setFormValue: (type: 'storeId' | 'storeBayId', id: number) => void;
};

export const FlatRealogramForm: FC<Props> = ({
  firstFile,
  secondFile,
  onSecondFileSelect,
  onFirstFileSelect,
  onSwapFile,
  control,
  storeBayData,
  handleOpenModal,
  setFormValue,
}) => {
  const dispatch = useAppDispatch();
  const { currentSelectedItem } = useAppSelector(selectionStoreModal);
  const { currentSelectedItem: currentSelectedStoreBay } = useAppSelector(
    selectionStoreBayModal
  );
  const { showModal: handleOpenStoreBayModal } = useModal(
    'selectionStoreBayModal',
    ''
  );

  const handleChangeSelectedStoreBayModal = (selectedItem?: StoreBay) => {
    dispatch(updateCurrentSelectedItemId(selectedItem?.id.toString()));
    dispatch(setCurrentSelectedItem(selectedItem));
    dispatch(closeModal());
  };
  const { data: recentRealograms } = useRealogramDirectoriesSearch({
    enabled: !!currentSelectedStoreBay,
    limit: 1,
    directory_type: 'file',
    directory_ancestor_id: currentSelectedStoreBay?.directory_id,
    first_order: 'created_at_desc',
  });

  const realogramCandidateId =
    recentRealograms && recentRealograms.length > 0
      ? recentRealograms[0]?.realogram_candidate_id ?? 0
      : 0;
  const { image, isLoading } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'medium',
      shotIndex: 1,
    },
    {
      skip: realogramCandidateId === 0,
      isNoCache: true,
    }
  );
  const { image: secondImage } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'medium',
      shotIndex: 2,
    },
    {
      skip: realogramCandidateId === 0,
      isNoCache: true,
    }
  );
  useEffect(() => {
    setFormValue('storeBayId', 0);
    dispatch(updateCurrentSelectedItemId(undefined));
    dispatch(setCurrentSelectedItem(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- unnecessary dependency
  }, []);
  return (
    <Box
      component="div"
      justifyContent="space-between"
      mb={5}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <FlatRealogramImages
        file={firstFile}
        secondFile={secondFile}
        onSecondFileSelect={onSecondFileSelect}
        onFirstFileSelect={onFirstFileSelect}
        onSwapFile={onSwapFile}
      />
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={3}
        gap={4}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
        >
          <RequiredText />
          <Controller
            name="storeId"
            control={control}
            render={() => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '20%', gap: '10px' }}>
                  <Typography variant="body2">店舗 *</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small">
                  <Box component="div" display="flex" alignItems="center">
                    <Typography
                      sx={{ fontSize: '16px' }}
                      color={theme.palette.textBlack.primary}
                    >
                      {currentSelectedItem?.name || '選択なし'}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenModal()}
                      sx={{
                        p: '0 8px',
                        width: '102px',
                        minWidth: '102px',
                        height: '40px',
                        fontSize: '14px',
                        ml: '10px',
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      店舗を選択
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="storeBayId"
            render={() => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '20%', gap: '10px' }}>
                  <Typography variant="body2">什器 *</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small" required>
                  <Box component="div" display="flex" alignItems="center">
                    <Typography
                      sx={{ fontSize: '16px' }}
                      color={theme.palette.textBlack.primary}
                    >
                      {currentSelectedStoreBay?.name || '選択なし'}
                    </Typography>
                    <Button
                      variant="outlined"
                      disabled={!currentSelectedItem?.name}
                      onClick={() =>
                        handleOpenStoreBayModal({
                          data: storeBayData,
                          storeName: currentSelectedItem?.name,
                          handleChangeSelectedStoreBayModal: (
                            selectedItem?: StoreBay
                          ) => handleChangeSelectedStoreBayModal(selectedItem),
                        })
                      }
                      sx={{
                        p: '0 8px',
                        width: '102px',
                        minWidth: '102px',
                        height: '40px',
                        fontSize: '14px',
                        ml: '10px',
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      什器を選択
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          />
          {realogramCandidateId !== 0 && (
            <RecentFlatRealogramImage
              isLoading={!image || isLoading}
              image={image ?? ''}
              secondImage={secondImage ?? ''}
              createdAt={recentRealograms ? recentRealograms[0].created_at : ''}
            />
          )}
          {/* 仕様再検討のためコメントアウト */}
          {/* <Controller
            control={control}
            name="bayPlanId"
            render={({ field }) => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '160px', gap: '10px' }}>
                  <Typography variant="body2">ゴンドラ番号</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel sx={{ fontSize: '0.875rem' }}>
                    ゴンドラ番号
                  </InputLabel>
                  <Select label="ゴンドラ番号" {...field}>
                    {bayPlanCodes?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          />
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="top"
            gap={2}
          >
            <InputLabel sx={{ width: '160px', gap: '10px', py: '10px' }}>
              <Typography variant="body2">メモ</Typography>
            </InputLabel>
            <Controller
              control={control}
              name="memo"
              render={({ field }) => (
                <TextField
                  sx={{ flex: 1 }}
                  rows={isMd ? memoRow : 1}
                  label="メモ"
                  multiline
                  {...field}
                />
              )}
            />
          </Box> */}
        </Box>
        <FlatUploadRealogramAlert />
      </Box>
    </Box>
  );
};
