import { SearchTip } from '@components/molecules/searchTip/searchTip';
import { Box } from '@mui/material';
import { FilterCondition } from '../types';
import { User } from 'types/user';

type BayPlanCode = {
  id: number;
  name: string;
};
type Status = {
  id: number;
  label: string;
};
type Props = {
  isShowFolderTip: boolean;
  directoryName?: string;
  filterCondition: FilterCondition;
  users?: User[];
  bayPlanCodes?: BayPlanCode[];
  status?: Status[];
  handleConditionModalOpen: VoidFunction;
  handleDeleteTip: (key: keyof FilterCondition) => void;
};
export const SearchTipsArea = ({
  isShowFolderTip,
  directoryName,
  filterCondition,
  users,
  bayPlanCodes,
  status,
  handleConditionModalOpen,
  handleDeleteTip,
}: Props) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}
    >
      {isShowFolderTip && directoryName !== 'ROOT' && directoryName && (
        <SearchTip
          text={`「${
            directoryName === 'ROOT' || !directoryName ? '' : directoryName
          }」フォルダ`}
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('directoryId')}
        />
      )}
      {filterCondition?.isStar && (
        <SearchTip
          text="スター付き"
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('isStar')}
        />
      )}
      {filterCondition?.owner_id && (
        <SearchTip
          text={
            users?.find((user) => user.id === filterCondition?.owner_id)
              ?.fullname ?? '存在しないユーザー'
          }
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('owner_id')}
        />
      )}
      {filterCondition?.bay_plan_code_id && (
        <SearchTip
          text={
            bayPlanCodes?.find(
              (bay) => bay.id === filterCondition?.bay_plan_code_id
            )?.name ?? '存在しないゴンドラ'
          }
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('bay_plan_code_id')}
        />
      )}
      {!!filterCondition?.status && (
        <SearchTip
          text={
            status?.find((st) => st.id.toString() === filterCondition?.status)
              ?.label ?? '存在しないステータス'
          }
          setIsConditionModal={handleConditionModalOpen}
          handleDelete={() => handleDeleteTip('status')}
        />
      )}
    </Box>
  );
};
