import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { Box, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import {
  formatNumberToYen,
  widthList,
  profitTabSales,
  profitTabQuantity,
  profitTabGrossProfit,
} from '@utils/const';
import { FC } from 'react';
import { ProfitTab } from 'types/common';
import { PlanogramProductCompartment } from 'types/planogram';

type Props = {
  compartment: PlanogramProductCompartment;
  profitTab: ProfitTab;
  handleClick: () => void;
};

export const ListOptional: FC<Props> = ({
  compartment,
  profitTab,
  handleClick,
}) => {
  const { detailView } = useAppSelector(selectPlanogramEditorState);
  const data = usePlanogramContext();
  const analyticsData = data?.productSalesReport?.products.find(
    (el) => el.product_id === compartment.product_id
  );

  switch (detailView) {
    case 'default':
      return (
        <Typography
          width={widthList.face}
          textAlign="right"
          pr={1}
          onClick={handleClick}
        >
          {compartment.face_count}
        </Typography>
      );
    case 'productFlag':
      return <></>;
    case 'profit':
      switch (profitTab) {
        case 'sales':
        case 'quantity':
        case 'grossProfit':
          return (
            <Box component="div" display="flex" alignItems="center">
              <Typography
                sx={{ fontSize: 14 }}
                width={widthList.face}
                textAlign="right"
              >
                {compartment.face_count}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                width={widthList.profit}
                textAlign="right"
              >
                {getProfitValue(
                  profitTab,
                  analyticsData?.gross_sales_price,
                  analyticsData?.sales_count,
                  analyticsData?.avg_gross_profit_price
                )}
              </Typography>
            </Box>
          );
        default:
          return <></>;
      }
    default:
      return <></>;
  }
};

const getProfitValue = (
  profitTab: ProfitTab,
  salesPrice?: number,
  salesCount?: number,
  salesProfit?: number
) => {
  switch (profitTab) {
    case profitTabSales:
      return salesPrice?.toString() ? formatNumberToYen(salesPrice) : '-';
    case profitTabQuantity:
      return salesCount?.toString() ?? '-';
    case profitTabGrossProfit:
      return salesProfit?.toString() ?? '-';
    default:
      return '-';
  }
};
