import { useRealogramDirectoriesSearch } from '@api/index';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { updateSelectedItemId } from '@reducers/selectionStoreBayModal';
import { selectionStoreBayModal } from '@reducers/selectionStoreBayModal/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMemo, useState } from 'react';
import { StoreBay } from 'types/storeBay';

export const useStoreBayModal = (storeBays: StoreBay[]) => {
  const dispatch = useAppDispatch();
  const { currentSelectedItem, currentSelectedItemId } = useAppSelector(
    selectionStoreBayModal
  );
  const [selectedItem, setSelectedItem] = useState<StoreBay | undefined>(
    currentSelectedItem
  );

  const { data: recentRealograms } = useRealogramDirectoriesSearch({
    enabled: !!selectedItem,
    limit: 3,
    directory_type: 'file',
    directory_ancestor_id: selectedItem?.directory_id,
    first_order: 'created_at_desc',
  });
  const [selectedRecentImage, setSelectedRecentImage] = useState(0);

  const { image, isLoading } = useRealogramCandidateImage(
    {
      realogramCandidateId:
        !!recentRealograms && recentRealograms.length > 0
          ? Number(
              recentRealograms[selectedRecentImage].realogram_candidate_id
            ) ?? 0
          : 0,
      size: 'medium',
      shotIndex: 1,
    },
    {
      skip: !recentRealograms || recentRealograms.length === 0,
      isNoCache: true,
    }
  );
  const { image: secondImage } = useRealogramCandidateImage(
    {
      realogramCandidateId:
        !!recentRealograms && recentRealograms.length > 0
          ? Number(
              recentRealograms[selectedRecentImage].realogram_candidate_id
            ) ?? 0
          : 0,
      size: 'medium',
      shotIndex: 2,
    },
    {
      skip: !recentRealograms || recentRealograms.length === 0,
      isNoCache: true,
    }
  );

  const { selectedItemId } = useAppSelector(selectionStoreBayModal);
  const selectedItemIndex = useMemo(() => {
    const index = storeBays?.findIndex(
      (d) => d.id.toString() === selectedItemId
    );

    if (index !== -1) return index;
  }, [selectedItemId, storeBays]);

  const handleClickListItem = (item: StoreBay) => {
    setSelectedItem(item);
    dispatch(updateSelectedItemId(item.id.toString()));
    setSelectedRecentImage(0);
  };

  const handleUpdateSelectedItem = () => {
    updateSelectedItemId(currentSelectedItemId);
  };
  return {
    selectedItem,
    selectedItemId,
    selectedItemIndex,
    recentRealograms,
    isLoadingImage: !image || isLoading,
    image,
    secondImage,
    selectedRecentImage,
    setSelectedRecentImage,
    handleClickListItem,
    handleUpdateSelectedItem,
  };
};
