import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import LockIcon from '@mui/icons-material/Lock';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { StoreBay } from 'types/storeBay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {
  storeBays?: StoreBay[];
  userId?: number;
  selectedItemId?: string;
  selectedItemIndex?: number;
  handleClickListItem: (item: StoreBay) => void;
};

export const SelectionStoreBaysList = ({
  storeBays,
  userId,
  selectedItemId,
  selectedItemIndex,
  handleClickListItem,
}: Props) => {
  return (
    <Virtuoso
      data={storeBays}
      initialTopMostItemIndex={{
        index: selectedItemIndex || 0,
      }}
      itemContent={(_, directory) => {
        const isDisabled = false;
        const { name, id } = directory;
        const isSelected = selectedItemId === id.toString();
        const isFavorite = directory.favorite?.owner_id === userId;

        return (
          <ListItemButton
            component="div"
            sx={{
              borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: isSelected ? theme.palette.primary.selected : '',
              '&:hover': {
                backgroundColor: isSelected
                  ? theme.palette.primary.selected
                  : '',
              },
              height: '56px',
              cursor: isDisabled ? 'auto' : 'pointer',
              width: '100%',
            }}
            onClick={() => {
              if (isDisabled) return;
              handleClickListItem(directory);
            }}
          >
            <Box component="div" display="flex" gap={1} alignItems="center">
              <ShelfIcon htmlColor={theme.palette.icons.secondary} />
              <ListItemText
                primary={
                  <Fragment>
                    <Box component="div">
                      <Typography variant="body2">
                        {name}
                        {isFavorite && (
                          <StarIcon
                            htmlColor={theme.palette.confidence.middle}
                            sx={{
                              marginLeft: '4px',
                              width: '16px',
                              height: '16px',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                        )}
                      </Typography>
                    </Box>
                  </Fragment>
                }
                primaryTypographyProps={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: isDisabled
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.primary,
                }}
              />
            </Box>
            {isSelected && (
              <CheckCircleIcon
                fontSize="small"
                htmlColor={theme.palette.primary.main}
                sx={{ alignItems: 'center' }}
              />
            )}
            {isDisabled && (
              <LockIcon
                htmlColor={theme.palette.icons.secondary}
                sx={{ width: '20px', height: '20px' }}
              />
            )}
          </ListItemButton>
        );
      }}
    />
  );
};
