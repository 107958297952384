import { theme } from 'theme';
import { Box, IconButton, Typography } from '@mui/material';
import { format, Format } from '@utils/date';
import Image from 'next/image';
import { EllipseIcon } from '@components/molecules/ellipseIcon/ellipseIcon';
import { CircularSpinner } from '@components/molecules';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { RealogramDirectoryForSearch } from '@api/types/generated';

type Props = {
  recentRealograms?: RealogramDirectoryForSearch[];
  isLoading: boolean;
  image: string;
  secondImage: string;
  selectedRecentImage: number;
  setSelectedRecentImage: (index: number) => void;
};

export const SelectedStoreBayImage = ({
  recentRealograms,
  isLoading,
  image,
  secondImage,
  selectedRecentImage,
  setSelectedRecentImage,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      sx={{
        borderLeft: `1px solid ${theme.palette.dividerBlack.medium}`,
        width: '320px',
        padding: '12px',
        gap: 1,
      }}
    >
      <Typography variant="subtitle2">最近のスキャン結果</Typography>
      {recentRealograms && recentRealograms.length > 0 ? (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gap="8px"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.backgroundBlack.black,
              width: '296px',
              height: '279px',
            }}
          >
            {isLoading ? (
              <CircularSpinner />
            ) : (
              <>
                {!secondImage ? (
                  <Image
                    src={image}
                    alt=""
                    loading="lazy"
                    width={204}
                    height={279}
                  />
                ) : (
                  <>
                    <Image
                      src={image}
                      alt=""
                      loading="lazy"
                      width={148}
                      height={111}
                    />
                    <Image
                      src={secondImage}
                      alt=""
                      loading="lazy"
                      width={148}
                      height={111}
                    />
                  </>
                )}
              </>
            )}
          </Box>
          <Box
            component="div"
            maxWidth="34px"
            display="flex"
            flexDirection="row"
            gap={1}
          >
            {recentRealograms
              .map((realogramIndex, index) => (
                <EllipseIcon
                  key={realogramIndex.id}
                  opacity={index === selectedRecentImage ? '0.65' : '0.2'}
                />
              ))
              .reverse()}
          </Box>
          <Box component="div" display="flex" alignItems="center">
            <IconButton
              sx={{
                width: 32,
                height: 32,
                borderRadius: 4,
                padding: 0,
                margin: 0,
                color: theme.palette.textBlack.primary,
              }}
              disabled={selectedRecentImage >= recentRealograms.length - 1}
              onClick={() => setSelectedRecentImage(selectedRecentImage + 1)}
            >
              <KeyboardArrowLeft fontSize="small" />
            </IconButton>
            <Typography
              variant="body3"
              color={theme.palette.textBlack.secondary}
            >
              {format(
                recentRealograms[selectedRecentImage].created_at,
                Format.datetimeWithWeekday
              )}
            </Typography>
            <IconButton
              sx={{
                width: 32,
                height: 32,
                borderRadius: 4,
                padding: 0,
                margin: 0,
                color: theme.palette.textBlack.primary,
              }}
              disabled={selectedRecentImage === 0}
              onClick={() => setSelectedRecentImage(selectedRecentImage - 1)}
            >
              <KeyboardArrowRight fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            component="div"
            display="flex"
            sx={{
              width: '296px',
              height: '279px',
              borderRadius: '4px',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.backgroundBlack.active,
            }}
          >
            <Typography
              variant="body2"
              color={theme.palette.textBlack.disabled}
            >
              選択した什器の
              <br />
              前回のスキャン結果が表示されます
            </Typography>
          </Box>
          <Typography variant="body3" color={theme.palette.textBlack.secondary}>
            前回の撮影日時: -
          </Typography>
        </>
      )}
    </Box>
  );
};
