import { FC } from 'react';
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { theme } from 'theme';

export const FlatUploadRealogramAlert: FC = () => {
  return (
    <Box component="div" pt={1} pb={3} gap={1} width="50%">
      <Box
        component="div"
        pt={1}
        pb={3}
        gap={1}
        border={`1px solid ${theme.palette.dividerBlack.dark}`}
        borderRadius="4px"
        width="100%"
      >
        <Alert
          severity="warning"
          sx={{
            fontWeight: 400,
            lineHeight: '24px',
            alignItems: 'center',
            fontSize: '16px',
            px: 2,
            py: 0.5,
            backgroundColor: theme.palette.white.primary,
          }}
        >
          撮影時の注意点
        </Alert>
        <List sx={{ color: theme.palette.system.warningText }}>
          <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
            <ListItemIcon
              sx={{ minWidth: 0, color: theme.palette.system.warningText }}
            >
              ・
            </ListItemIcon>
            <ListItemText>画像は横向きになっていますか？</ListItemText>
          </ListItem>
          <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
            <ListItemIcon
              sx={{ minWidth: 0, color: theme.palette.system.warningText }}
            >
              ・
            </ListItemIcon>
            <ListItemText>
              フタ付きのゴンドラの場合はフタが開いていますか？
            </ListItemText>
          </ListItem>
          <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
            <ListItemIcon
              sx={{ minWidth: 0, color: theme.palette.system.warningText }}
            >
              ・
            </ListItemIcon>
            <ListItemText>真上から撮っていますか？</ListItemText>
          </ListItem>
          <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
            <ListItemIcon
              sx={{ minWidth: 0, color: theme.palette.system.warningText }}
            >
              ・
            </ListItemIcon>
            <ListItemText>
              ゴンドラの半分のうち、四隅が画像から見切れていませんか？
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
