import { CircularSpinner } from '@components/molecules';
import { Box, Typography } from '@mui/material';
import { Format, format } from '@utils/date';
import Image from 'next/image';
import { theme } from 'theme';

type Props = {
  isLoading: boolean;
  image: string;
  secondImage: string;
  createdAt: string;
};

export const RecentFlatRealogramImage = ({
  isLoading,
  image,
  secondImage,
  createdAt,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={2}
    >
      <Box component="div" sx={{ width: '20%', gap: '10px' }} />
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        position="relative"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.backgroundBlack.black,
          width: !secondImage ? '160px' : '320px',
          height: '160px',
        }}
      >
        {!image || isLoading ? (
          <CircularSpinner />
        ) : (
          <>
            {!secondImage ? (
              <Image
                src={image ?? ''}
                alt=""
                loading="lazy"
                width={117}
                height={160}
              />
            ) : (
              <>
                <Image
                  src={image}
                  alt=""
                  loading="lazy"
                  width={160}
                  height={120}
                />
                <Image
                  src={secondImage}
                  alt=""
                  loading="lazy"
                  width={160}
                  height={120}
                />
              </>
            )}
            <Box
              component="div"
              position="absolute"
              bottom={0}
              width="100%"
              height="19px"
              padding="4px"
              sx={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
            >
              <Typography
                variant="caption1"
                color={theme.palette.white.primary}
                noWrap
                position="absolute"
                bottom={0}
              >
                前回:
                {format(createdAt, Format.datetimeWithWeekday)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
