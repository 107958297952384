/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shelf-Apps API
 * 棚スキャナ/エディタのAPI仕様を記載します。このOpenAPIドキュメントを正式なAPI仕様とし、OpenAPIドキュメントで表現することが難しい補足情報を [こちらのGoogleDoc](https://docs.google.com/document/d/1s-XQKyiP6cCRTmE0M5E9wsmaYI4eb4BWxVoZ3jBe2-w/edit) に記載します。両方のドキュメントを合わせて参照してください。またドキュメントに矛盾がある場合は、常にこちらのOpenAPIドキュメントが正しい仕様とします。<br/><br/>
 * OpenAPI spec version: 0.0.1
 */
import { createInstance } from '../axios';
import type { BodyType } from '../axios';
export type GetProductThumbnailApiV1MediaProductsProductIdFaceFrontSizePngGetParams =
  {
    asset_version?: string | null;
  };

export type GetMediaProductMeshApiV1MediaProductsProductIdMeshQualityBinGetParams =
  {
    asset_version?: string | null;
  };

export type GetMediaProductTextureApiV1MediaProductsProductIdTextureQualityExtensionGetParams =
  {
    asset_version?: string | null;
  };

export type GetProductGltfApiV1MediaProductsProductIdQualityGltfGetParams = {
  asset_version?: string | null;
};

export type GetProductSalesForRealogramApiV1AnalyticsProductsSalesTimeseriesForRealogramGetParams =
  {
    store_area_id?: number | null;
    store_area_type?: StoreAreaType | null;
    time_window: TimeWindow;
    start_from_including?: string | null;
    limit: number;
    first_order?: ProductSalesOrder;
    realogram_candidate_id: number;
  };

export type GetProductSalesApiV1AnalyticsProductsSalesTimeseriesGetParams = {
  store_area_id?: number | null;
  store_area_type: StoreAreaType;
  time_window: TimeWindow;
  start_from_including?: string | null;
  limit: number;
  first_order?: ProductSalesOrder;
  product_ids?: number[];
};

export type GetEventsApiV1TopicsTopicIdEventsGetParams = {
  offset?: number;
  limit?: number;
};

export type GetListApiV1UsersGetParams = {
  offset?: number;
  limit?: number;
  q?: string;
  fullname?: string;
  email?: string;
  role?: Role;
  scope?: UserScope | null;
  deleted_user?: boolean | null;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  first_order?: UserOrder;
};

export type SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetFilterByRole =
  (typeof SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetFilterByRole)[keyof typeof SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetFilterByRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetFilterByRole =
  {
    editor: 'editor',
    viewer: 'viewer',
  } as const;

export type SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetDirectoryType =
  (typeof SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetDirectoryType)[keyof typeof SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetDirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetDirectoryType =
  {
    file: 'file',
    directory: 'directory',
  } as const;

export type SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams = {
  directory_ancestor_id?: string;
  first_order?: RealogramDirectorySearchOrder;
  offset?: number;
  limit?: number;
  owner_id?: number[];
  favorite_owner_id?: number[];
  status?: string[];
  name?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  directory_type?: SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetDirectoryType;
  filter_by_role?: SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetFilterByRole;
};

export type GetRealogramDirectoryApiV1RealogramDirectoriesGetFilterByRole =
  (typeof GetRealogramDirectoryApiV1RealogramDirectoriesGetFilterByRole)[keyof typeof GetRealogramDirectoryApiV1RealogramDirectoriesGetFilterByRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRealogramDirectoryApiV1RealogramDirectoriesGetFilterByRole = {
  editor: 'editor',
  viewer: 'viewer',
} as const;

export type GetRealogramDirectoryApiV1RealogramDirectoriesGetDirectoryType =
  (typeof GetRealogramDirectoryApiV1RealogramDirectoriesGetDirectoryType)[keyof typeof GetRealogramDirectoryApiV1RealogramDirectoriesGetDirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRealogramDirectoryApiV1RealogramDirectoriesGetDirectoryType = {
  file: 'file',
  directory: 'directory',
} as const;

export type GetRealogramDirectoryApiV1RealogramDirectoriesGetParams = {
  id?: string;
  first_order?: RealogramDirectoryOrder;
  owner_id?: number[];
  favorite_owner_id?: number[];
  status?: string[];
  name?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  directory_type?: GetRealogramDirectoryApiV1RealogramDirectoriesGetDirectoryType;
  filter_by_role?: GetRealogramDirectoryApiV1RealogramDirectoriesGetFilterByRole;
};

export type GetListApiV1RealogramCandidatesGetParams = {
  offset?: number;
  limit?: number;
  owner_id?: number[];
  store_id?: number[];
  store_bay_id?: number[];
  status?: string[];
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  first_order?: RealogramCandidateOrder;
};

export type GetListApiV1StoresGetParams = {
  offset?: number;
  limit?: number;
  organization_store_id?: string[];
  name?: string;
  organization_area_id_lv1?: string[];
  organization_area_id_lv2?: string[];
  organization_area_id_lv3?: string[];
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  first_order?: StoreOrder;
  filter_by_role?: FilterByRoleEnum;
};

export type GetListApiV1DirectoryRolesGetParams = {
  directory_id: string;
  directory_type: DirectoryRoleType;
};

export type GetListApiV1BayPlansGetParams = {
  type?: PlanogramFormatType | null;
  offset?: number;
  limit?: number;
};

export type GetRealogramReviseHistoriesApiV1ProductsRealogramReviseHistoriesGetParams =
  {
    realogram_candidate_id: number;
  };

export type GetBulkListApiV1ProductsBulkGetParams = {
  product_ids?: string | null;
  organization_product_ids?: string | null;
  product_ean_codes?: string | null;
  shape?: boolean;
  detail?: boolean;
};

export type GetListApiV1ProductsGetParams = {
  offset?: number;
  limit?: number;
  shape?: boolean;
  detail?: boolean;
  q?: string;
  sales_ended?: boolean;
  category?: string[];
  tags?: string[];
  ean_code?: string[];
  organization_product_id?: string[];
  name?: string;
  in_sale_store_id?: number;
  sales_start_date_start?: string;
  sales_start_date_end?: string;
  sales_end_date_start?: string;
  sales_end_date_end?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  first_order?: ProductOrder;
};

export type SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetFilterByRole =
  (typeof SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetFilterByRole)[keyof typeof SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetFilterByRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetFilterByRole =
  {
    editor: 'editor',
    viewer: 'viewer',
  } as const;

export type SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetDirectoryType =
  (typeof SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetDirectoryType)[keyof typeof SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetDirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetDirectoryType =
  {
    file: 'file',
    directory: 'directory',
  } as const;

export type SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetParams = {
  directory_ancestor_id?: string;
  first_order?: PlanogramDirectorySearchOrder;
  offset?: number;
  limit?: number;
  owner_id?: number[];
  favorite_owner_id?: number[];
  status?: string[];
  name?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  organization_status_id?: number[];
  bay_plan_code_id?: number[];
  planogram_format?: PlanogramFormatType;
  directory_type?: SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetDirectoryType;
  filter_by_role?: SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetFilterByRole;
};

export type GetPlanogramDirectoryApiV1PlanogramDirectoriesGetFilterByRole =
  (typeof GetPlanogramDirectoryApiV1PlanogramDirectoriesGetFilterByRole)[keyof typeof GetPlanogramDirectoryApiV1PlanogramDirectoriesGetFilterByRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPlanogramDirectoryApiV1PlanogramDirectoriesGetFilterByRole = {
  editor: 'editor',
  viewer: 'viewer',
} as const;

export type GetPlanogramDirectoryApiV1PlanogramDirectoriesGetDirectoryType =
  (typeof GetPlanogramDirectoryApiV1PlanogramDirectoriesGetDirectoryType)[keyof typeof GetPlanogramDirectoryApiV1PlanogramDirectoriesGetDirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPlanogramDirectoryApiV1PlanogramDirectoriesGetDirectoryType = {
  file: 'file',
  directory: 'directory',
} as const;

export type GetPlanogramDirectoryApiV1PlanogramDirectoriesGetParams = {
  id?: string;
  first_order?: PlanogramDirectoryOrder;
  offset?: number;
  limit?: number;
  owner_id?: number[];
  favorite_owner_id?: number[];
  status?: string[];
  name?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  organization_status_id?: number[];
  bay_plan_code_id?: number[];
  planogram_format?: PlanogramFormatType;
  directory_type?: GetPlanogramDirectoryApiV1PlanogramDirectoriesGetDirectoryType;
  filter_by_role?: GetPlanogramDirectoryApiV1PlanogramDirectoriesGetFilterByRole;
};

export type GetListApiV1PlanogramsGetParams = {
  offset?: number;
  limit?: number;
  owner_id?: number[];
  store_bay_id?: number[];
  store_id?: number[];
  status?: string[];
  name?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
  organization_status_id?: number[];
  bay_plan_code_id?: number[];
  first_order?: PlanogramOrder;
};

export interface ViewGroups {
  default: Default[];
  print: Default[];
}

export interface VerifyMfaDeviceRequest {
  access_token: string;
  mfa_token: string;
}

export interface Vector {
  x: number;
  y: number;
  z: number;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserScope = (typeof UserScope)[keyof typeof UserScope];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserScope = {
  organization_employee: 'organization_employee',
  organization_employee_with_deleted: 'organization_employee_with_deleted',
  all: 'all',
} as const;

export interface UserRequest {
  email: string;
  /** @minLength 1 */
  fullname: string;
  role?: Role;
}

export type UserOrder = (typeof UserOrder)[keyof typeof UserOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  fullname_asc: 'fullname_asc',
  fullname_desc: 'fullname_desc',
  email_asc: 'email_asc',
  email_desc: 'email_desc',
  deleted_at_asc: 'deleted_at_asc',
  deleted_at_desc: 'deleted_at_desc',
} as const;

export type UserInfoUpdatedAt = string | string;

export type UserInfoDeletedAt = string | string;

export type UserInfoCreatedAt = string | string;

export interface UserInfo {
  created_at: UserInfoCreatedAt;
  deleted_at?: UserInfoDeletedAt;
  email: string;
  fullname: string;
  id: number;
  role?: Role;
  updated_at: UserInfoUpdatedAt;
}

export interface UserResponse {
  /** 認証されたユーザ自身の情報 */
  user: UserInfo;
}

export interface UserPageResponse {
  pager: OffsetPager;
  /** 指定したページの棚割計画リスト */
  users: UserInfo[];
}

export interface UpdateProductFaceRequest {
  /** 商品が欠品している場合 true */
  out_of_stock?: boolean;
  /** 指定した商品フェイスの商品候補に設定する商品ID、商品が不明な場合 null を指定する */
  primary_candidate_product_id?: number;
  /** フェイス訂正の種類を指定する。 */
  revise_source?: ReviseSource;
}

export interface UIHelperDisplaySpace {
  /** exteriorのbottomからの高さ */
  elevation: number;
  width: number;
}

export interface TokenResponse {
  access_token: string;
  id_token: string;
  refresh_token: string;
}

export type TimeWindow = (typeof TimeWindow)[keyof typeof TimeWindow];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeWindow = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarter: 'quarter',
} as const;

export interface Tenant {
  name: string;
  tenant_key: string;
}

export interface TenantResponse {
  environment: Environment;
  features: Feature;
  tenant: Tenant;
}

export interface TenantPublicResponse {
  features: FeaturePublic;
  tenant: Tenant;
}

export interface StoreSection {
  id: number;
  name: string;
  sort_order: number;
}

export interface StoreSectionResponse {
  store_sections: StoreSection[];
}

export interface StoreResponse {
  /** 指定したIDの店舗データ */
  store: Store;
}

export interface StorePageResponse {
  pager: OffsetPager;
  /** 指定したページの店舗リスト */
  stores: Store[];
}

export type StoreOrder = (typeof StoreOrder)[keyof typeof StoreOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreOrder = {
  organization_code_asc: 'organization_code_asc',
  organization_code_desc: 'organization_code_desc',
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
} as const;

export type StoreBayUpdatedAt = string | string;

export type StoreBayCreatedAt = string | string;

export interface StoreBay {
  bay_plan_id?: number;
  created_at: StoreBayCreatedAt;
  directory_id: string;
  favorite?: RealogramDirectoryFavorite;
  /** データベース上のユニークなID */
  id: number;
  name: string;
  product_division: ProductDivision;
  sort_order: number;
  store_id: number;
  store_section?: StoreSection;
  updated_at: StoreBayUpdatedAt;
}

export interface StoreBayResponse {
  /** 店舗什器 */
  store_bay: StoreBay;
}

/**
 * store = 個店
area_lv3 = OFC地域
area_lv2 = DO地域
area_lv1 = ZO地域
area_lv0 = 日本全国
 */
export type StoreAreaType = (typeof StoreAreaType)[keyof typeof StoreAreaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreAreaType = {
  store: 'store',
  area_lv3: 'area_lv3',
  area_lv2: 'area_lv2',
  area_lv1: 'area_lv1',
  area_lv0: 'area_lv0',
} as const;

/**
 * 店舗を表現する
 */
export interface Store {
  /** データベース上のユニークなID */
  id: number;
  name: string;
  organization_area_id_lv1?: string;
  organization_area_id_lv2?: string;
  organization_area_id_lv3?: string;
  /** ユーザ企業の定義する店舗ID */
  organization_store_id?: string;
}

export interface StoreBayForRealogramCandidate {
  bay_plan_id?: number;
  /** Productのcategoryと対応する */
  categories: unknown[];
  /** データベース上のユニークなID */
  id: number;
  name: string;
  store: Store;
  store_id: number;
}

/**
 * initialized = デフォルト値
processing = ジョブ投入時に設定
processed = ジョブ正常終了時に設定
error = ジョブエラー時に設定
 */
export type Status = (typeof Status)[keyof typeof Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  initialized: 'initialized',
  processing: 'processing',
  processed: 'processed',
  error: 'error',
} as const;

/**
 * 分割後、商品フェイスが並ぶ方向
 */
export type SplitAxisType = (typeof SplitAxisType)[keyof typeof SplitAxisType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SplitAxisType = {
  horizontal: 'horizontal',
  vertical: 'vertical',
} as const;

export interface SplitProductFaceRequest {
  split_axis: SplitAxisType;
  /**
   * 商品フェイスの分割数
   */
  split_num: number;
}

export type SignInResponse = TokenResponse | SessionResponse;

/**
 * 撮影タイプ

single_shelve = 1枚の写真で通常の什器を撮影したことを表す
split_bucket = 複数枚の写真で平置き冷凍ケースを撮影したことを表す
 */
export type ShotType = (typeof ShotType)[keyof typeof ShotType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShotType = {
  single_shelve: 'single_shelve',
  split_bucket: 'split_bucket',
} as const;

export interface ShelvesV2ProductCompartment {
  adjust_rotation?: ProductAdjustRotation;
  count?: PlanogramProductCompartmentCount;
  /** 未調整の場合はnull */
  display_size?: ProductDisplaySize;
  face_count?: number;
  /** PTS仕様で定義された数値 */
  face_front: FaceFrontID;
  /** 未調整の場合はnull */
  margin?: ProductFaceMargin;
  /** PTS仕様で定義された数値 */
  orientation: FaceOrientationID;
  product_id: number;
}

export interface ShelvesV2Compartment {
  justify_content?: JustifyContent;
  row: ShelvesV2ProductCompartment[];
}

export interface ShelvesShelfBoardHandle {
  /** trueの場合、取り除きや、高さ変更ができない */
  fixed?: boolean;
  occupancy_steps?: number;
  padding: ShelvesBayPartHandle;
}

export type ShelvesShelfBoardBayPartType =
  (typeof ShelvesShelfBoardBayPartType)[keyof typeof ShelvesShelfBoardBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShelvesShelfBoardBayPartType = {
  shelf_board: 'shelf_board',
} as const;

export type ShelvesShelfBoardBayPartCreatedAt = string | string;

/**
 * BayPlan type=shelves 用の什器パーツ、棚板を表現する
 */
export interface ShelvesShelfBoardBayPart {
  created_at: ShelvesShelfBoardBayPartCreatedAt;
  deprecated?: boolean;
  depth: number;
  detail: ShelfBayPartDetail;
  height: number;
  id: number;
  name: string;
  type?: ShelvesShelfBoardBayPartType;
  width: number;
}

export type ShelvesHookBarBayPartType =
  (typeof ShelvesHookBarBayPartType)[keyof typeof ShelvesHookBarBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShelvesHookBarBayPartType = {
  hook_bar: 'hook_bar',
} as const;

export type ShelvesHookBarBayPartCreatedAt = string | string;

/**
 * BayPlan type=shelves 用の什器パーツ、フックバーを表現する
 */
export interface ShelvesHookBarBayPart {
  created_at: ShelvesHookBarBayPartCreatedAt;
  deprecated?: boolean;
  depth: number;
  detail: ShelfBayPartDetail;
  height: number;
  id: number;
  name: string;
  type?: ShelvesHookBarBayPartType;
  width: number;
}

export interface ShelvesFrameStep {
  elevation: number;
}

export interface ShelvesFramePadding {
  behind: number;
  bottom: number;
  front: number;
  left: number;
  right: number;
  top: number;
}

export interface ShelvesFrameDetail {
  exterior: ShelvesFrameExterior;
  shelf_steps: ShelvesFrameStep[];
  step_lane_slope?: number;
}

export interface ShelvesFrameCeiling {
  closed: boolean;
  /** 天井なしの場合ユーザ企業の規定する固定値(現在は2.0), 天井ありの場合天井の地面からの高さ */
  elevation: number;
}

export interface ShelvesFrameExterior {
  ceiling: ShelvesFrameCeiling;
  depth: number;
  height: number;
  padding?: ShelvesFramePadding;
  width: number;
}

export type ShelvesFrameBayPartType =
  (typeof ShelvesFrameBayPartType)[keyof typeof ShelvesFrameBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShelvesFrameBayPartType = {
  shelves_frame: 'shelves_frame',
} as const;

export type ShelvesFrameBayPartCreatedAt = string | string;

/**
 * BayPlan type=shelves 用の什器パーツ、什器フレームを表現する
 */
export interface ShelvesFrameBayPart {
  created_at: ShelvesFrameBayPartCreatedAt;
  deprecated?: boolean;
  depth?: number;
  detail: ShelvesFrameDetail;
  height?: number;
  id: number;
  name: string;
  type?: ShelvesFrameBayPartType;
  width?: number;
}

/**
 * Shelvesタイプ什器用のフレーム
 */
export interface ShelvesFrame {
  bay_part_id: number;
  depth?: number;
  detail: ShelvesFrameDetail;
  height?: number;
  name: string;
  type: string;
  width?: number;
}

export interface ShelvesBayPartUIHelper {
  display_space_2d: UIHelperDisplaySpace;
  /** exteriorのbottomからの高さ */
  grip_bar: number;
}

export interface ShelvesBayPartProduct {
  stackable: boolean;
}

export interface ShelvesBayPartHandle {
  bottom: number;
}

export interface ShelvesBayPartExteriorPadding {
  behind: number;
  /** product_origin=topの場合: null */
  bottom?: number;
  front: number;
  left: number;
  right: number;
  /** product_origin=bottomの場合: null, フックバーの上部にフックが突き出す場合などはマイナスの値になる */
  top?: number;
}

export interface ShelvesBayPartExterior {
  depth: number;
  height: number;
  padding: ShelvesBayPartExteriorPadding;
  product_origin: string;
  width: number;
}

export type ShelfBoardPartType =
  (typeof ShelfBoardPartType)[keyof typeof ShelfBoardPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShelfBoardPartType = {
  shelf_board: 'shelf_board',
} as const;

export interface ShelfBayPartDetail {
  exterior: ShelvesBayPartExterior;
  handle: ShelvesShelfBoardHandle;
  product?: ShelvesBayPartProduct;
  ui_helper?: ShelvesBayPartUIHelper;
}

/**
 * Shelvesタイプ什器に設置する棚板パーツ
 */
export interface ShelfBoardPart {
  bay_part_id: number;
  depth: number;
  detail: ShelfBayPartDetail;
  elevation: number;
  height: number;
  name: string;
  shelf_step_index?: number;
  type?: ShelfBoardPartType;
  width: number;
}

export interface SetInitialPasswordRequest {
  challenge_name: string;
  password: string;
  session: string;
  username: string;
}

export interface SetDefaultLimitPager {
  /**
   * 1ページに含まれるアイテムの最大件数
   * @maximum 10000
   */
  limit?: number;
  /** リストの開始インデックス（0オリジン） */
  offset?: number;
  /** DBに存在するアイテムの合計件数。リクエスト時に指定不要 */
  total?: number;
}

export interface SessionResponse {
  challenge_name: string;
  session: string;
}

export interface SecretCodeResponse {
  secret_code: string;
}

/**
 * admin = 全権限
user = 標準的な権限
vendor = 制限された権限
read_only = 閲覧権限
 */
export type Role = (typeof Role)[keyof typeof Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  admin: 'admin',
  user: 'user',
  vendor: 'vendor',
  read_only: 'read_only',
} as const;

/**
 * フェイス訂正の種類

top10 = 認識候補 TOP-10
search = 検索
history = 最近訂正した商品
 */
export type ReviseSource = (typeof ReviseSource)[keyof typeof ReviseSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviseSource = {
  top10: 'top10',
  search: 'search',
  history: 'history',
} as const;

export interface ResponseRegenerateIdToken {
  access_token: string;
  id_token: string;
}

/**
 * resnext: 2022年~2024年6月まで稼働した認識エンジン
vit:     2024年7月から稼働した認識エンジン
 */
export type RecognizerArchitecture =
  (typeof RecognizerArchitecture)[keyof typeof RecognizerArchitecture];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecognizerArchitecture = {
  resnext: 'resnext',
  vit: 'vit',
} as const;

export interface RealogramReviseHistoriesResponse {
  products: Product[];
}

export type RealogramDirectorySearchOrder =
  (typeof RealogramDirectorySearchOrder)[keyof typeof RealogramDirectorySearchOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealogramDirectorySearchOrder = {
  sort_key_asc: 'sort_key_asc',
  sort_key_desc: 'sort_key_desc',
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
  current_user_accessed_at_asc: 'current_user_accessed_at_asc',
  current_user_accessed_at_desc: 'current_user_accessed_at_desc',
} as const;

export type RealogramDirectoryOrder =
  (typeof RealogramDirectoryOrder)[keyof typeof RealogramDirectoryOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealogramDirectoryOrder = {
  sort_key_asc: 'sort_key_asc',
  sort_key_desc: 'sort_key_desc',
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
} as const;

export type RealogramDirectoryForSearchUpdatedAt = string | string;

export type RealogramDirectoryForSearchCreatedAt = string | string;

export interface RealogramDirectoryFavorite {
  id: number;
  owner_id: number;
  target_id: string;
}

export interface RealogramDirectoryForSearch {
  breadcrumb: BreadCrumbForSearch[];
  created_at: RealogramDirectoryForSearchCreatedAt;
  current_user_accessed_at?: string;
  current_user_role?: CurrentUserRole;
  favorite?: RealogramDirectoryFavorite;
  id: string;
  /** ディレクトリ名 or ファイル名 */
  name: string;
  owner?: UserInfo;
  parent_id?: string;
  realogram_candidate?: RealogramCandidate;
  realogram_candidate_id?: number;
  store_area_id?: number;
  store_bay_id?: number;
  store_id?: number;
  type: DirectoryType;
  updated_at: RealogramDirectoryForSearchUpdatedAt;
}

export type RealogramDirectoryUpdatedAt = string | string;

export type RealogramDirectoryCreatedAt = string | string;

export interface RealogramDirectory {
  created_at: RealogramDirectoryCreatedAt;
  current_user_role?: CurrentUserRole;
  favorite?: RealogramDirectoryFavorite;
  id: string;
  /** ディレクトリ名 or ファイル名 */
  name: string;
  owner?: UserInfo;
  parent_id?: string;
  realogram_candidate?: RealogramCandidate;
  realogram_candidate_id?: number;
  store_area_id?: number;
  store_bay_id?: number;
  store_id?: number;
  type: DirectoryType;
  updated_at: RealogramDirectoryUpdatedAt;
}

export interface RealogramDirectoriesResponse {
  breadcrumb: RealogramDirectory[];
  parent: RealogramDirectory;
  realogram_directories: RealogramDirectory[];
}

export interface RealogramDirectoriesPageResponse {
  pager: OffsetPager;
  /** 指定したページの棚割計画ディレクトリリスト */
  realogram_directories: RealogramDirectoryForSearch[];
}

export interface RealogramDirecotryResponse {
  /** 指定したIDの棚割実績またはディレクトリ */
  realogram_directory: RealogramDirectory;
}

export interface RealogramCandidateShelvesImage {
  /** 画像ファイルの高さ(px) */
  height: number;
  /** 画像ファイルの横幅(px) */
  width: number;
}

export interface RealogramCandidateResponse {
  /** 指定したIDの棚割実績候補データ */
  realogram_candidate: RealogramCandidateContainCurrentRole;
}

export type RealogramCandidateOrder =
  (typeof RealogramCandidateOrder)[keyof typeof RealogramCandidateOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealogramCandidateOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
} as const;

export interface RealogramCandidateFace {
  /** 商品フェイスの位置を表現する長方形 */
  bbox: ImageBBox;
  /** 商品フェイスごとの評価 */
  evaluation?: ProductFaceEvaluation;
  id: string;
  /** 商品あり: true, 欠品: false */
  in_stock: boolean;
  /** 画像内の商品が未知の場合: true, それ以外: false */
  is_unknown?: boolean;
  /** 商品が欠品: null, product_candidates の最上位の商品 */
  primary_candidate?: ProductCandidate;
  /** 商品が欠品: null, 確信度の降順, primary_candidate を含む */
  product_candidates: ProductCandidate[];
  /** ユーザが primary_candidate を訂正した場合 true */
  revised?: boolean;
}

export interface RealogramCandidateEvaluation {
  /** 棚割実勢候補に含まれない商品のリスト */
  not_included_products: ProductId[];
  /** 評価スコア(0~1.0) */
  score: number;
}

/**
 * 画像から推定した棚割実績候補を表現する
 */
export interface RealogramCandidateDetail {
  evaluation?: RealogramCandidateEvaluation;
  products_shelves: RealogramCandidateShelves;
  /** オリジナルの画像サイズ */
  products_shelves_image: RealogramCandidateShelvesImage;
  /** 認識エンジンの内部アーキテクチャの世代を表現する文字列 */
  recognizer_architecture: RecognizerArchitecture;
  /** 認識エンジンのバージョンを特定する文字列 */
  recognizer_version: string;
}

export type RealogramCandidateContainsDirectoryInfoCustomField = {
  [key: string]: unknown;
};

export type RealogramCandidateContainsDirectoryInfoCreatedAt = string | string;

export interface RealogramCandidateContainsDirectoryInfo {
  created_at: RealogramCandidateContainsDirectoryInfoCreatedAt;
  current_user_role?: CurrentUserRole;
  custom_field?: RealogramCandidateContainsDirectoryInfoCustomField;
  /** 一覧系APIでは常に null となる */
  detail?: RealogramCandidateDetail;
  directory_id?: string;
  directory_parent_id?: string;
  favorite?: RealogramDirectoryFavorite;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** 一覧系APIでは常に null となる */
  link?: LinkRealogramCandidate;
  owner: UserInfo;
  status: Status;
  store_bay: StoreBayForRealogramCandidate;
  store_bay_id: number;
}

export interface RealogramCandidatePageResponse {
  pager: OffsetPager;
  /** 指定したページの実績棚割候補リスト */
  realogram_candidates: RealogramCandidateContainsDirectoryInfo[];
}

export type RealogramCandidateContainCurrentRoleCustomField = {
  [key: string]: unknown;
};

export type RealogramCandidateContainCurrentRoleCreatedAt = string | string;

export interface RealogramCandidateContainCurrentRole {
  created_at: RealogramCandidateContainCurrentRoleCreatedAt;
  current_user_role?: CurrentUserRole;
  custom_field?: RealogramCandidateContainCurrentRoleCustomField;
  /** 一覧系APIでは常に null となる */
  detail?: RealogramCandidateDetail;
  directory_id?: string;
  directory_parent_id?: string;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** 一覧系APIでは常に null となる */
  link?: LinkRealogramCandidate;
  owner: UserInfo;
  /**
   * 撮影された棚割実績写真の枚数
   * @minimum 1
   */
  shot_count?: number;
  /** 棚割実績が分割して撮影されたか、1枚のみか、を判別する */
  shot_type?: ShotType;
  status: Status;
  store_bay: StoreBayForRealogramCandidate;
  store_bay_id: number;
}

/**
 * １つの商品区画を表現する
 */
export interface RealogramCandidateCompartment {
  /** 商品区画の位置を表現する長方形 */
  bbox: ImageBBox;
  faces: RealogramCandidateFace[];
  id: string;
}

/**
 * １つの陳列棚板と陳列される商品郡を表現する
 */
export interface RealogramCandidateCompartmentList {
  /** 左から右に並ぶ商品区画のリスト */
  compartments: RealogramCandidateCompartment[];
  id: string;
  /** 陳列棚板の位置を表現する直線 */
  shelf_board_line?: ImageLine;
  /**
   * 撮影画像のインデックス番号、値が存在しない場合は1を表示
   * @minimum 1
   */
  shot_index?: number;
}

export interface RealogramCandidateBayRegion {
  bottom_bound: ImageLine;
  left_bound: ImageLine;
  right_bound: ImageLine;
  top_bound?: ImageLine;
}

export interface RealogramCandidateShelves {
  bay_region: RealogramCandidateBayRegion;
  /** 下段から上段に並ぶ陳列棚のリスト */
  shelf_boards: RealogramCandidateCompartmentList[];
}

export type RealogramCandidateCustomField = { [key: string]: unknown };

export type RealogramCandidateCreatedAt = string | string;

/**
 * 棚割実績候補
 */
export interface RealogramCandidate {
  created_at: RealogramCandidateCreatedAt;
  custom_field?: RealogramCandidateCustomField;
  /** 一覧系APIでは常に null となる */
  detail?: RealogramCandidateDetail;
  directory_id?: string;
  directory_parent_id?: string;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** 一覧系APIでは常に null となる */
  link?: LinkRealogramCandidate;
  owner: UserInfo;
  status: Status;
  store_bay: StoreBayForRealogramCandidate;
  store_bay_id: number;
}

export interface PutStoreBayRequest {
  bay_plan_id?: number;
  /** @minLength 1 */
  name?: string;
  product_division_code?: string;
  /** */
  sort_order?: number;
  store_section_master_id?: number;
}

export interface PutPlanogramDirectory {
  /**
   * ディレクトリ名 or ファイル名
   * @minLength 1
   */
  name?: string;
  parent_id?: string;
  scope?: PlanogramDirectoryScope;
}

export type PutPlanogramPlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export type PutPlanogramCustomField = { [key: string]: unknown };

export interface PutPlanogram {
  bay_plan_code_id?: number;
  bay_plan_id: number;
  custom_field?: PutPlanogramCustomField;
  /** @minLength 1 */
  name: string;
  organization_status_id?: number;
  plan?: PutPlanogramPlan;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
  /** 店舗と紐づく場合にのみ存在する */
  store_id?: number;
}

export interface PutDirectoryRoleRequest {
  role: DirectoryRoleName;
}

export type PtsShelfBoardType =
  (typeof PtsShelfBoardType)[keyof typeof PtsShelfBoardType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PtsShelfBoardType = {
  board: 'board',
} as const;

export interface PtsShelfBoard {
  depth: number;
  elevation: number;
  height: number;
  type?: PtsShelfBoardType;
  width: number;
}

export interface ProductStatus {
  available_in_editor: boolean;
  available_in_scanner: boolean;
  is_broken: boolean;
  is_deprecated?: boolean;
  use_scanned_model: boolean;
}

/**
 * 商品の物理的なサイズ
単位: メートル
 */
export interface ProductSizeBase {
  depth: number;
  height: number;
  width: number;
}

export interface ProductSize {
  actual: ProductSizeBase;
  display_size?: ProductSizeBase;
  images: CubeMapImageSize;
}

export interface ProductShape {
  size: ProductSize;
  stackable: boolean;
  type?: string;
}

export interface ProductSalesReportSummary {
  /** 集計期間の開始日と終了日 */
  aggregation_period: AggregationPeriod;
  /** 合計荒利金額 */
  total_gross_profit_price?: number;
  /** 合計売上金額 */
  total_gross_sales_price?: number;
  /** 合計商品販売数 */
  total_sales_count?: number;
}

export interface ProductSalesReport {
  /** 商品毎の売上金額などのデータ */
  products: ProductSales[];
  /** 商品の売上金額などを合計したサマリー情報 */
  summary: ProductSalesReportSummary;
}

export type ProductSalesOrder =
  (typeof ProductSalesOrder)[keyof typeof ProductSalesOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductSalesOrder = {
  period_start_desc: 'period_start_desc',
} as const;

export interface ProductSalesEstimateSummary {
  /** 集計期間の開始日と終了日 */
  aggregation_period: AggregationPeriod;
  /** 合計平均荒利金額 */
  total_avg_gross_profit_price?: number;
  /** 合計平均売上金額 */
  total_avg_gross_sales_price?: number;
  /** 合計平均商品販売数 */
  total_avg_sales_count?: number;
  /** 合計荒利金額 */
  total_gross_profit_price?: number;
  /** 合計売上金額 */
  total_gross_sales_price?: number;
  /** 合計商品販売数 */
  total_sales_count?: number;
}

export interface ProductSalesEstimateResponse {
  estimate: Estimate;
}

export interface ProductSalesEstimate {
  /** 1店舗あたりの平均荒利金額 */
  avg_gross_profit_price?: number;
  /** 1店舗あたりの平均売上金額 */
  avg_gross_sales_price?: number;
  /** 1店舗あたりの平均販売数 */
  avg_sales_count?: number;
  /** 合計荒利金額 */
  gross_profit_price?: number;
  /** 合計売上金額 */
  gross_sales_price?: number;
  /** 商品ID */
  product_id: number;
  /** 合計販売数 */
  sales_count?: number;
}

export interface ProductSales {
  /** 平均日次荒利金額 */
  avg_daily_gross_profit?: number;
  /** 平均日次売上金額 */
  avg_daily_gross_sales_price?: number;
  /** 平均荒利率 */
  avg_gross_profit_rate?: number;
  /** 合計荒利金額 */
  gross_profit_price?: number;
  /** 合計売上金額 */
  gross_sales_price?: number;
  /** 商品ID */
  product_id: number;
  /** 合計販売数 */
  sales_count?: number;
}

export interface ProductResponse {
  /** 指定したIDの商品情報データ */
  product: Product;
}

export type ProductPageResponsePager = CategoryExistOffsetPager | OffsetPager;

export interface ProductPageResponse {
  pager: ProductPageResponsePager;
  /** 指定したページの商品リスト */
  products: Product[];
}

export type ProductOrder = (typeof ProductOrder)[keyof typeof ProductOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
  sales_start_date_asc: 'sales_start_date_asc',
  sales_start_date_desc: 'sales_start_date_desc',
  sales_end_date_asc: 'sales_end_date_asc',
  sales_end_date_desc: 'sales_end_date_desc',
  ean_code_asc: 'ean_code_asc',
  ean_code_desc: 'ean_code_desc',
  sales_date_order: 'sales_date_order',
} as const;

export interface ProductManufacturer {
  id: number;
  name: string;
}

export interface ProductId {
  product_id: number;
}

export interface ProductFaceMargin {
  behind: number;
  front: number;
  left: number;
  right: number;
}

/**
 * 1つの商品フェイスに対する評価。
 */
export interface ProductFaceEvaluation {
  face_front_score?: number;
  foreground_score?: number;
}

export interface ProductFaceDirection {
  depth: number;
  face_direction?: FaceFrontType;
  height: number;
  width: number;
}

export interface ProductDivision {
  name: string;
  product_division_code: string;
}

export interface ProductDivisionResponse {
  product_divisions: ProductDivision[];
}

export interface ProductDisplaySize {
  depth: number;
  height: number;
  width: number;
}

export type ProductDetailSalesStartDate = string | string;

export type ProductDetailSalesEndDate = string | string;

export interface ProductDetail {
  categories: string[];
  dummy_product?: boolean;
  ean_code: string;
  face_direction: FaceFrontType;
  organization_product_id?: string;
  product_manufacturer?: ProductManufacturer;
  sales_end_date?: ProductDetailSalesEndDate;
  sales_start_date?: ProductDetailSalesStartDate;
  tags?: string[];
}

export interface ProductCategory {
  example_product_id?: string;
  example_product_image_url?: string;
  name: string;
  organization_category_code?: string;
  product_count: number;
  /** 棚割認識エンジンなどが利用する */
  product_division_code?: string;
  sub_categories: ProductCategory[];
}

export interface ProductCategoryResponse {
  product_category: ProductCategory;
}

/**
 * 1つの商品フェイスに対する、商品IDの候補。認識器は 1.0〜0.0 までの確信度を出力する。
 */
export interface ProductCandidate {
  /** 確信度を高・中・低の３段階で表現する */
  confidence_level: ConfidenceLevel;
  product_id: number;
  score: number;
}

export interface ProductBulkResponse {
  /** 指定したIDの商品リスト */
  products: Product[];
}

export interface ProductAdjuster {
  depth: number;
  face_direction?: FaceFrontType;
  height: number;
  id: number;
  product_id: number;
  width: number;
}

export interface ProductAdjusterResponse {
  product_adjuster?: ProductAdjuster;
}

export interface ProductAdjustRotation {
  /**
   * @minimum -45
   * @maximum 45
   */
  x: number;
  /**
   * @minimum -45
   * @maximum 45
   */
  y: number;
  /**
   * @minimum -45
   * @maximum 45
   */
  z: number;
}

export type ProductUpdatedAt = string | string;

export type ProductCreatedAt = string | string;

export interface Product {
  asset_version?: string;
  created_at?: ProductCreatedAt;
  detail?: ProductDetail;
  id: number;
  name: string;
  shape?: ProductShape;
  status?: ProductStatus;
  updated_at?: ProductUpdatedAt;
}

export type PreviewPlanogramPlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export interface PreviewPlanogram {
  bay_plan_id: number;
  id?: number;
  name?: string;
  plan?: PreviewPlanogramPlan;
  /** Media API */
  products_shelves_url: string;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
}

export interface PreviewPlanogramResponse {
  preview_planogram: PreviewPlanogram;
}

export interface PostStoreBayRequest {
  bay_plan_id?: number;
  /** @minLength 1 */
  name: string;
  product_division_code: string;
  /** */
  sort_order: number;
  store_id: number;
  store_section_master_id?: number;
}

export interface PostSignInRequest {
  auth_flow: AuthFlow;
  password: string;
  username: string;
}

export interface PostRefreshToken {
  refresh_token: string;
}

export interface PostRealToPlanRequest {
  bay_plan_id: number;
  directory_parent_id?: string;
  /** @minLength 1 */
  name: string;
  realogram_candidate_id: number;
  /**
   * 店舗と紐づく場合にのみ存在する
   * @minimum 0
   */
  store_id?: number;
}

export type PostPreviewPlanogramPlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export type PostPreviewPlanogramCustomField = { [key: string]: unknown };

export interface PostPreviewPlanogram {
  bay_plan_id?: number;
  custom_field?: PostPreviewPlanogramCustomField;
  /** @minLength 1 */
  name: string;
  plan?: PostPreviewPlanogramPlan;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
}

export type PostPlanogramDirectoryType =
  (typeof PostPlanogramDirectoryType)[keyof typeof PostPlanogramDirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostPlanogramDirectoryType = {
  file: 'file',
  directory: 'directory',
} as const;

export interface PostPlanogramDirectory {
  /**
   * ディレクトリ名 or ファイル名
   * @minLength 1
   */
  name: string;
  parent_id?: string;
  /** */
  planogram_id?: number;
  scope?: PlanogramDirectoryScope;
  type: PostPlanogramDirectoryType;
}

export type PostPlanogramPlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export type PostPlanogramCustomField = { [key: string]: unknown };

export interface PostPlanogram {
  bay_plan_code_id?: number;
  bay_plan_id: number;
  custom_field?: PostPlanogramCustomField;
  directory_parent_id?: string;
  /** @minLength 1 */
  name: string;
  organization_status_id?: number;
  plan?: PostPlanogramPlan;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
  /** 店舗と紐づく場合にのみ存在する */
  store_id?: number;
}

export interface PostMfaAuth {
  challenge_name: string;
  mfa_token: string;
  session: string;
  username: string;
}

export interface PostImageResponse {
  /** 作成された棚割実績候補データ */
  realogram_candidate: RealogramCandidate;
  /** アップロード用URLリスト */
  upload_urls: string[];
}

export type PostImageRequestCustomField = { [key: string]: unknown };

export interface PostImageRequest {
  custom_field?: PostImageRequestCustomField;
  /** */
  shot_count?: number;
  shot_type?: ShotType;
  store_bay_id: number;
}

export interface PostForgotPasswordRequest {
  username: string;
}

export interface PostDirectoryRoleResponse {
  /** 作成したディレクトリロール */
  directory_role: DirectoryRole;
}

export interface PostDirectoryRoleRequest {
  directory_id: string;
  directory_type: DirectoryRoleType;
  role: DirectoryRoleName;
  user_id: number;
}

export interface PostConfirmForgotPasswordRequest {
  confirmation_code: string;
  password: string;
  username: string;
}

export interface PostAccessTokenRequest {
  access_token: string;
}

export type PlanogramShelvesV2DetailShelvesItem = ShelfBoardPart | HookBarPart;

export type PlanogramShelvesV2DetailFormat =
  (typeof PlanogramShelvesV2DetailFormat)[keyof typeof PlanogramShelvesV2DetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramShelvesV2DetailFormat = {
  shelves_v2: 'shelves_v2',
} as const;

export interface PlanogramShelvesV2Detail {
  bay_size?: BayActualSize;
  format: PlanogramShelvesV2DetailFormat;
  products_layout: ShelvesV2Compartment[];
  /** 下段から上段のリスト */
  shelves: PlanogramShelvesV2DetailShelvesItem[];
  shelves_frame: ShelvesFrame;
}

export type PlanogramShelvesDetailShelvesItem = ShelfBoardPart | HookBarPart;

export type PlanogramShelvesDetailFormat =
  (typeof PlanogramShelvesDetailFormat)[keyof typeof PlanogramShelvesDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramShelvesDetailFormat = {
  shelves_v1: 'shelves_v1',
} as const;

export interface PlanogramShelvesDetail {
  bay_size?: BayActualSize;
  format: PlanogramShelvesDetailFormat;
  products_layout: PlanogramProductCompartmentList[];
  /** 下段から上段のリスト */
  shelves: PlanogramShelvesDetailShelvesItem[];
  shelves_frame: ShelvesFrame;
}

export interface PlanogramResponse {
  /** 指定したIDの棚割計画データ */
  planogram: PlanogramContainCurrentRoleAndScope;
}

export type PlanogramPtsDetailFormat =
  (typeof PlanogramPtsDetailFormat)[keyof typeof PlanogramPtsDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramPtsDetailFormat = {
  pts_v3: 'pts_v3',
} as const;

export interface PlanogramProductCompartmentCount {
  x: number;
  y: number;
  z?: number;
}

export interface PlanogramProductCompartment {
  adjust_rotation?: ProductAdjustRotation;
  count?: PlanogramProductCompartmentCount;
  face_count?: number;
  /** PTS仕様で定義された数値 */
  face_front: FaceFrontID;
  /** PTS仕様で定義された数値 */
  orientation: FaceOrientationID;
  product_id: number;
}

export interface PlanogramProductCompartmentList {
  row: PlanogramProductCompartment[];
}

export interface PlanogramPtsDetail {
  bay_size?: BayActualSize;
  format: PlanogramPtsDetailFormat;
  products_layout: PlanogramProductCompartmentList[];
  /** 下段から上段のリスト */
  shelves: PtsShelfBoard[];
}

export interface PlanogramPageResponse {
  pager: OffsetPager;
  /** 指定したページの棚割計画リスト */
  planograms: Planogram[];
}

export type PlanogramOrder =
  (typeof PlanogramOrder)[keyof typeof PlanogramOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
  bay_plan_code_asc: 'bay_plan_code_asc',
  bay_plan_code_desc: 'bay_plan_code_desc',
} as const;

/**
 * 棚割計画の形式

PTS = PTSファイル
SHELVES = 棚
BUCKETS = 平置き冷凍ケース
 */
export type PlanogramFormatType =
  (typeof PlanogramFormatType)[keyof typeof PlanogramFormatType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramFormatType = {
  shelves: 'shelves',
  buckets: 'buckets',
} as const;

export type PlanogramDirectorySearchOrder =
  (typeof PlanogramDirectorySearchOrder)[keyof typeof PlanogramDirectorySearchOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramDirectorySearchOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
  organization_status_id_asc: 'organization_status_id_asc',
  organization_status_id_desc: 'organization_status_id_desc',
  bay_plan_code_asc: 'bay_plan_code_asc',
  bay_plan_code_desc: 'bay_plan_code_desc',
  current_user_accessed_at_asc: 'current_user_accessed_at_asc',
  current_user_accessed_at_desc: 'current_user_accessed_at_desc',
} as const;

/**
 * 定義順序でプラノグラムディレクトリの公開範囲を表現

0: PUBLIC = ユーザ全員に公開
1: RESTRICTED = 権限付与されたユーザにのみ公開
2: PRIVATE = 非公開
 */
export type PlanogramDirectoryScope =
  (typeof PlanogramDirectoryScope)[keyof typeof PlanogramDirectoryScope];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramDirectoryScope = {
  public: 'public',
  restricted: 'restricted',
  private: 'private',
} as const;

export interface PlanogramDirectoryResponse {
  planogram_directory?: PlanogramDirectory;
}

export type PlanogramDirectoryOrder =
  (typeof PlanogramDirectoryOrder)[keyof typeof PlanogramDirectoryOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramDirectoryOrder = {
  created_at_asc: 'created_at_asc',
  created_at_desc: 'created_at_desc',
  updated_at_asc: 'updated_at_asc',
  updated_at_desc: 'updated_at_desc',
  name_asc: 'name_asc',
  name_desc: 'name_desc',
  organization_status_id_asc: 'organization_status_id_asc',
  organization_status_id_desc: 'organization_status_id_desc',
  bay_plan_code_asc: 'bay_plan_code_asc',
  bay_plan_code_desc: 'bay_plan_code_desc',
} as const;

export type PlanogramDirectoryForSearchUpdatedAt = string | string;

export type PlanogramDirectoryForSearchCreatedAt = string | string;

export interface PlanogramDirectoryFavorite {
  id: number;
  owner_id: number;
  target_id: string;
}

export interface PlanogramDirectoryForSearch {
  breadcrumb: BreadCrumbForSearch[];
  created_at: PlanogramDirectoryForSearchCreatedAt;
  current_user_accessed_at?: string;
  current_user_role?: CurrentUserRole;
  favorite?: PlanogramDirectoryFavorite;
  id: string;
  /** ディレクトリ名 or ファイル名 */
  name: string;
  owner?: UserInfo;
  parent_id?: string;
  planogram?: Planogram;
  planogram_id?: number;
  scope: PlanogramDirectoryScope;
  type: DirectoryType;
  updated_at: PlanogramDirectoryForSearchUpdatedAt;
}

export interface PlanogramDirectoryFavoriteResponse {
  planogram_directory_favorite: PlanogramDirectoryFavorite;
}

export type PlanogramDirectoryUpdatedAt = string | string;

export type PlanogramDirectoryCreatedAt = string | string;

export interface PlanogramDirectory {
  created_at: PlanogramDirectoryCreatedAt;
  current_user_role?: CurrentUserRole;
  favorite?: PlanogramDirectoryFavorite;
  id: string;
  /** ディレクトリ名 or ファイル名 */
  name: string;
  owner?: UserInfo;
  parent_id?: string;
  planogram?: Planogram;
  planogram_id?: number;
  scope: PlanogramDirectoryScope;
  type: DirectoryType;
  updated_at: PlanogramDirectoryUpdatedAt;
}

export interface PlanogramDirectoriesSearchPageResponse {
  pager: OffsetPager;
  /** 指定したページの棚割計画ディレクトリリスト */
  planogram_directories: PlanogramDirectoryForSearch[];
}

export interface PlanogramDirectoriesPageResponse {
  breadcrumb: PlanogramDirectory[];
  pager: SetDefaultLimitPager;
  parent: PlanogramDirectory;
  planogram_directories: PlanogramDirectory[];
}

export type PlanogramContainCurrentRoleAndScopeUpdatedAt = string | string;

/**
 * 一覧系APIでは常に null となる
 */
export type PlanogramContainCurrentRoleAndScopePlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export type PlanogramContainCurrentRoleAndScopeCustomField = {
  [key: string]: unknown;
};

export type PlanogramContainCurrentRoleAndScopeCreatedAt = string | string;

export interface PlanogramContainCurrentRoleAndScope {
  bay_plan_code_id?: number;
  bay_plan_id: number;
  created_at: PlanogramContainCurrentRoleAndScopeCreatedAt;
  current_user_role?: CurrentUserRole;
  custom_field?: PlanogramContainCurrentRoleAndScopeCustomField;
  directory_id?: string;
  directory_parent_id?: string;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** 一覧系APIでは常に null となる */
  link?: LinkPlanogram;
  name: string;
  organization_status_id: number;
  owner: UserInfo;
  /** 一覧系APIでは常に null となる */
  plan?: PlanogramContainCurrentRoleAndScopePlan;
  /** Media API */
  products_shelves_url?: string;
  scope?: PlanogramDirectoryScope;
  status?: Status;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
  /** 店舗と紐づく場合にのみ存在する */
  store_id?: number;
  updated_at: PlanogramContainCurrentRoleAndScopeUpdatedAt;
}

export interface PlanogramBucketsFrameBayPlanDetail {
  buckets?: PlanogramBucketsBucketBayPlan[];
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  padding: BucketsPadding;
}

export interface PlanogramBucketsFrame {
  bay_part_id: number;
  depth?: number;
  detail: PlanogramBucketsFrameBayPlanDetail;
  height?: number;
  name: string;
  type: string;
  width?: number;
}

export type PlanogramBucketsDetailFormat =
  (typeof PlanogramBucketsDetailFormat)[keyof typeof PlanogramBucketsDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramBucketsDetailFormat = {
  buckets_v1: 'buckets_v1',
} as const;

export interface PlanogramBucketsDetail {
  format: PlanogramBucketsDetailFormat;
  frame: PlanogramBucketsFrame;
}

export type PlanogramBucketsCompartmentType =
  (typeof PlanogramBucketsCompartmentType)[keyof typeof PlanogramBucketsCompartmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramBucketsCompartmentType = {
  compartment: 'compartment',
} as const;

export interface PlanogramBucketsCompartment {
  adjust_rotation?: ProductAdjustRotation;
  count?: PlanogramProductCompartmentCount;
  face_count?: number;
  /** PTS仕様で定義された数値 */
  face_front: FaceFrontID;
  /** PTS仕様で定義された数値 */
  orientation: FaceOrientationID;
  product_id: number;
  type?: PlanogramBucketsCompartmentType;
}

export type PlanogramBucketsBucketBayPlanType =
  (typeof PlanogramBucketsBucketBayPlanType)[keyof typeof PlanogramBucketsBucketBayPlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramBucketsBucketBayPlanType = {
  buckets_bucket: 'buckets_bucket',
} as const;

export interface PlanogramBucketsBucketBayPlan {
  bay_part_id: number;
  detail: PlanogramBucketsBucketDetail;
  name: string;
  type: PlanogramBucketsBucketBayPlanType;
}

export type PlanogramBucketsAreaType =
  (typeof PlanogramBucketsAreaType)[keyof typeof PlanogramBucketsAreaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramBucketsAreaType = {
  area: 'area',
} as const;

export type PlanogramBucketsAreaSplitAxis =
  (typeof PlanogramBucketsAreaSplitAxis)[keyof typeof PlanogramBucketsAreaSplitAxis];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanogramBucketsAreaSplitAxis = {
  compartment_x: 'compartment_x',
  compartment_y: 'compartment_y',
} as const;

export type PlanogramBucketsAreaChildrenItem =
  | PlanogramBucketsArea
  | PlanogramBucketsCompartment;

export interface PlanogramBucketsArea {
  children?: PlanogramBucketsAreaChildrenItem[];
  split_axis?: PlanogramBucketsAreaSplitAxis;
  type?: PlanogramBucketsAreaType;
}

export interface PlanogramBucketsBucketDetail {
  area: PlanogramBucketsArea;
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  padding: BucketsPadding;
}

export type PlanogramUpdatedAt = string | string;

/**
 * 一覧系APIでは常に null となる
 */
export type PlanogramPlan =
  | PlanogramShelvesDetail
  | PlanogramShelvesV2Detail
  | PlanogramPtsDetail
  | PlanogramBucketsDetail;

export type PlanogramCustomField = { [key: string]: unknown };

export type PlanogramCreatedAt = string | string;

export interface Planogram {
  bay_plan_code_id?: number;
  bay_plan_id: number;
  created_at: PlanogramCreatedAt;
  custom_field?: PlanogramCustomField;
  directory_id?: string;
  directory_parent_id?: string;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** 一覧系APIでは常に null となる */
  link?: LinkPlanogram;
  name: string;
  organization_status_id: number;
  owner: UserInfo;
  /** 一覧系APIでは常に null となる */
  plan?: PlanogramPlan;
  /** Media API */
  products_shelves_url?: string;
  status?: Status;
  /** 店舗と紐づく場合にのみ存在する */
  store_bay_id?: number;
  /** 店舗と紐づく場合にのみ存在する */
  store_id?: number;
  updated_at: PlanogramUpdatedAt;
}

export type PTSBoardBayPartType =
  (typeof PTSBoardBayPartType)[keyof typeof PTSBoardBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PTSBoardBayPartType = {
  board: 'board',
} as const;

export type PTSBoardBayPartCreatedAt = string | string;

/**
 * BayPlan type=pts 用の什器パーツ、棚板を表現する
 */
export interface PTSBoardBayPart {
  created_at: PTSBoardBayPartCreatedAt;
  deprecated?: boolean;
  depth: number;
  detail?: EmptyBayPartDetail;
  height: number;
  id: number;
  name: string;
  type?: PTSBoardBayPartType;
  width: number;
}

export interface OrganizationStatus {
  id: number;
  label: string;
}

export interface OrganizationStatusResponse {
  /** ユーザ組織の規定するPlanogramを管理するためのステータス */
  planogram_organization_statuses: OrganizationStatus[];
}

export interface OptionalUserRequest {
  email?: string;
  /** @minLength 1 */
  fullname?: string;
  role?: Role;
}

/**
 * offset & limit形式のページング情報。頻繁に変更されないデータに利用。
 */
export interface OffsetPager {
  /**
   * 1ページに含まれるアイテムの最大件数
   * @maximum 100
   */
  limit?: number;
  /** リストの開始インデックス（0オリジン） */
  offset?: number;
  /** DBに存在するアイテムの合計件数。リクエスト時に指定不要 */
  total?: number;
}

export interface MfaDeviceResponse {
  preferred_mfa_setting?: string;
}

export interface MergeProductFacesRequest {
  /** 結合対象となる商品フェイスのID */
  face_ids: string[];
}

export interface LinkRealogramCandidate {
  next_created_realogram_candidate_id?: number;
  prev_created_realogram_candidate_id?: number;
}

export interface LinkPlanogram {
  next_created_planogram_id?: number;
  origin_planogram_id?: number;
  origin_realogram_candidate_id?: number;
  prev_created_planogram_id?: number;
}

export interface LightShadow {
  enabled: boolean;
}

export interface Light {
  shadow: LightShadow;
}

/**
 * start = 左詰め
space_evenly = 均等割り
 */
export type JustifyContent =
  (typeof JustifyContent)[keyof typeof JustifyContent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JustifyContent = {
  start: 'start',
  space_evenly: 'space_evenly',
} as const;

export type ImageSizeType = (typeof ImageSizeType)[keyof typeof ImageSizeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageSizeType = {
  tiny: 'tiny',
  small: 'small',
  medium: 'medium',
  large: 'large',
  huge: 'huge',
} as const;

export interface ImageSize {
  height: number;
  width: number;
}

export interface ImageSizes {
  huge: ImageSize;
  large: ImageSize;
  medium: ImageSize;
  small: ImageSize;
  tiny: ImageSize;
}

/**
 * 画像内の直線を表現する座標
 */
export interface ImageLine {
  ex: number;
  ey: number;
  sx: number;
  sy: number;
}

export type ImageLayer = (typeof ImageLayer)[keyof typeof ImageLayer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageLayer = {
  background: 'background',
  foreground: 'foreground',
} as const;

/**
 * 画像内の長方形を表現する座標
 */
export interface ImageBBox {
  /** @minimum 1 */
  height: number;
  /** @minimum 1 */
  width: number;
  x: number;
  y: number;
}

export type HookBarPartType =
  (typeof HookBarPartType)[keyof typeof HookBarPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HookBarPartType = {
  hook_bar: 'hook_bar',
} as const;

/**
 * Shelvesタイプ什器に設置するフックバーパーツ
 */
export interface HookBarPart {
  bay_part_id: number;
  depth: number;
  detail: ShelfBayPartDetail;
  elevation: number;
  height: number;
  name: string;
  shelf_step_index?: number;
  type?: HookBarPartType;
  width: number;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

/**
 * gltfアセットの品質を表す
最初は 'main' 固定
 */
export type GltfQuality = (typeof GltfQuality)[keyof typeof GltfQuality];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GltfQuality = {
  main: 'main',
} as const;

export interface GetStoreBayResponse {
  /** 指定した店舗IDの設置什器リスト */
  store_bays: StoreBay[];
}

export interface GetProductSalesResponse {
  /** 次のページを取得する場合に指定する日時。次のページがない場合はnull */
  next_start_time?: string;
  /** 指定した集計期間の売り上げデータをまとめるデータ構造 */
  reports: ProductSalesReport[];
}

export interface GetProductSalesForRealogramResponse {
  /** 次のページを取得する場合に指定する日時。ページがない場合はnull */
  next_start_time?: string;
  /** 前のページを取得する場合に指定する日時。ページがない場合はnull */
  prev_start_time?: string;
  /** 指定した集計期間の売り上げデータをまとめるデータ構造 */
  reports: ProductSalesReport[];
}

export interface GetPlanogramDirectoryResponse {
  /** 指定したIDの棚割計画またはディレクトリ */
  planogram_directory: PlanogramDirectory;
}

export interface Fog {
  enabled: boolean;
  far: number;
  near: number;
}

export type FilterByRoleEnum =
  (typeof FilterByRoleEnum)[keyof typeof FilterByRoleEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterByRoleEnum = {
  editor: 'editor',
} as const;

export type FileFormat = (typeof FileFormat)[keyof typeof FileFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileFormat = {
  pts: 'pts',
  se_v1: 'se_v1',
} as const;

export interface FeaturePublic {
  saml_enabled?: boolean;
  saml_sign_in_url?: string;
  sample_feature_1?: boolean;
}

export interface Feature {
  /** テナント全体でデモモードを強制的に無効化する場合 false とする */
  demo_mode: boolean;
  /** システムロール user による棚スキャナディレクトリのユーザ権限管理の有効・無効（admin はこのフラグの有効・無効によらず管理機能を利用することができる） */
  realogram_directory_acl_by_user: boolean;
  /** テナント企業と売上データ連携を行い、それを利用した分析機能の有効・無効 */
  tenant_sales_analytics: boolean;
}

/**
 * 0:回転なし  1:左90度  2:180度  3:右90度
 */
export type FaceOrientationID =
  (typeof FaceOrientationID)[keyof typeof FaceOrientationID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaceOrientationID = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

/**
 * 1:正面  2:上面  3:右側面 4:左側面  5:背面  6:底面
 */
export type FaceFrontType = (typeof FaceFrontType)[keyof typeof FaceFrontType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaceFrontType = {
  front: 'front',
  top: 'top',
  right: 'right',
  left: 'left',
  back: 'back',
  bottom: 'bottom',
} as const;

/**
 * 1:正面  2:上面  3:右側面 4:左側面  5:背面  6:底面
 */
export type FaceFrontID = (typeof FaceFrontID)[keyof typeof FaceFrontID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaceFrontID = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
} as const;

/**
 * テクスチャの拡張子
 */
export type Extension = (typeof Extension)[keyof typeof Extension];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Extension = {
  jpg: 'jpg',
  ktx2: 'ktx2',
  png: 'png',
} as const;

export type EventType = (typeof EventType)[keyof typeof EventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  failed_update_planogram_3d_shelf: 'failed_update_planogram_3d_shelf',
  failed_recognize_realogram_candidate_image:
    'failed_recognize_realogram_candidate_image',
} as const;

export type EventMessage = { [key: string]: unknown };

export type EventCreatedAt = string | string;

export interface Event {
  created_at: EventCreatedAt;
  id: string;
  message?: EventMessage;
  type: EventType;
}

export interface EventPageResponse {
  /** 指定したトピックIDに紐づくイベントのリスト */
  events: Event[];
  pager: OffsetPager;
}

export interface EstimateForRealogramRequest {
  /** シミュレーションの対象となるRealogramCandidate */
  realogram_candidate: RealogramCandidate;
  /** 指定した日時を含む集計ウィンドウのデータを取得。省略した場合最新の集計期間のデータを取得 */
  start_from_including?: string;
  /** 店舗地域ID */
  store_area_id?: number;
  /** 店舗地域タイプ */
  store_area_type?: StoreAreaType;
  /** 集計期間ウィンドウ */
  time_window?: TimeWindow;
}

export interface EstimateForPlanogramRequest {
  /** シミュレーションの対象となるPlanogramデータ */
  planogram: PostPlanogram;
  /** 指定した日時を含む集計ウィンドウのデータを取得。省略した場合最新の集計期間のデータを取得 */
  start_from_including?: string;
  /** 店舗地域ID */
  store_area_id?: number;
  /** 店舗地域タイプ */
  store_area_type: StoreAreaType;
  /** 集計期間ウィンドウ */
  time_window?: TimeWindow;
}

export interface Estimate {
  products: ProductSalesEstimate[];
  summary: ProductSalesEstimateSummary;
}

export type Environment = (typeof Environment)[keyof typeof Environment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Environment = {
  test: 'test',
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const;

export interface EmptyBayPartDetail {
  [key: string]: unknown;
}

export type DirectoryType = (typeof DirectoryType)[keyof typeof DirectoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectoryType = {
  root: 'root',
  file: 'file',
  directory: 'directory',
  trash: 'trash',
} as const;

export type DirectoryRoleUserInfoUpdatedAt = string | string;

export type DirectoryRoleUserInfoCreatedAt = string | string;

export type DirectoryRoleType =
  (typeof DirectoryRoleType)[keyof typeof DirectoryRoleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectoryRoleType = {
  planogram: 'planogram',
  realogram: 'realogram',
} as const;

/**
 * 定義順序でディレクトリロールの上位権限・下位権限を表現

OWNER = オーナー権限
EDITOR = 編集者権限
VIEWER = 閲覧者権限

index:
    OWNER = 0
    EDITOR = 1
    VIEWER = 2
 */
export type DirectoryRoleName =
  (typeof DirectoryRoleName)[keyof typeof DirectoryRoleName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectoryRoleName = {
  owner: 'owner',
  editor: 'editor',
  viewer: 'viewer',
} as const;

export interface DirectoryRoleUserInfo {
  authorizer_id?: number;
  created_at: DirectoryRoleUserInfoCreatedAt;
  directory_id: string;
  /** PlanogramDirectoryに紐づく場合はplanogra, RealogramDirectoryに紐づいている場合はrealogram */
  directory_type: DirectoryRoleType;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** ディレクトリオーナーロールの時はowner,ディレクトリ編集者ロールはeditor,ディレクトリ閲覧者ロールはviewer */
  role: DirectoryRoleName;
  updated_at: DirectoryRoleUserInfoUpdatedAt;
  user: UserInfo;
  user_id: number;
}

export interface DirectoryRolesResponse {
  ancestor_roles: DirectoryRoleUserInfo[];
  directory_roles: DirectoryRoleUserInfo[];
}

export type DirectoryRoleUpdatedAt = string | string;

export type DirectoryRoleCreatedAt = string | string;

export interface DirectoryRole {
  authorizer_id?: number;
  created_at: DirectoryRoleCreatedAt;
  directory_id: string;
  /** PlanogramDirectoryに紐づく場合はplanogra, RealogramDirectoryに紐づいている場合はrealogram */
  directory_type: DirectoryRoleType;
  /** データベースでユニークな自動発行のID */
  id: number;
  /** ディレクトリオーナーロールの時はowner,ディレクトリ編集者ロールはeditor,ディレクトリ閲覧者ロールはviewer */
  role: DirectoryRoleName;
  updated_at: DirectoryRoleUpdatedAt;
  user_id: number;
}

export interface DirectoryRoleResponse {
  /** ディレクトリロール */
  directory_role: DirectoryRole;
}

export interface Default {
  camera: Camera;
  fog: Fog;
  light: Light;
  name: string;
}

export interface CurrentUserRole {
  /** 現在ログイン中のユーザに関する権限情報 */
  role: DirectoryRoleName;
}

/**
 * 6面図の各視点からの画像サイズ
 */
export interface CubeMapImageSize {
  back: ImageSizes;
  bottom: ImageSizes;
  front: ImageSizes;
  left: ImageSizes;
  right: ImageSizes;
  top: ImageSizes;
}

export interface CreateUser {
  email: string;
  /** @minLength 1 */
  fullname: string;
  id: number;
  role?: Role;
}

export interface CreateUserResponse {
  /** 作成されたユーザの情報 */
  user: CreateUser;
}

export type ConfidenceLevel =
  (typeof ConfidenceLevel)[keyof typeof ConfidenceLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConfidenceLevel = {
  high: 'high',
  medium: 'medium',
  low: 'low',
} as const;

export interface CloneRequest {
  bay_plan_id: number;
  directory_parent_id?: string;
  /** @minLength 1 */
  name: string;
  origin_planogram_id: number;
  /**
   * 店舗と紐づく場合にのみ存在する
   * @minimum 0
   */
  store_id?: number;
}

export interface ChildBayPart {
  bay_part_id: number;
  name: string;
  type: string;
}

export interface ChangePassowrdRequest {
  access_token: string;
  new_password: string;
  old_password: string;
}

/**
 * offset & limit形式のページング情報。カテゴリパラメータが存在し、 limit を10000とする場合に利用。
 */
export interface CategoryExistOffsetPager {
  /**
   * 1ページに含まれるアイテムの最大件数
   * @maximum 10000
   */
  limit?: number;
  /** リストの開始インデックス（0オリジン） */
  offset?: number;
  /** DBに存在するアイテムの合計件数。リクエスト時に指定不要 */
  total?: number;
}

/**
 * カメラの向き
 */
export type CameraType = (typeof CameraType)[keyof typeof CameraType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CameraType = {
  'front-orthographic': 'front-orthographic',
} as const;

export type CameraControllerType =
  (typeof CameraControllerType)[keyof typeof CameraControllerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CameraControllerType = {
  orbit: 'orbit',
} as const;

export interface CameraController {
  max_distance: number;
  rotate_enabled: boolean;
  target: Vector;
  type: CameraControllerType;
}

export interface Camera {
  controller: CameraController;
  far: number;
  fov: number;
  near: number;
  position: Vector;
  rotation: Vector;
}

export interface BucketsPoint {
  x: number;
  y: number;
  z: number;
}

export interface BucketsPadding {
  behind: number;
  bottom: number;
  front: number;
  left: number;
  right: number;
  top: number;
}

export interface BucketsFrameDetail {
  buckets?: BucketsBucket[];
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  padding: BucketsPadding;
}

export interface BucketsFrameBayPlanDetail {
  buckets?: BucketsBucketBayPlan[];
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  padding: BucketsPadding;
}

export type BucketsFrameBayPartType =
  (typeof BucketsFrameBayPartType)[keyof typeof BucketsFrameBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsFrameBayPartType = {
  buckets_frame: 'buckets_frame',
} as const;

export type BucketsFrameBayPartDetail = BucketsFrameDetail | EmptyBayPartDetail;

export type BucketsFrameBayPartCreatedAt = string | string;

/**
 * BayPlan type=buckets 用の什器パーツ、什器フレームを表現する
 */
export interface BucketsFrameBayPart {
  created_at: BucketsFrameBayPartCreatedAt;
  deprecated?: boolean;
  detail: BucketsFrameBayPartDetail;
  id: number;
  name: string;
  type?: BucketsFrameBayPartType;
}

export interface BucketsFrame {
  bay_part_id: number;
  depth?: number;
  detail: BucketsFrameBayPlanDetail;
  height?: number;
  name: string;
  type: string;
  width?: number;
}

export type BucketsCompartmentType =
  (typeof BucketsCompartmentType)[keyof typeof BucketsCompartmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsCompartmentType = {
  compartment: 'compartment',
} as const;

export interface BucketsCompartment {
  type?: BucketsCompartmentType;
}

export interface BucketsBucketDetail {
  area: BucketsArea;
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  padding: BucketsPadding;
}

export type BucketsBucketBayPlanType =
  (typeof BucketsBucketBayPlanType)[keyof typeof BucketsBucketBayPlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsBucketBayPlanType = {
  buckets_bucket: 'buckets_bucket',
} as const;

export interface BucketsBucketBayPlan {
  bay_part_id: number;
  detail: BucketsBucketDetail;
  name: string;
  type: BucketsBucketBayPlanType;
}

export type BucketsBucketBayPartType =
  (typeof BucketsBucketBayPartType)[keyof typeof BucketsBucketBayPartType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsBucketBayPartType = {
  buckets_bucket: 'buckets_bucket',
} as const;

export type BucketsBucketBayPartCreatedAt = string | string;

/**
 * BayPlan type=buckets 用の什器パーツ、カゴを表現する
 */
export interface BucketsBucketBayPart {
  created_at: BucketsBucketBayPartCreatedAt;
  deprecated?: boolean;
  depth?: number;
  detail: BucketsBucketDetail;
  height?: number;
  id: number;
  name: string;
  type?: BucketsBucketBayPartType;
  width?: number;
}

export interface BucketsBucket {
  [key: string]: unknown;
}

export type BucketsAreaType =
  (typeof BucketsAreaType)[keyof typeof BucketsAreaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsAreaType = {
  area: 'area',
} as const;

export type BucketsAreaSplitAxis =
  (typeof BucketsAreaSplitAxis)[keyof typeof BucketsAreaSplitAxis];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketsAreaSplitAxis = {
  compartment_x: 'compartment_x',
  compartment_y: 'compartment_y',
} as const;

export type BucketsAreaChildrenItem = BucketsArea | BucketsCompartment;

export interface BucketsArea {
  children?: BucketsAreaChildrenItem[];
  split_axis?: BucketsAreaSplitAxis;
  type?: BucketsAreaType;
}

export interface BreadCrumbForSearch {
  id: string;
  /** ディレクトリ名 or ファイル名 */
  name: string;
}

export interface BodyUploadFile {
  bay_plan_code_id?: number;
  bay_plan_id: number;
  custom_field?: string;
  directory_parent_id?: string;
  file: Blob;
  file_format: FileFormat;
  /** @minLength 1 */
  name: string;
  organization_status_id?: number;
  store_bay_id?: number;
  store_id?: number;
}

export interface BodyPutMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut {
  /** 画像ファイル */
  image: Blob;
}

export interface BaySize {
  actual: BayActualSize;
  images?: BayImageSize;
}

export interface BayPlansViewSet {
  bay_plan_id: number;
  view_groups: ViewGroups;
}

export interface BayPlansViewSetResponse {
  /** 3Dプレビュー表示するためのカメラ情報 */
  bay_plans_view_set: BayPlansViewSet;
}

export type BayPlanShelvesDetailShelvesItem = ShelfBoardPart | HookBarPart;

export type BayPlanShelvesDetailFormat =
  (typeof BayPlanShelvesDetailFormat)[keyof typeof BayPlanShelvesDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BayPlanShelvesDetailFormat = {
  shelves_v1: 'shelves_v1',
  shelves_v2: 'shelves_v2',
} as const;

export interface BayPlanShelvesDetail {
  bay_size?: BaySize;
  format: BayPlanShelvesDetailFormat;
  /** 下段から上段のリスト */
  shelves: BayPlanShelvesDetailShelvesItem[];
  shelves_frame: ShelvesFrame;
}

export interface BayPlanResponse {
  /** 指定したIDの什器計画データ */
  bay_plan: BayPlan;
}

export type BayPlanPtsDetailFormat =
  (typeof BayPlanPtsDetailFormat)[keyof typeof BayPlanPtsDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BayPlanPtsDetailFormat = {
  pts_v3: 'pts_v3',
} as const;

export interface BayPlanPtsDetail {
  bay_size?: BaySize;
  format: BayPlanPtsDetailFormat;
  /** 下段から上段のリスト */
  shelves: PtsShelfBoard[];
}

export interface BayPlanPageResponse {
  /** 指定したページの什器計画リスト */
  bay_plans: BayPlan[];
  pager: OffsetPager;
}

/**
 * ユーザ組織の規定するフランチャイズチェーンでの店舗什器を識別するコード
 */
export interface BayPlanCode {
  /** PFNの管理する内部的なID */
  id: number;
  /** ユーザ企業の規定した文字列 */
  name: string;
}

export interface BayPlanCodeResponse {
  /** ベイプランコードのリスト */
  bay_plan_codes: BayPlanCode[];
}

export interface BayPlanCategory {
  bay_plan_id: number;
  children: BayPartCategory[];
  name: string;
  type: string;
}

export type BayPlanBucketsDetailFormat =
  (typeof BayPlanBucketsDetailFormat)[keyof typeof BayPlanBucketsDetailFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BayPlanBucketsDetailFormat = {
  buckets_v1: 'buckets_v1',
} as const;

export interface BayPlanBucketsDetail {
  bay_size?: BaySize;
  format: BayPlanBucketsDetailFormat;
  frame: BucketsFrame;
}

export type BayPlanPlan =
  | BayPlanShelvesDetail
  | BayPlanBucketsDetail
  | BayPlanPtsDetail;

export type BayPlanCreatedAt = string | string;

export interface BayPlan {
  created_at: BayPlanCreatedAt;
  deprecated?: boolean;
  id: number;
  name: string;
  plan?: BayPlanPlan;
  type: string;
}

/**
 * 什器パーツ
 */
export type BayPartsResponseBayPartsItem =
  | PTSBoardBayPart
  | ShelvesFrameBayPart
  | ShelvesShelfBoardBayPart
  | ShelvesHookBarBayPart
  | BucketsFrameBayPart
  | BucketsBucketBayPart;

export interface BayPartsResponse {
  /** 什器パーツリスト */
  bay_parts: BayPartsResponseBayPartsItem[];
}

export interface BayPartTree {
  children: BayPlanCategory[];
  name: string;
  type: string;
}

/**
 * 什器パーツ
 */
export type BayPartResponseBayPart =
  | PTSBoardBayPart
  | ShelvesFrameBayPart
  | ShelvesShelfBoardBayPart
  | ShelvesHookBarBayPart
  | BucketsFrameBayPart
  | BucketsBucketBayPart;

export interface BayPartResponse {
  /** 什器パーツ */
  bay_part: BayPartResponseBayPart;
}

export interface BayPartCategoryResponse {
  bay_part_category: BayPartTree;
}

export interface BayPartCategory {
  children: ChildBayPart[];
  name: string;
  type: string;
}

export interface BayImageSize {
  front: ImageSizes;
}

/**
 * 什器全体の物理的なサイズ
単位: メートル
 */
export interface BayActualSize {
  depth: number;
  height: number;
  width: number;
}

export interface AuthorizationCodeRequest {
  authorization_code: string;
}

export type AuthFlow = (typeof AuthFlow)[keyof typeof AuthFlow];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthFlow = {
  USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH',
  REFRESH_TOKEN_AUTH: 'REFRESH_TOKEN_AUTH',
} as const;

export interface AggregationPeriod {
  /** 集計期間の終了日 */
  end_date: string;
  /** 集計期間の開始日 */
  start_date: string;
}

/**
 * 追加する商品区分の座標情報
 */
export interface AddBBox {
  /** @minimum 1 */
  height: number;
  /** @minimum 1 */
  width: number;
  /** @minimum 1 */
  x: number;
  /** @minimum 1 */
  y: number;
}

export interface AddProductFaceRequest {
  /** 追加する商品フェイスを含む区画 */
  bbox: AddBBox;
  /** 商品の店頭在庫状態 */
  in_stock?: boolean;
  /** フェイス追加を行う対象の棚板のID */
  shelf_board_id: string;
}

/**
 * ファイルをアップロードし棚割計画を新規作成する。主な処理はバックエンドシステム内で非同期ジョブとして実行されるため、レスポンスJSONにはplanogramの主な内容を含まない。planogram.idをクライアントに伝える目的で返却する。
 * @summary ファイルをアップロード
 */
export const uploadFile = (bodyUploadFile: BodyType<BodyUploadFile>) => {
  const formData = new FormData();
  formData.append('file', bodyUploadFile.file);
  formData.append('file_format', bodyUploadFile.file_format);
  formData.append('name', bodyUploadFile.name);
  formData.append('bay_plan_id', bodyUploadFile.bay_plan_id.toString());
  if (bodyUploadFile.store_id !== undefined) {
    formData.append('store_id', bodyUploadFile.store_id.toString());
  }
  if (bodyUploadFile.store_bay_id !== undefined) {
    formData.append('store_bay_id', bodyUploadFile.store_bay_id.toString());
  }
  if (bodyUploadFile.bay_plan_code_id !== undefined) {
    formData.append(
      'bay_plan_code_id',
      bodyUploadFile.bay_plan_code_id.toString()
    );
  }
  if (bodyUploadFile.organization_status_id !== undefined) {
    formData.append(
      'organization_status_id',
      bodyUploadFile.organization_status_id.toString()
    );
  }
  if (bodyUploadFile.directory_parent_id !== undefined) {
    formData.append('directory_parent_id', bodyUploadFile.directory_parent_id);
  }
  if (bodyUploadFile.custom_field !== undefined) {
    formData.append('custom_field', bodyUploadFile.custom_field);
  }

  return createInstance<unknown>({
    url: `/api/v1/planograms/file`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

/**
 * 棚割計画一覧を返却する。Alpha1~2では、全件を作成日順で返却する
 * @summary 棚割計画一覧を取得
 */
export const getListApiV1PlanogramsGet = (
  params?: GetListApiV1PlanogramsGetParams
) => {
  return createInstance<PlanogramPageResponse>({
    url: `/api/v1/planograms`,
    method: 'GET',
    params,
  });
};

/**
 * 商品棚3Dジョブを投入する。内部的な処理は  POST /planograms/file を参照
 * @summary Planogram JSONをポストして棚割計画を新規作成する
 */
export const postApiV1PlanogramsPost = (
  postPlanogram: BodyType<PostPlanogram>
) => {
  return createInstance<PlanogramResponse>({
    url: `/api/v1/planograms`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postPlanogram,
  });
};

/**
 * @summary ユーザ組織の規定する Planogram のユーザステータスとして選択可能なリストを提供する
 */
export const getOrganizationStatusesApiV1PlanogramsOrganizationStatusesGet =
  () => {
    return createInstance<OrganizationStatusResponse>({
      url: `/api/v1/planograms/organization_statuses`,
      method: 'GET',
    });
  };

/**
 * @summary 指定した棚割計画を取得
 */
export const getSingleDataApiV1PlanogramsPlanogramIdGet = (
  planogramId: number
) => {
  return createInstance<PlanogramResponse>({
    url: `/api/v1/planograms/${planogramId}`,
    method: 'GET',
  });
};

/**
 * フロントエンドの「保存」ボタンを押下したタイミングでリクエスト         商品棚3Dジョブを投入する
 * @summary 指定した棚割計画を更新する
 */
export const updateApiV1PlanogramsPlanogramIdPut = (
  planogramId: number,
  putPlanogram: BodyType<PutPlanogram>
) => {
  return createInstance<PlanogramResponse>({
    url: `/api/v1/planograms/${planogramId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: putPlanogram,
  });
};

/**
 * @summary 指定した棚割計画をゴミ箱に移動する
 */
export const deleteApiV1PlanogramsPlanogramIdDelete = (planogramId: number) => {
  return createInstance<unknown>({
    url: `/api/v1/planograms/${planogramId}`,
    method: 'DELETE',
  });
};

/**
 * PTSShelf3DJobWorker を実行し gltf を生成する。<br>         gltfファイルをS3のテンポラリーなディレクトリへ保存し、        Planogram.products_shelves_url へgltfのURLをセットして返す。<br>         UUID などでユニークな名前を gltf につける。
 * @summary フロントエンドの「3Dプレビュー」ボタンを押下したタイミングでリクエスト
 */
export const previewApiV1PlanogramsPreviewPost = (
  postPreviewPlanogram: BodyType<PostPreviewPlanogram>
) => {
  return createInstance<PreviewPlanogramResponse>({
    url: `/api/v1/planograms/preview`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postPreviewPlanogram,
  });
};

/**
 * @summary RealogramCandidateからPlanogramを作成する
 */
export const postRealToPlanApiV1PlanogramsRealToPlanPost = (
  postRealToPlanRequest: BodyType<PostRealToPlanRequest>
) => {
  return createInstance<PlanogramResponse>({
    url: `/api/v1/planograms/real_to_plan`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postRealToPlanRequest,
  });
};

/**
 * @summary Planogramをコピーして新しいPlanogramを作成する
 */
export const cloneApiV1PlanogramsClonePost = (
  cloneRequest: BodyType<CloneRequest>
) => {
  return createInstance<PlanogramResponse>({
    url: `/api/v1/planograms/clone`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cloneRequest,
  });
};

/**
 * @summary ディレクトリ、または棚割計画をディレクトリへ登録する
 */
export const postPlanogramDirectoryApiV1PlanogramDirectoriesPost = (
  postPlanogramDirectory: BodyType<PostPlanogramDirectory>
) => {
  return createInstance<PlanogramDirectoryResponse>({
    url: `/api/v1/planogram_directories`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postPlanogramDirectory,
  });
};

/**
 * @summary 指定したディレクトリの直下のディレクトリ、または棚割計画を全て取得する
 */
export const getPlanogramDirectoryApiV1PlanogramDirectoriesGet = (
  params?: GetPlanogramDirectoryApiV1PlanogramDirectoriesGetParams
) => {
  return createInstance<PlanogramDirectoriesPageResponse>({
    url: `/api/v1/planogram_directories`,
    method: 'GET',
    params,
  });
};

/**
 * GET /planogram_directories API は絞り込みに利用し、こちらのAPIはツリー全体からの検索に利用する。                このAPIは階層構造ではなくフラットなリストとしてデータを返却する。ページングを行う
 * @summary PlanogramDirectoryの検索を行い、directory と file type のデータを返却する。
 */
export const searchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGet = (
  params?: SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetParams
) => {
  return createInstance<PlanogramDirectoriesSearchPageResponse>({
    url: `/api/v1/planogram_directories/search`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定したIDのディレクトリまたは棚割計画を取得
 */
export const getSingleDataApiV1PlanogramDirectoriesPlanogramDirectoryIdGet = (
  planogramDirectoryId: string
) => {
  return createInstance<GetPlanogramDirectoryResponse>({
    url: `/api/v1/planogram_directories/${planogramDirectoryId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定したIDのディレクトリ、または棚割計画を別のディレクトリへ移動する
 */
export const putPlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdPut =
  (
    planogramDirectoryId: string,
    putPlanogramDirectory: BodyType<PutPlanogramDirectory>
  ) => {
    return createInstance<PlanogramDirectoryResponse>({
      url: `/api/v1/planogram_directories/${planogramDirectoryId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putPlanogramDirectory,
    });
  };

/**
 * @summary 指定したIDのディレクトリ、または棚割計画をメインの木構造から削除しゴミ箱へ移動する。
 */
export const deletePlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdDelete =
  (planogramDirectoryId: string) => {
    return createInstance<PlanogramDirectoryResponse>({
      url: `/api/v1/planogram_directories/${planogramDirectoryId}`,
      method: 'DELETE',
    });
  };

/**
 * @summary 指定したIDのディレクトリ、または棚割計画をお気に入り登録解除する
 */
export const deletePlanogramDirectoryFavoriteApiV1PlanogramDirectoryFavoritesDirectoryIdDelete =
  (directoryId: string) => {
    return createInstance<unknown>({
      url: `/api/v1/planogram_directory_favorites/${directoryId}`,
      method: 'DELETE',
    });
  };

/**
 * @summary 指定したIDのディレクトリ、または棚割計画をお気に入り登録する
 */
export const postPlanogramDirectoryFavoritesApiV1PlanogramDirectoryFavoritesDirectoryIdPost =
  (directoryId: string) => {
    return createInstance<PlanogramDirectoryFavoriteResponse>({
      url: `/api/v1/planogram_directory_favorites/${directoryId}`,
      method: 'POST',
    });
  };

/**
 * 全件をid降順で返却する
 * @summary 商品一覧を取得
 */
export const getListApiV1ProductsGet = (
  params?: GetListApiV1ProductsGetParams
) => {
  return createInstance<ProductPageResponse>({
    url: `/api/v1/products`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 複数の商品情報をまとめて取得する
 */
export const getBulkListApiV1ProductsBulkGet = (
  params?: GetBulkListApiV1ProductsBulkGetParams
) => {
  return createInstance<ProductBulkResponse>({
    url: `/api/v1/products/bulk`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 商品の棚割訂正履歴を取得する
 */
export const getRealogramReviseHistoriesApiV1ProductsRealogramReviseHistoriesGet =
  (
    params: GetRealogramReviseHistoriesApiV1ProductsRealogramReviseHistoriesGetParams
  ) => {
    return createInstance<RealogramReviseHistoriesResponse>({
      url: `/api/v1/products/realogram_revise_histories`,
      method: 'GET',
      params,
    });
  };

/**
 * @summary 指定した商品情報を取得
 */
export const getSingleDataApiV1ProductsProductIdGet = (productId: number) => {
  return createInstance<ProductResponse>({
    url: `/api/v1/products/${productId}`,
    method: 'GET',
  });
};

/**
 * @summary 商品サイズ調整データを取得する
 */
export const getProductAdjustersApiV1ProductsProductIdAdjustersGet = (
  productId: number
) => {
  return createInstance<ProductAdjusterResponse>({
    url: `/api/v1/products/${productId}/adjusters`,
    method: 'GET',
  });
};

/**
 * @summary 商品サイズ調整データを更新
 */
export const putProductAdjusterApiV1ProductsProductIdAdjustersPut = (
  productId: number,
  productFaceDirection: BodyType<ProductFaceDirection>
) => {
  return createInstance<ProductAdjusterResponse>({
    url: `/api/v1/products/${productId}/adjusters`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: productFaceDirection,
  });
};

/**
 * @summary 商品サイズ調整データを削除する
 */
export const deleteProductAdjustersApiV1ProductsProductIdAdjustersDelete = (
  productId: number
) => {
  return createInstance<ProductAdjusterResponse>({
    url: `/api/v1/products/${productId}/adjusters`,
    method: 'DELETE',
  });
};

/**
 * @summary ユーザが管理する商品モックアップ画像を更新するためのAPI
 */
export const putMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut = (
  productId: number,
  faceFront: FaceFrontType,
  bodyPutMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut: BodyType<BodyPutMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut>
) => {
  const formData = new FormData();
  formData.append(
    'image',
    bodyPutMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut.image
  );

  return createInstance<unknown>({
    url: `/api/v1/products/${productId}/mockup_images/${faceFront}.png`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

/**
 * @summary ユーザが管理する商品モックアップ画像を取得する
 */
export const getMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngGet = (
  productId: number,
  faceFront: FaceFrontType
) => {
  return createInstance<unknown>({
    url: `/api/v1/products/${productId}/mockup_images/${faceFront}.png`,
    method: 'GET',
  });
};

/**
 * @summary ユーザが管理する商品モックアップ画像を削除する
 */
export const deleteMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngDelete =
  (productId: number, faceFront: FaceFrontType) => {
    return createInstance<unknown>({
      url: `/api/v1/products/${productId}/mockup_images/${faceFront}.png`,
      method: 'DELETE',
    });
  };

/**
 * 棚エディタAlpha1~2フェーズでは全件を id 昇順で返却する。plan詳細なし
 * @summary 什器計画一覧を取得する
 */
export const getListApiV1BayPlansGet = (
  params?: GetListApiV1BayPlansGetParams
) => {
  return createInstance<BayPlanPageResponse>({
    url: `/api/v1/bay_plans`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定した什器計画を取得する
 */
export const getSingleDataApiV1BayPlansBayPlanIdGet = (bayPlanId: number) => {
  return createInstance<BayPlanResponse>({
    url: `/api/v1/bay_plans/${bayPlanId}`,
    method: 'GET',
  });
};

/**
 * 什器パーツの id 昇順、ページング無し全件取得
 * @summary 指定した什器計画の利用可能な什器パーツを取得する
 */
export const getBayPartsApiV1BayPlansBayPlanIdBayPartsGet = (
  bayPlanId: number
) => {
  return createInstance<BayPartsResponse>({
    url: `/api/v1/bay_plans/${bayPlanId}/bay_parts`,
    method: 'GET',
  });
};

/**
 * PFN 3Dエンジンチームの用意したJSONの内容をそのままレスポンスする
 * @summary BayPlan ID の什器を3Dプレビュー表示するためのカメラ情報等を取得する
 */
export const getBayPlansViewSetApiV1BayPlansBayPlanIdViewSetGet = (
  bayPlanId: number
) => {
  return createInstance<BayPlansViewSetResponse>({
    url: `/api/v1/bay_plans/${bayPlanId}/view_set`,
    method: 'GET',
  });
};

/**
 * @summary 什器パーツの詳細データを取得するためのAPI
 */
export const getBayPartApiV1BayPartsBayPartIdGet = (bayPartId: number) => {
  return createInstance<BayPartResponse>({
    url: `/api/v1/bay_parts/${bayPartId}`,
    method: 'GET',
  });
};

/**
 * 指定したディレクトリと、その直近の親ディレクトリのディレクトリロールを一括で取得する
 * @summary 指定したIDのディレクトリに関するディレクトリロール一覧を取得する
 */
export const getListApiV1DirectoryRolesGet = (
  params: GetListApiV1DirectoryRolesGetParams
) => {
  return createInstance<DirectoryRolesResponse>({
    url: `/api/v1/directory_roles`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定したIDのディレクトリにディレクトリロールを作成する
 */
export const postDirectoryRoleApiV1DirectoryRolesPost = (
  postDirectoryRoleRequest: BodyType<PostDirectoryRoleRequest>
) => {
  return createInstance<PostDirectoryRoleResponse>({
    url: `/api/v1/directory_roles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postDirectoryRoleRequest,
  });
};

/**
 * @summary 指定したIDのディレクトリロールの変更を行う
 */
export const putDirectoryRoleApiV1DirectoryRolesDirectoryRoleIdPut = (
  directoryRoleId: number,
  putDirectoryRoleRequest: BodyType<PutDirectoryRoleRequest>
) => {
  return createInstance<DirectoryRoleResponse>({
    url: `/api/v1/directory_roles/${directoryRoleId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: putDirectoryRoleRequest,
  });
};

/**
 * @summary 指定したIDのディレクトリロールを削除する
 */
export const deleteDirectoryRolesApiV1DirectoryRolesDirectoryRoleIdDelete = (
  directoryRoleId: number
) => {
  return createInstance<unknown>({
    url: `/api/v1/directory_roles/${directoryRoleId}`,
    method: 'DELETE',
  });
};

/**
 * 全件をID昇順で返却する
 * @summary 店舗一覧を取得する
 */
export const getListApiV1StoresGet = (params?: GetListApiV1StoresGetParams) => {
  return createInstance<StorePageResponse>({
    url: `/api/v1/stores`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定した店舗の情報を取得する
 */
export const getSingleDataApiV1StoresStoreIdGet = (storeId: number) => {
  return createInstance<StoreResponse>({
    url: `/api/v1/stores/${storeId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定した店舗のStoreBayを全件取得する
 */
export const getStoreBaysApiV1StoresStoreIdStoreBaysGet = (storeId: number) => {
  return createInstance<GetStoreBayResponse>({
    url: `/api/v1/stores/${storeId}/store_bays`,
    method: 'GET',
  });
};

/**
 * @summary 指定したIDの店舗に店舗什器を作成する
 */
export const postStoreBayApiV1StoreBaysPost = (
  postStoreBayRequest: BodyType<PostStoreBayRequest>
) => {
  return createInstance<StoreBayResponse>({
    url: `/api/v1/store_bays`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postStoreBayRequest,
  });
};

/**
 * @summary 指定したIDの店舗什器の変更を行う
 */
export const putStoreBayApiV1StoreBaysStoreBayIdPut = (
  storeBayId: number,
  putStoreBayRequest: BodyType<PutStoreBayRequest>
) => {
  return createInstance<StoreBayResponse>({
    url: `/api/v1/store_bays/${storeBayId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: putStoreBayRequest,
  });
};

/**
 * @summary 指定したIDの店舗什器を取得する
 */
export const getStoreBayApiV1StoreBaysStoreBayIdGet = (storeBayId: number) => {
  return createInstance<StoreBayResponse>({
    url: `/api/v1/store_bays/${storeBayId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定したIDの店舗什器を削除する
 */
export const deleteStoreBayApiV1StoreBaysStoreBayIdDelete = (
  storeBayId: number
) => {
  return createInstance<unknown>({
    url: `/api/v1/store_bays/${storeBayId}`,
    method: 'DELETE',
  });
};

/**
 * @summary 売り場を返却する
 */
export const getStoreSectionsApiV1StoreSectionsGet = () => {
  return createInstance<StoreSectionResponse>({
    url: `/api/v1/store_sections`,
    method: 'GET',
  });
};

/**
 * クライアントはレスポンスのupload_urlを使用して、S3へ直接ファイルのアップロードを行う
 * @summary 画像ファイルをアップロードし棚割実績候補を新規作成する
 */
export const postImageApiV1RealogramCandidatesImagePost = (
  postImageRequest: BodyType<PostImageRequest>
) => {
  return createInstance<PostImageResponse>({
    url: `/api/v1/realogram_candidates/image`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postImageRequest,
  });
};

/**
 * 全データを作成日時の降順で返却する
 * @summary 棚割実績候補一覧を取得する
 */
export const getListApiV1RealogramCandidatesGet = (
  params?: GetListApiV1RealogramCandidatesGetParams
) => {
  return createInstance<RealogramCandidatePageResponse>({
    url: `/api/v1/realogram_candidates`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定した棚割実績候補を取得する
 */
export const getSingleDataApiV1RealogramCandidatesRealogramCandidateIdGet = (
  realogramCandidateId: number
) => {
  return createInstance<RealogramCandidateResponse>({
    url: `/api/v1/realogram_candidates/${realogramCandidateId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定した棚割実績候補をゴミ箱に移動する
 */
export const deleteApiV1RealogramCandidatesRealogramCandidateIdDelete = (
  realogramCandidateId: number
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_candidates/${realogramCandidateId}`,
    method: 'DELETE',
  });
};

/**
 * 指定したRealogramCandidateへ、新しい商品区分ごと商品フェイス追加を行う。
 * @summary 指定したRealogramCandidateへ新しい商品区画およびフェイスの追加を行う。
 */
export const addProductFaces = (
  realogramCandidateId: number,
  addProductFaceRequest: BodyType<AddProductFaceRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_candidates/${realogramCandidateId}/faces`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addProductFaceRequest,
  });
};

/**
 * 指定したRealogramCandidateデータ内の同一区画内の商品フェイスの結合を行う。
 * @summary 指定したRealogramCandidateデータ内の商品フェイスの結合を行う。
 */
export const mergeProductFaces = (
  realogramCandidateId: number,
  mergeProductFacesRequest: BodyType<MergeProductFacesRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_candidates/${realogramCandidateId}/faces/merge`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: mergeProductFacesRequest,
  });
};

/**
 * @summary 商品フェイスの持つ情報を変更する
 */
export const updateProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdPut =
  (
    realogramCandidateId: number,
    faceId: string,
    updateProductFaceRequest: BodyType<UpdateProductFaceRequest>
  ) => {
    return createInstance<RealogramCandidateResponse>({
      url: `/api/v1/realogram_candidates/${realogramCandidateId}/faces/${faceId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateProductFaceRequest,
    });
  };

/**
 * @summary 棚割実績候補から指定したフェイスの削除を行う
 */
export const deleteProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdDelete =
  (realogramCandidateId: number, faceId: string) => {
    return createInstance<unknown>({
      url: `/api/v1/realogram_candidates/${realogramCandidateId}/faces/${faceId}`,
      method: 'DELETE',
    });
  };

/**
 * @summary 棚割実績候補から指定した商品棚板を一段削除する
 */
export const deleteShelfBoardApiV1RealogramCandidatesRealogramCandidateIdShelfBoardsShelfBoardIdDelete =
  (realogramCandidateId: number, shelfBoardId: string) => {
    return createInstance<unknown>({
      url: `/api/v1/realogram_candidates/${realogramCandidateId}/shelf_boards/${shelfBoardId}`,
      method: 'DELETE',
    });
  };

/**
 * 指定したRealogramCandidateデータ内の商品フェイスの分割を行う。主に欠品区画の分割に利用する。
 * @summary 指定したRealogramCandidateデータ内の商品フェイスの分割を行う。
 */
export const splitProductFace = (
  realogramCandidateId: number,
  faceId: string,
  splitProductFaceRequest: BodyType<SplitProductFaceRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_candidates/${realogramCandidateId}/faces/${faceId}/split`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: splitProductFaceRequest,
  });
};

/**
 * @summary 指定した親ディレクトリの直下の子ディレクトリ・ファイルを全て取得する
 */
export const getRealogramDirectoryApiV1RealogramDirectoriesGet = (
  params?: GetRealogramDirectoryApiV1RealogramDirectoriesGetParams
) => {
  return createInstance<RealogramDirectoriesResponse>({
    url: `/api/v1/realogram_directories`,
    method: 'GET',
    params,
  });
};

/**
 * このAPIはツリー全体からの検索に利用し、階層構造ではなくフラットなリストとしてデータを返却する。ページングを行う
 * @summary RealogramDirectoryの検索を行い、directory と file type のデータを返却する。
 */
export const searchRealogramDirectoryApiV1RealogramDirectoriesSearchGet = (
  params?: SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams
) => {
  return createInstance<RealogramDirectoriesPageResponse>({
    url: `/api/v1/realogram_directories/search`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定したIDのディレクトリまたは棚割実績を取得
 */
export const getSingleDataApiV1RealogramDirectoriesRealogramDirectoryIdGet = (
  realogramDirectoryId: string
) => {
  return createInstance<RealogramDirecotryResponse>({
    url: `/api/v1/realogram_directories/${realogramDirectoryId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定したIDのファイルをメインの木構造から削除し、ゴミ箱へ移動する
 */
export const deleteRealogramDirectoryApiV1RealogramDirectoriesDirectoryIdDelete =
  (directoryId: string) => {
    return createInstance<unknown>({
      url: `/api/v1/realogram_directories/${directoryId}`,
      method: 'DELETE',
    });
  };

/**
 * @summary 指定したIDのディレクトリ・ファイルを、ログイン中ユーザのお気に入りとして登録する
 */
export const postApiV1RealogramDirectoryFavoritesDirectoryIdPost = (
  directoryId: string
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_directory_favorites/${directoryId}`,
    method: 'POST',
  });
};

/**
 * @summary 指定したIDのディレクトリ・ファイルをお気に入り登録解除する
 */
export const deleteApiV1RealogramDirectoryFavoritesDirectoryIdDelete = (
  directoryId: string
) => {
  return createInstance<unknown>({
    url: `/api/v1/realogram_directory_favorites/${directoryId}`,
    method: 'DELETE',
  });
};

/**
 * @summary 自身のユーザ情報を取得する
 */
export const getMeApiV1UsersMeGet = () => {
  return createInstance<UserResponse>({
    url: `/api/v1/users/me`,
    method: 'GET',
  });
};

/**
 * @summary トークンを破棄してログアウトする
 */
export const signOutApiV1UsersSignOutDelete = (
  postRefreshToken: BodyType<PostRefreshToken>
) => {
  return createInstance<unknown>({
    url: `/api/v1/users/sign_out`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: postRefreshToken,
  });
};

/**
 * @summary MFA設定を取得する
 */
export const mfaDeviceApiV1UsersMfaDevicePost = (
  postAccessTokenRequest: BodyType<PostAccessTokenRequest>
) => {
  return createInstance<MfaDeviceResponse>({
    url: `/api/v1/users/mfa_device`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postAccessTokenRequest,
  });
};

/**
 * @summary 設定済みのMFAデバイスを削除する
 */
export const deleteMfaDeviceApiV1UsersMfaDeviceDelete = (
  postAccessTokenRequest: BodyType<PostAccessTokenRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/users/mfa_device`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: postAccessTokenRequest,
  });
};

/**
 * @summary MFA設定の初期化を行う
 */
export const initializeMfaApiV1UsersInitializeMfaPost = (
  postAccessTokenRequest: BodyType<PostAccessTokenRequest>
) => {
  return createInstance<SecretCodeResponse>({
    url: `/api/v1/users/initialize_mfa`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postAccessTokenRequest,
  });
};

/**
 * @summary MFAデバイスの検証を行う
 */
export const verifyMfaDeviceApiV1UsersVerifyMfaDevicePost = (
  verifyMfaDeviceRequest: BodyType<VerifyMfaDeviceRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/users/verify_mfa_device`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyMfaDeviceRequest,
  });
};

/**
 * @summary パスワードの変更を行う
 */
export const changePasswordApiV1UsersChangePasswordPut = (
  changePassowrdRequest: BodyType<ChangePassowrdRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/users/change_password`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: changePassowrdRequest,
  });
};

/**
 * ユーザ一覧を返却する。全件をfullnameの昇順で返却する。
 * @summary ユーザ一覧を取得
 */
export const getListApiV1UsersGet = (params?: GetListApiV1UsersGetParams) => {
  return createInstance<UserPageResponse>({
    url: `/api/v1/users`,
    method: 'GET',
    params,
  });
};

/**
 * @summary ユーザを作成する
 */
export const createApiV1UsersPost = (userRequest: BodyType<UserRequest>) => {
  return createInstance<CreateUserResponse>({
    url: `/api/v1/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userRequest,
  });
};

/**
 * @summary 指定のユーザ情報を取得する
 */
export const getSingleDataApiV1UsersUserIdGet = (userId: number) => {
  return createInstance<UserResponse>({
    url: `/api/v1/users/${userId}`,
    method: 'GET',
  });
};

/**
 * @summary 指定のユーザ情報を更新する
 */
export const putUserApiV1UsersUserIdPut = (
  userId: number,
  optionalUserRequest: BodyType<OptionalUserRequest>
) => {
  return createInstance<UserResponse>({
    url: `/api/v1/users/${userId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: optionalUserRequest,
  });
};

/**
 * @summary 指定のユーザアカウントを停止する
 */
export const deleteApiV1UsersUserIdDelete = (userId: number) => {
  return createInstance<UserResponse>({
    url: `/api/v1/users/${userId}`,
    method: 'DELETE',
  });
};

/**
 * @summary 停止済みユーザーアカウントを復活させる
 */
export const reactivateApiV1UsersUserIdActivatePut = (userId: number) => {
  return createInstance<UserResponse>({
    url: `/api/v1/users/${userId}/activate`,
    method: 'PUT',
  });
};

/**
 * @summary adminユーザが他のユーザのMFAデバイスを登録解除する
 */
export const adminDeleteMfaDeviceApiV1AdminUsersUserIdMfaDeviceDelete = (
  userId: number
) => {
  return createInstance<unknown>({
    url: `/api/v1/admin/users/${userId}/mfa_device`,
    method: 'DELETE',
  });
};

/**
 * @summary adminユーザが他のユーザのMFA設定を取得する
 */
export const adminGetMfaDeviceApiV1AdminUsersUserIdMfaDeviceGet = (
  userId: number
) => {
  return createInstance<MfaDeviceResponse>({
    url: `/api/v1/admin/users/${userId}/mfa_device`,
    method: 'GET',
  });
};

/**
 * @summary 商品カテゴリを返却する
 */
export const getProductCategoryApiV1ProductCategoriesGet = () => {
  return createInstance<ProductCategoryResponse>({
    url: `/api/v1/product_categories`,
    method: 'GET',
  });
};

/**
 * @summary 商品部門マスタを返却する
 */
export const getProductDivisionsApiV1ProductDivisionsGet = () => {
  return createInstance<ProductDivisionResponse>({
    url: `/api/v1/product_divisions`,
    method: 'GET',
  });
};

/**
 * @summary 什器パーツをカテゴリ毎に階層構造で表現したデータを返却する
 */
export const getBayPartCategoryApiV1BayPartCategoriesGet = () => {
  return createInstance<BayPartCategoryResponse>({
    url: `/api/v1/bay_part_categories`,
    method: 'GET',
  });
};

/**
 * @summary 指定したトピックIDに紐づくイベントを取得する
 */
export const getEventsApiV1TopicsTopicIdEventsGet = (
  topicId: string,
  params?: GetEventsApiV1TopicsTopicIdEventsGetParams
) => {
  return createInstance<EventPageResponse>({
    url: `/api/v1/topics/${topicId}/events`,
    method: 'GET',
    params,
  });
};

/**
 * @summary ベイプランコード一覧を取得する
 */
export const getBayPlanCodesApiV1BayPlanCodesGet = () => {
  return createInstance<BayPlanCodeResponse>({
    url: `/api/v1/bay_plan_codes`,
    method: 'GET',
  });
};

/**
 * @summary ログイン中のユーザーのtenant_keyに紐づくテナント情報を取得する
 */
export const getTenantMeApiV1TenantsPrivateMeGet = () => {
  return createInstance<TenantResponse>({
    url: `/api/v1/tenants/private/me`,
    method: 'GET',
  });
};

/**
 * @summary テナントの文言、色の設定を取得する
 */
export const getTenantPrivateLocaleApiV1TenantsPrivateLocaleGet = () => {
  return createInstance<unknown>({
    url: `/api/v1/tenants/private/locale`,
    method: 'GET',
  });
};

/**
 * @summary 指定した店舗地域と期間の条件で集計した、商品の売り上げデータを取得する
 */
export const getProductSalesApiV1AnalyticsProductsSalesTimeseriesGet = (
  params: GetProductSalesApiV1AnalyticsProductsSalesTimeseriesGetParams
) => {
  return createInstance<GetProductSalesResponse>({
    url: `/api/v1/analytics/products/sales/timeseries`,
    method: 'GET',
    params,
  });
};

/**
 * @summary 指定した店舗地域と期間の条件で集計した、棚割実績に含まれる商品の売り上げデータを取得する
 */
export const getProductSalesForRealogramApiV1AnalyticsProductsSalesTimeseriesForRealogramGet =
  (
    params: GetProductSalesForRealogramApiV1AnalyticsProductsSalesTimeseriesForRealogramGetParams
  ) => {
    return createInstance<GetProductSalesForRealogramResponse>({
      url: `/api/v1/analytics/products/sales/timeseries_for_realogram`,
      method: 'GET',
      params,
    });
  };

/**
 * @summary 指定したPlanogramから売上データを推定する。リクエストで指定した店舗地域と期間の条件で推定した結果を返却する
 */
export const estimateProductSalesForPlanogramApiV1AnalyticsProductsSalesEstimateForPlanogramPost =
  (estimateForPlanogramRequest: BodyType<EstimateForPlanogramRequest>) => {
    return createInstance<ProductSalesEstimateResponse>({
      url: `/api/v1/analytics/products/sales/estimate_for_planogram`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: estimateForPlanogramRequest,
    });
  };

/**
 * @summary 指定したRealogramから売上データを推定する。リクエストで指定した店舗地域と期間の条件で推定した結果を返却する
 */
export const estimateProductSalesForRealogramApiV1AnalyticsProductsSalesEstimateForRealogramPost =
  (estimateForRealogramRequest: BodyType<EstimateForRealogramRequest>) => {
    return createInstance<ProductSalesEstimateResponse>({
      url: `/api/v1/analytics/products/sales/estimate_for_realogram`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: estimateForRealogramRequest,
    });
  };

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ不可
 * @summary 指定したUUIDの商品棚プレビュー3Dモデルデータを返却する
 */
export const getPlanogramPreviewGltfApiV1MediaPlanogramPreviewPreviewIdQualityGltfGet =
  (previewId: string, quality: GltfQuality) => {
    return createInstance<unknown>({
      url: `/api/v1/media/planogram/preview_${previewId}_${quality}.gltf`,
      method: 'GET',
    });
  };

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ不可
 * @summary 指定したplanogram_idの商品棚3Dモデルを返却
 */
export const getPlanogramGltfApiV1MediaPlanogramPlanogramIdQualityGltfGet = (
  planogramId: number,
  quality: GltfQuality
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/planogram/${planogramId}_${quality}.gltf`,
    method: 'GET',
  });
};

/**
 * 商品棚gltfから相対URLで参照される。
 * @summary 指定した商品の3Dデータを返却
 */
export const getProductGltfApiV1MediaProductsProductIdQualityGltfGet = (
  productId: number,
  quality: GltfQuality,
  params?: GetProductGltfApiV1MediaProductsProductIdQualityGltfGetParams
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/products/${productId}_${quality}.gltf`,
    method: 'GET',
    params,
  });
};

/**
 * 商品棚gltfから相対URLで参照される。クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した商品の3Dデータを返却
 */
export const getMediaProductTextureApiV1MediaProductsProductIdTextureQualityExtensionGet =
  (
    productId: number,
    quality: GltfQuality,
    extension: Extension,
    params?: GetMediaProductTextureApiV1MediaProductsProductIdTextureQualityExtensionGetParams
  ) => {
    return createInstance<unknown>({
      url: `/api/v1/media/products/${productId}_texture_${quality}.${extension}`,
      method: 'GET',
      params,
    });
  };

/**
 * 商品棚gltfから相対URLで参照される。クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した商品の3Dデータを返却
 */
export const getMediaProductMeshApiV1MediaProductsProductIdMeshQualityBinGet = (
  productId: number,
  quality: GltfQuality,
  params?: GetMediaProductMeshApiV1MediaProductsProductIdMeshQualityBinGetParams
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/products/${productId}_mesh_${quality}.bin`,
    method: 'GET',
    params,
  });
};

/**
 * 商品棚gltfから相対URLで参照される。
 * @summary 指定した什器パーツの3Dデータを返却
 */
export const getBayPartGltfApiV1MediaBayPartsBayPartIdQualityGltfGet = (
  bayPartId: number,
  quality: GltfQuality
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/bay_parts/${bayPartId}_${quality}.gltf`,
    method: 'GET',
  });
};

/**
 * 商品棚gltfから相対URLで参照される。クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した什器パーツの3Dデータを返却
 */
export const getMediaBayPartTextureApiV1MediaBayPartsBayPartIdTextureQualityExtensionGet =
  (bayPartId: number, quality: GltfQuality, extension: Extension) => {
    return createInstance<unknown>({
      url: `/api/v1/media/bay_parts/${bayPartId}_texture_${quality}.${extension}`,
      method: 'GET',
    });
  };

/**
 * 商品棚gltfから相対URLで参照される。クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した什器パーツの3Dデータを返却
 */
export const getMediaBayPartMeshApiV1MediaBayPartsBayPartIdMeshQualityBinGet = (
  bayPartId: number,
  quality: GltfQuality
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/bay_parts/${bayPartId}_mesh_${quality}.bin`,
    method: 'GET',
  });
};

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した bay_part_id の什器パーツサムネイル画像を返却する
 */
export const getBayPartThumbnailApiV1MediaBayPartsBayPartIdFrontLayerSvgGet = (
  bayPartId: number,
  layer: ImageLayer
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/bay_parts/${bayPartId}_front_${layer}.svg`,
    method: 'GET',
  });
};

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定したproduct_idの商品サムネイル画像を返却する
 */
export const getProductThumbnailApiV1MediaProductsProductIdFaceFrontSizePngGet =
  (
    productId: number,
    faceFront: FaceFrontType,
    size: ImageSizeType,
    params?: GetProductThumbnailApiV1MediaProductsProductIdFaceFrontSizePngGetParams
  ) => {
    return createInstance<unknown>({
      url: `/api/v1/media/products/${productId}_${faceFront}_${size}.png`,
      method: 'GET',
      params,
    });
  };

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した bay_plan_id の什器サムネイル画像を返却する
 */
export const getBayPlanThumbnailApiV1MediaBayPlansBayPlanIdFrontSizePngGet = (
  bayPlanId: number,
  size: ImageSizeType
) => {
  return createInstance<unknown>({
    url: `/api/v1/media/bay_plans/${bayPlanId}_front_${size}.png`,
    method: 'GET',
  });
};

/**
 * @summary 指定したIDのRealogramCandidateに紐づくユーザの撮影した画像のうち、指定された番号の画像を返却する
 */
export const getRealogramCandidateImageByIndexApiV1MediaRealogramCandidatesRealogramCandidateIdShotIndexSizeJpgGet =
  (realogramCandidateId: number, shotIndex: number, size: ImageSizeType) => {
    return createInstance<unknown>({
      url: `/api/v1/media/realogram_candidates/${realogramCandidateId}_${shotIndex}_${size}.jpg`,
      method: 'GET',
    });
  };

/**
 * @summary 指定したIDのRealogramCandidateに紐づく、ユーザの撮影した画像を返却する
 */
export const getRealogramCandidateImageApiV1MediaRealogramCandidatesRealogramCandidateIdSizeJpgGet =
  (realogramCandidateId: number, size: ImageSizeType) => {
    return createInstance<unknown>({
      url: `/api/v1/media/realogram_candidates/${realogramCandidateId}_${size}.jpg`,
      method: 'GET',
    });
  };

/**
 * クライアント（ブラウザ）でのHTTPキャッシュ推奨
 * @summary 指定した planogram_id の商品棚3Dモデルのレンダリング済み画像を返却する
 */
export const getPlanogramThumbnailApiV1MediaPlanogramPlanogramIdCameraSizePngGet =
  (planogramId: number, camera: CameraType, size: ImageSizeType) => {
    return createInstance<unknown>({
      url: `/api/v1/media/planogram/${planogramId}_${camera}_${size}.png`,
      method: 'GET',
    });
  };

/**
 * システムの正常性を確認する。正常の場合200を、異常の場合500をレスポンスする
 * @summary ヘルスチェックAPI
 */
export const getHealthCheckApiV1PublicSysHealthGet = () => {
  return createInstance<unknown>({
    url: `/api/v1/public/sys/health`,
    method: 'GET',
  });
};

/**
 * @summary IDトークンを再生成する
 */
export const regenerateIdTokenApiV1PublicUsersRegenerateIdTokenPost = (
  postRefreshToken: BodyType<PostRefreshToken>
) => {
  return createInstance<ResponseRegenerateIdToken>({
    url: `/api/v1/public/users/regenerate_id_token`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postRefreshToken,
  });
};

/**
 * @summary メールアドレスとパスワードを使用してログインする
 */
export const signInApiV1PublicUsersSignInPost = (
  postSignInRequest: BodyType<PostSignInRequest>
) => {
  return createInstance<SignInResponse>({
    url: `/api/v1/public/users/sign_in`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postSignInRequest,
  });
};

/**
 * @summary メールアドレスを入力してパスワード変更用の認証コードを送信する
 */
export const forgotPasswordApiV1PublicUsersForgotPasswordPost = (
  postForgotPasswordRequest: BodyType<PostForgotPasswordRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/public/users/forgot_password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postForgotPasswordRequest,
  });
};

/**
 * @summary 認証コードを入力してパスワードをリセットする
 */
export const confirmForgotPasswordApiV1PublicUsersConfirmForgotPasswordPut = (
  postConfirmForgotPasswordRequest: BodyType<PostConfirmForgotPasswordRequest>
) => {
  return createInstance<unknown>({
    url: `/api/v1/public/users/confirm_forgot_password`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: postConfirmForgotPasswordRequest,
  });
};

/**
 * @summary MFAトークンを検証してログインを行う
 */
export const mfaAuthApiV1PublicUsersMfaAuthPost = (
  postMfaAuth: BodyType<PostMfaAuth>
) => {
  return createInstance<TokenResponse>({
    url: `/api/v1/public/users/mfa_auth`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postMfaAuth,
  });
};

/**
 * @summary ユーザの初期パスワードの設定を行う
 */
export const setInitialPasswordApiV1PublicUsersSetInitialPasswordPost = (
  setInitialPasswordRequest: BodyType<SetInitialPasswordRequest>
) => {
  return createInstance<TokenResponse>({
    url: `/api/v1/public/users/set_initial_password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: setInitialPasswordRequest,
  });
};

/**
 * @summary SAMLサインインで取得した認証コードからIDトークンを生成する
 */
export const signInWithCodeApiV1PublicUsersSignInWithCodePost = (
  authorizationCodeRequest: BodyType<AuthorizationCodeRequest>
) => {
  return createInstance<TokenResponse>({
    url: `/api/v1/public/users/sign_in_with_code`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authorizationCodeRequest,
  });
};

/**
 * @summary アクセス中のテナント画面に紐づく公開テナント情報を取得する
 */
export const getTenantPublicMeApiV1TenantsPublicMeGet = () => {
  return createInstance<TenantPublicResponse>({
    url: `/api/v1/tenants/public/me`,
    method: 'GET',
  });
};

export type UploadFileResult = NonNullable<
  Awaited<ReturnType<typeof uploadFile>>
>;
export type GetListApiV1PlanogramsGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1PlanogramsGet>>
>;
export type PostApiV1PlanogramsPostResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1PlanogramsPost>>
>;
export type GetOrganizationStatusesApiV1PlanogramsOrganizationStatusesGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getOrganizationStatusesApiV1PlanogramsOrganizationStatusesGet
      >
    >
  >;
export type GetSingleDataApiV1PlanogramsPlanogramIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getSingleDataApiV1PlanogramsPlanogramIdGet>>
>;
export type UpdateApiV1PlanogramsPlanogramIdPutResult = NonNullable<
  Awaited<ReturnType<typeof updateApiV1PlanogramsPlanogramIdPut>>
>;
export type DeleteApiV1PlanogramsPlanogramIdDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV1PlanogramsPlanogramIdDelete>>
>;
export type PreviewApiV1PlanogramsPreviewPostResult = NonNullable<
  Awaited<ReturnType<typeof previewApiV1PlanogramsPreviewPost>>
>;
export type PostRealToPlanApiV1PlanogramsRealToPlanPostResult = NonNullable<
  Awaited<ReturnType<typeof postRealToPlanApiV1PlanogramsRealToPlanPost>>
>;
export type CloneApiV1PlanogramsClonePostResult = NonNullable<
  Awaited<ReturnType<typeof cloneApiV1PlanogramsClonePost>>
>;
export type PostPlanogramDirectoryApiV1PlanogramDirectoriesPostResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postPlanogramDirectoryApiV1PlanogramDirectoriesPost>
    >
  >;
export type GetPlanogramDirectoryApiV1PlanogramDirectoriesGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getPlanogramDirectoryApiV1PlanogramDirectoriesGet>
    >
  >;
export type SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof searchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGet
      >
    >
  >;
export type GetSingleDataApiV1PlanogramDirectoriesPlanogramDirectoryIdGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getSingleDataApiV1PlanogramDirectoriesPlanogramDirectoryIdGet
      >
    >
  >;
export type PutPlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdPutResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putPlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdPut
      >
    >
  >;
export type DeletePlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deletePlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdDelete
      >
    >
  >;
export type DeletePlanogramDirectoryFavoriteApiV1PlanogramDirectoryFavoritesDirectoryIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deletePlanogramDirectoryFavoriteApiV1PlanogramDirectoryFavoritesDirectoryIdDelete
      >
    >
  >;
export type PostPlanogramDirectoryFavoritesApiV1PlanogramDirectoryFavoritesDirectoryIdPostResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postPlanogramDirectoryFavoritesApiV1PlanogramDirectoryFavoritesDirectoryIdPost
      >
    >
  >;
export type GetListApiV1ProductsGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1ProductsGet>>
>;
export type GetBulkListApiV1ProductsBulkGetResult = NonNullable<
  Awaited<ReturnType<typeof getBulkListApiV1ProductsBulkGet>>
>;
export type GetRealogramReviseHistoriesApiV1ProductsRealogramReviseHistoriesGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getRealogramReviseHistoriesApiV1ProductsRealogramReviseHistoriesGet
      >
    >
  >;
export type GetSingleDataApiV1ProductsProductIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getSingleDataApiV1ProductsProductIdGet>>
>;
export type GetProductAdjustersApiV1ProductsProductIdAdjustersGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getProductAdjustersApiV1ProductsProductIdAdjustersGet>
    >
  >;
export type PutProductAdjusterApiV1ProductsProductIdAdjustersPutResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putProductAdjusterApiV1ProductsProductIdAdjustersPut>
    >
  >;
export type DeleteProductAdjustersApiV1ProductsProductIdAdjustersDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteProductAdjustersApiV1ProductsProductIdAdjustersDelete
      >
    >
  >;
export type PutMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPutResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof putMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngPut
      >
    >
  >;
export type GetMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngGet
      >
    >
  >;
export type DeleteMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteMockupImageApiV1ProductsProductIdMockupImagesFaceFrontPngDelete
      >
    >
  >;
export type GetListApiV1BayPlansGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1BayPlansGet>>
>;
export type GetSingleDataApiV1BayPlansBayPlanIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getSingleDataApiV1BayPlansBayPlanIdGet>>
>;
export type GetBayPartsApiV1BayPlansBayPlanIdBayPartsGetResult = NonNullable<
  Awaited<ReturnType<typeof getBayPartsApiV1BayPlansBayPlanIdBayPartsGet>>
>;
export type GetBayPlansViewSetApiV1BayPlansBayPlanIdViewSetGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getBayPlansViewSetApiV1BayPlansBayPlanIdViewSetGet>
    >
  >;
export type GetBayPartApiV1BayPartsBayPartIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getBayPartApiV1BayPartsBayPartIdGet>>
>;
export type GetListApiV1DirectoryRolesGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1DirectoryRolesGet>>
>;
export type PostDirectoryRoleApiV1DirectoryRolesPostResult = NonNullable<
  Awaited<ReturnType<typeof postDirectoryRoleApiV1DirectoryRolesPost>>
>;
export type PutDirectoryRoleApiV1DirectoryRolesDirectoryRoleIdPutResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putDirectoryRoleApiV1DirectoryRolesDirectoryRoleIdPut>
    >
  >;
export type DeleteDirectoryRolesApiV1DirectoryRolesDirectoryRoleIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteDirectoryRolesApiV1DirectoryRolesDirectoryRoleIdDelete
      >
    >
  >;
export type GetListApiV1StoresGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1StoresGet>>
>;
export type GetSingleDataApiV1StoresStoreIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getSingleDataApiV1StoresStoreIdGet>>
>;
export type GetStoreBaysApiV1StoresStoreIdStoreBaysGetResult = NonNullable<
  Awaited<ReturnType<typeof getStoreBaysApiV1StoresStoreIdStoreBaysGet>>
>;
export type PostStoreBayApiV1StoreBaysPostResult = NonNullable<
  Awaited<ReturnType<typeof postStoreBayApiV1StoreBaysPost>>
>;
export type PutStoreBayApiV1StoreBaysStoreBayIdPutResult = NonNullable<
  Awaited<ReturnType<typeof putStoreBayApiV1StoreBaysStoreBayIdPut>>
>;
export type GetStoreBayApiV1StoreBaysStoreBayIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getStoreBayApiV1StoreBaysStoreBayIdGet>>
>;
export type DeleteStoreBayApiV1StoreBaysStoreBayIdDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteStoreBayApiV1StoreBaysStoreBayIdDelete>>
>;
export type GetStoreSectionsApiV1StoreSectionsGetResult = NonNullable<
  Awaited<ReturnType<typeof getStoreSectionsApiV1StoreSectionsGet>>
>;
export type PostImageApiV1RealogramCandidatesImagePostResult = NonNullable<
  Awaited<ReturnType<typeof postImageApiV1RealogramCandidatesImagePost>>
>;
export type GetListApiV1RealogramCandidatesGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1RealogramCandidatesGet>>
>;
export type GetSingleDataApiV1RealogramCandidatesRealogramCandidateIdGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getSingleDataApiV1RealogramCandidatesRealogramCandidateIdGet
      >
    >
  >;
export type DeleteApiV1RealogramCandidatesRealogramCandidateIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteApiV1RealogramCandidatesRealogramCandidateIdDelete
      >
    >
  >;
export type AddProductFacesResult = NonNullable<
  Awaited<ReturnType<typeof addProductFaces>>
>;
export type MergeProductFacesResult = NonNullable<
  Awaited<ReturnType<typeof mergeProductFaces>>
>;
export type UpdateProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdPutResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof updateProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdPut
      >
    >
  >;
export type DeleteProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteProductFaceApiV1RealogramCandidatesRealogramCandidateIdFacesFaceIdDelete
      >
    >
  >;
export type DeleteShelfBoardApiV1RealogramCandidatesRealogramCandidateIdShelfBoardsShelfBoardIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteShelfBoardApiV1RealogramCandidatesRealogramCandidateIdShelfBoardsShelfBoardIdDelete
      >
    >
  >;
export type SplitProductFaceResult = NonNullable<
  Awaited<ReturnType<typeof splitProductFace>>
>;
export type GetRealogramDirectoryApiV1RealogramDirectoriesGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getRealogramDirectoryApiV1RealogramDirectoriesGet>
    >
  >;
export type SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof searchRealogramDirectoryApiV1RealogramDirectoriesSearchGet
      >
    >
  >;
export type GetSingleDataApiV1RealogramDirectoriesRealogramDirectoryIdGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getSingleDataApiV1RealogramDirectoriesRealogramDirectoryIdGet
      >
    >
  >;
export type DeleteRealogramDirectoryApiV1RealogramDirectoriesDirectoryIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteRealogramDirectoryApiV1RealogramDirectoriesDirectoryIdDelete
      >
    >
  >;
export type PostApiV1RealogramDirectoryFavoritesDirectoryIdPostResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postApiV1RealogramDirectoryFavoritesDirectoryIdPost>
    >
  >;
export type DeleteApiV1RealogramDirectoryFavoritesDirectoryIdDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteApiV1RealogramDirectoryFavoritesDirectoryIdDelete>
    >
  >;
export type GetMeApiV1UsersMeGetResult = NonNullable<
  Awaited<ReturnType<typeof getMeApiV1UsersMeGet>>
>;
export type SignOutApiV1UsersSignOutDeleteResult = NonNullable<
  Awaited<ReturnType<typeof signOutApiV1UsersSignOutDelete>>
>;
export type MfaDeviceApiV1UsersMfaDevicePostResult = NonNullable<
  Awaited<ReturnType<typeof mfaDeviceApiV1UsersMfaDevicePost>>
>;
export type DeleteMfaDeviceApiV1UsersMfaDeviceDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteMfaDeviceApiV1UsersMfaDeviceDelete>>
>;
export type InitializeMfaApiV1UsersInitializeMfaPostResult = NonNullable<
  Awaited<ReturnType<typeof initializeMfaApiV1UsersInitializeMfaPost>>
>;
export type VerifyMfaDeviceApiV1UsersVerifyMfaDevicePostResult = NonNullable<
  Awaited<ReturnType<typeof verifyMfaDeviceApiV1UsersVerifyMfaDevicePost>>
>;
export type ChangePasswordApiV1UsersChangePasswordPutResult = NonNullable<
  Awaited<ReturnType<typeof changePasswordApiV1UsersChangePasswordPut>>
>;
export type GetListApiV1UsersGetResult = NonNullable<
  Awaited<ReturnType<typeof getListApiV1UsersGet>>
>;
export type CreateApiV1UsersPostResult = NonNullable<
  Awaited<ReturnType<typeof createApiV1UsersPost>>
>;
export type GetSingleDataApiV1UsersUserIdGetResult = NonNullable<
  Awaited<ReturnType<typeof getSingleDataApiV1UsersUserIdGet>>
>;
export type PutUserApiV1UsersUserIdPutResult = NonNullable<
  Awaited<ReturnType<typeof putUserApiV1UsersUserIdPut>>
>;
export type DeleteApiV1UsersUserIdDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV1UsersUserIdDelete>>
>;
export type ReactivateApiV1UsersUserIdActivatePutResult = NonNullable<
  Awaited<ReturnType<typeof reactivateApiV1UsersUserIdActivatePut>>
>;
export type AdminDeleteMfaDeviceApiV1AdminUsersUserIdMfaDeviceDeleteResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof adminDeleteMfaDeviceApiV1AdminUsersUserIdMfaDeviceDelete
      >
    >
  >;
export type AdminGetMfaDeviceApiV1AdminUsersUserIdMfaDeviceGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof adminGetMfaDeviceApiV1AdminUsersUserIdMfaDeviceGet>
    >
  >;
export type GetProductCategoryApiV1ProductCategoriesGetResult = NonNullable<
  Awaited<ReturnType<typeof getProductCategoryApiV1ProductCategoriesGet>>
>;
export type GetProductDivisionsApiV1ProductDivisionsGetResult = NonNullable<
  Awaited<ReturnType<typeof getProductDivisionsApiV1ProductDivisionsGet>>
>;
export type GetBayPartCategoryApiV1BayPartCategoriesGetResult = NonNullable<
  Awaited<ReturnType<typeof getBayPartCategoryApiV1BayPartCategoriesGet>>
>;
export type GetEventsApiV1TopicsTopicIdEventsGetResult = NonNullable<
  Awaited<ReturnType<typeof getEventsApiV1TopicsTopicIdEventsGet>>
>;
export type GetBayPlanCodesApiV1BayPlanCodesGetResult = NonNullable<
  Awaited<ReturnType<typeof getBayPlanCodesApiV1BayPlanCodesGet>>
>;
export type GetTenantMeApiV1TenantsPrivateMeGetResult = NonNullable<
  Awaited<ReturnType<typeof getTenantMeApiV1TenantsPrivateMeGet>>
>;
export type GetTenantPrivateLocaleApiV1TenantsPrivateLocaleGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getTenantPrivateLocaleApiV1TenantsPrivateLocaleGet>
    >
  >;
export type GetProductSalesApiV1AnalyticsProductsSalesTimeseriesGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getProductSalesApiV1AnalyticsProductsSalesTimeseriesGet>
    >
  >;
export type GetProductSalesForRealogramApiV1AnalyticsProductsSalesTimeseriesForRealogramGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getProductSalesForRealogramApiV1AnalyticsProductsSalesTimeseriesForRealogramGet
      >
    >
  >;
export type EstimateProductSalesForPlanogramApiV1AnalyticsProductsSalesEstimateForPlanogramPostResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof estimateProductSalesForPlanogramApiV1AnalyticsProductsSalesEstimateForPlanogramPost
      >
    >
  >;
export type EstimateProductSalesForRealogramApiV1AnalyticsProductsSalesEstimateForRealogramPostResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof estimateProductSalesForRealogramApiV1AnalyticsProductsSalesEstimateForRealogramPost
      >
    >
  >;
export type GetPlanogramPreviewGltfApiV1MediaPlanogramPreviewPreviewIdQualityGltfGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getPlanogramPreviewGltfApiV1MediaPlanogramPreviewPreviewIdQualityGltfGet
      >
    >
  >;
export type GetPlanogramGltfApiV1MediaPlanogramPlanogramIdQualityGltfGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getPlanogramGltfApiV1MediaPlanogramPlanogramIdQualityGltfGet
      >
    >
  >;
export type GetProductGltfApiV1MediaProductsProductIdQualityGltfGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getProductGltfApiV1MediaProductsProductIdQualityGltfGet>
    >
  >;
export type GetMediaProductTextureApiV1MediaProductsProductIdTextureQualityExtensionGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getMediaProductTextureApiV1MediaProductsProductIdTextureQualityExtensionGet
      >
    >
  >;
export type GetMediaProductMeshApiV1MediaProductsProductIdMeshQualityBinGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getMediaProductMeshApiV1MediaProductsProductIdMeshQualityBinGet
      >
    >
  >;
export type GetBayPartGltfApiV1MediaBayPartsBayPartIdQualityGltfGetResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getBayPartGltfApiV1MediaBayPartsBayPartIdQualityGltfGet>
    >
  >;
export type GetMediaBayPartTextureApiV1MediaBayPartsBayPartIdTextureQualityExtensionGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getMediaBayPartTextureApiV1MediaBayPartsBayPartIdTextureQualityExtensionGet
      >
    >
  >;
export type GetMediaBayPartMeshApiV1MediaBayPartsBayPartIdMeshQualityBinGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getMediaBayPartMeshApiV1MediaBayPartsBayPartIdMeshQualityBinGet
      >
    >
  >;
export type GetBayPartThumbnailApiV1MediaBayPartsBayPartIdFrontLayerSvgGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getBayPartThumbnailApiV1MediaBayPartsBayPartIdFrontLayerSvgGet
      >
    >
  >;
export type GetProductThumbnailApiV1MediaProductsProductIdFaceFrontSizePngGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getProductThumbnailApiV1MediaProductsProductIdFaceFrontSizePngGet
      >
    >
  >;
export type GetBayPlanThumbnailApiV1MediaBayPlansBayPlanIdFrontSizePngGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getBayPlanThumbnailApiV1MediaBayPlansBayPlanIdFrontSizePngGet
      >
    >
  >;
export type GetRealogramCandidateImageByIndexApiV1MediaRealogramCandidatesRealogramCandidateIdShotIndexSizeJpgGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getRealogramCandidateImageByIndexApiV1MediaRealogramCandidatesRealogramCandidateIdShotIndexSizeJpgGet
      >
    >
  >;
export type GetRealogramCandidateImageApiV1MediaRealogramCandidatesRealogramCandidateIdSizeJpgGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getRealogramCandidateImageApiV1MediaRealogramCandidatesRealogramCandidateIdSizeJpgGet
      >
    >
  >;
export type GetPlanogramThumbnailApiV1MediaPlanogramPlanogramIdCameraSizePngGetResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getPlanogramThumbnailApiV1MediaPlanogramPlanogramIdCameraSizePngGet
      >
    >
  >;
export type GetHealthCheckApiV1PublicSysHealthGetResult = NonNullable<
  Awaited<ReturnType<typeof getHealthCheckApiV1PublicSysHealthGet>>
>;
export type RegenerateIdTokenApiV1PublicUsersRegenerateIdTokenPostResult =
  NonNullable<
    Awaited<
      ReturnType<typeof regenerateIdTokenApiV1PublicUsersRegenerateIdTokenPost>
    >
  >;
export type SignInApiV1PublicUsersSignInPostResult = NonNullable<
  Awaited<ReturnType<typeof signInApiV1PublicUsersSignInPost>>
>;
export type ForgotPasswordApiV1PublicUsersForgotPasswordPostResult =
  NonNullable<
    Awaited<ReturnType<typeof forgotPasswordApiV1PublicUsersForgotPasswordPost>>
  >;
export type ConfirmForgotPasswordApiV1PublicUsersConfirmForgotPasswordPutResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof confirmForgotPasswordApiV1PublicUsersConfirmForgotPasswordPut
      >
    >
  >;
export type MfaAuthApiV1PublicUsersMfaAuthPostResult = NonNullable<
  Awaited<ReturnType<typeof mfaAuthApiV1PublicUsersMfaAuthPost>>
>;
export type SetInitialPasswordApiV1PublicUsersSetInitialPasswordPostResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof setInitialPasswordApiV1PublicUsersSetInitialPasswordPost
      >
    >
  >;
export type SignInWithCodeApiV1PublicUsersSignInWithCodePostResult =
  NonNullable<
    Awaited<ReturnType<typeof signInWithCodeApiV1PublicUsersSignInWithCodePost>>
  >;
export type GetTenantPublicMeApiV1TenantsPublicMeGetResult = NonNullable<
  Awaited<ReturnType<typeof getTenantPublicMeApiV1TenantsPublicMeGet>>
>;
