import { StyledListItemButton } from '@components/organisms/productCandidatesList/productCandidatesList';
import { ProductCandidatesListItem } from '@components/organisms/productCandidatesListItem/productCandidatesListitem';
import { FC } from 'react';
import { Product } from 'types/common';
import { ProductCandidateWithName } from '../../../../types/realogram';

type Props = {
  historyCandidates?: Product[];
  selectedCandidate?: ProductCandidateWithName;
  handleClick: (productCandidate: ProductCandidateWithName) => void;
};

export const HistoryCandidatesList: FC<Props> = ({
  historyCandidates,
  selectedCandidate,
  handleClick,
}) => {
  return (
    <>
      {historyCandidates?.map((historyCandidate) => {
        if (!historyCandidate) return;
        return (
          <StyledListItemButton
            key={historyCandidate.id}
            selected={selectedCandidate?.product_id === historyCandidate.id}
            onClick={() =>
              handleClick({
                product_id: historyCandidate.id,
                score: 0,
                confidence_level: 'low',
                sourceType: 'history',
              })
            }
          >
            <ProductCandidatesListItem historyCandidate={historyCandidate} />
          </StyledListItemButton>
        );
      })}
    </>
  );
};
