import { SidebarItemChips } from '@components/molecules/sidebarItemChips/sidebarItemChips';
import { Stack } from '@mui/material';
import { SidebarValue, getSidebarSx, sidebarLabel } from '@utils/const';
import { FC } from 'react';
import { Folder } from '@mui/icons-material';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { theme } from 'theme';
import { HistoryIcon } from '@components/molecules/historyIcon/historyIcon';
import { CampaignIcon } from '@components/molecules/campaignIcon/campaignIcon';

type VoidFunction = () => void;

type Props = {
  sidebarValueItems: SidebarValue[];
  sidebarValue: SidebarValue;
  notActivate?: boolean;
  onClickTabAll?: VoidFunction;
  onClickTabFavorite?: VoidFunction;
  onClickTabLatest?: VoidFunction;
  onClickTabViewed?: VoidFunction;
};

export const StackListSidebar: FC<Props> = ({
  sidebarValueItems,
  sidebarValue,
  notActivate = false,
  onClickTabAll,
  onClickTabFavorite,
  onClickTabLatest,
  onClickTabViewed,
}) => {
  const sidebarAction: Record<SidebarValue, VoidFunction | undefined> = {
    all: onClickTabAll,
    favorite: onClickTabFavorite,
    latest: onClickTabLatest,
    viewed: onClickTabViewed,
  };

  const getSidebarIcon = (value: SidebarValue, isActive: boolean) => {
    const htmlColor = isActive
      ? theme.palette.icons.primary
      : theme.palette.icons.secondary;
    switch (value) {
      case 'all':
        return <Folder sx={{ '&.MuiChip-icon': { color: htmlColor } }} />;
      case 'favorite':
        return <StarIcon htmlColor={htmlColor} />;
      case 'latest':
        return <CampaignIcon htmlColor={htmlColor} />;
      case 'viewed':
        return <HistoryIcon htmlColor={htmlColor} fillOpacity={1} />;
      default:
        return <></>;
    }
  };

  const sidebarItems = sidebarValueItems.map((value) => {
    let isActive = value === sidebarValue;
    if (notActivate && sidebarValue === 'all') {
      isActive = false;
    }

    return {
      value,
      label: sidebarLabel[value],
      sx: getSidebarSx(isActive),
      onClick: sidebarAction[value],
      icon: getSidebarIcon(value, isActive),
      isActive,
    };
  });

  return (
    <Stack direction="column" alignSelf="stretch" alignItems="center" gap="8px">
      {sidebarItems.map((item) => (
        <SidebarItemChips
          key={item.value}
          label={item.label}
          icon={item.icon}
          onClick={() => {
            if (item.isActive) return;
            item.onClick?.();
          }}
          sx={item.sx}
        />
      ))}
    </Stack>
  );
};
